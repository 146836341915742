import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTrash, faPlus, faEdit } from '@fortawesome/free-solid-svg-icons';
import Header from '../components/header';
import BaseURL from '../assets/contants/BaseURL';
import axios from 'axios';

const MachineGroups = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [machinesgroups, setMachinesgroups] = useState([]);
  const [machines, setMachines] = useState([]);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [groupName, setGroupName] = useState('');
  const [selectedMachines, setSelectedMachines] = useState([]);
  const [selectedGroupIds, setSelectedGroupIds] = useState([]);

  const fetchMachinesgroups = async () => {
    try {
      const response = await axios.get(`${BaseURL}meters/machinegroup/`);
      setMachinesgroups(response.data);
    } catch (error) {
      console.error('Error fetching machine data:', error);
    }
  };

  const fetchMachines = async () => {
    try {
      const response = await axios.get(`${BaseURL}meters/machine/`);
      const machineData = response.data.map(machine => ({
        machine_id: machine.machine_id,
        id: machine.id,
      }));
      setMachines(machineData);
    } catch (error) {
      console.error('Error fetching machine data:', error);
    }
  };

  const handleAddMachine = async () => {
    const newGroup = {
      group_name: groupName,
      machine_list: selectedMachines,
    };
    console.log('Data being sent to the server:', newGroup);

    try {
      const response = await axios.post(`${BaseURL}meters/machinegroup/`, newGroup);
      if (response.status === 201) {
        setModalOpen(false);
        setGroupName('');
        setSelectedMachines([]);
        fetchMachinesgroups();
      } else {
        console.error('Error adding machine group:', response);
      }
    } catch (error) {
      console.error('Error adding machine group:', error);
    }
  };

  const handleEditMachine = async () => {
    try {
      const updatedGroup = {
        group_name: groupName,
        machine_list: selectedMachines,
      };
      const response = await axios.put(`${BaseURL}meters/machinegroup/${selectedGroup}/`, updatedGroup);
      if (response.status === 200) {
        setEditModalOpen(false);
        setGroupName('');
        setSelectedMachines([]);
        fetchMachinesgroups();
      } else {
        console.error('Error updating machine group:', response);
      }
    } catch (error) {
      console.error('Error updating machine group:', error);
    }
  };


  const handleDeleteMachine = async () => {
    try {
      if (selectedGroupIds.length === 0) {
        alert("Please select at least one group to delete.");
        return;
      }
      const confirmDelete = window.confirm("Are you sure you want to delete the selected machine group(s)?");
      if (confirmDelete) {
        for (const groupId of selectedGroupIds) {
          const response = await axios.delete(`${BaseURL}meters/machinegroup/${groupId}/`);
          if (response.status === 204) {
            fetchMachinesgroups();
          } else {
            console.error('Failed to delete machine group:', response);
          }
        }
      }
    } catch (error) {
      console.error('Error deleting machine group:', error);
    }
  };

  const openEditModal = (groupId) => {
    const group = machinesgroups.find(group => group.group_id === groupId);
  
    if (group && Array.isArray(group.machines)) {
      setGroupName(group.group_name);
      setSelectedMachines(group.machines.map(machine => machine.id));
      setSelectedGroup(groupId);
      setEditModalOpen(true);
    } else {
      console.error("Group or machines is undefined or not an array:", group);
    }
  };

  useEffect(() => {
    fetchMachinesgroups();
    fetchMachines();
  }, []);

  const handleMachineSelect = (event) => {
    const selectedMachineIds = Array.from(event.target.selectedOptions, option => {
      const machine = machines.find(machine => machine.machine_id === option.value);
      return machine ? machine.id : null;
    }).filter(id => id !== null);
  
    setSelectedMachines(selectedMachineIds);
  };

  const handleeditMachineSelect = (event) => {
    const selectedMachineIds = Array.from(event.target.selectedOptions, option => option.value);
    setSelectedMachines(selectedMachineIds);
  };

  const handleGroupCheckboxChange = (groupId) => {
    setSelectedGroupIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(groupId)) {
        return prevSelectedIds.filter(id => id !== groupId);
      } else {
        return [...prevSelectedIds, groupId];
      }
    });
  };
  

  return (
    <div style={{ textAlign: 'center', width: '100%' }}>
      <div style={{
        backgroundColor: '#007acc',
        color: 'white',
        alignContent: 'center',
        fontSize: '14px',
        height: '40px'
      }}>
        <Header title="MACHINE GROUPS" />
      </div>

      <div style={{
        border: '1px solid #ccc',
        padding: '10px',
        marginTop: '20px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
        backgroundColor: 'white',
        margin: '20px auto',
        maxWidth: '1000px',
      }}>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
          <div style={{ display: 'flex', alignItems: 'center', width: '30%' }}>
            <input
              type="text"
              placeholder="Search"
              style={{
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '8px',
                flex: '1',
                marginRight: '10px',
              }}
            />
            <FontAwesomeIcon
              icon={faSearch}
              style={{ color: '#007acc', cursor: 'pointer' }}
            />
          </div>

          <div style={{ marginLeft: 'auto' }}>
    <button
        style={{
          padding: '10px 15px',
          marginRight: '10px',
          backgroundColor: '#f44336',
          color: 'white',
          border: 'none',
          borderRadius: '8px',
          cursor: 'pointer',
        }}
        onClick={handleDeleteMachine}
    >
        <FontAwesomeIcon icon={faTrash} style={{ marginRight: '5px' }} />
        Delete
    </button>

            <button
              style={{
                padding: '10px 15px',
                backgroundColor: '#4CAF50',
                color: 'white',
                border: 'none',
                borderRadius: '8px',
                cursor: 'pointer',
                marginRight: '10px',
              }}
              onClick={() => setModalOpen(true)}
            >
              <FontAwesomeIcon icon={faPlus} style={{ marginRight: '5px' }} />
              Add
            </button>
          </div>
        </div>
      </div>

      <div style={{
        marginTop: '20px',
        padding: '10px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
        backgroundColor: 'white',
        maxWidth: '1000px',
        margin: '20px auto',
        marginBottom: '70px',
      }}>
        <table style={{ width: "100%", textAlign: "left", borderCollapse: "collapse" }}>
  <thead style={{ backgroundColor: "#007acc", color: "white" }}>
    <tr>
      <th style={{ padding: "10px" }}>
      <input
                  type="checkbox"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedGroupIds(machinesgroups.map(group => group.group_id));
                    } else {
                      setSelectedGroupIds([]);
                    }
                  }}
                  checked={selectedGroupIds.length === machinesgroups.length}
                />
      </th>
      <th style={{ padding: "10px" }}>S.No</th>
      <th style={{ padding: "10px" }}>Group Name</th>
      <th style={{ padding: "10px" }}>Machine Name</th>
      <th style={{ padding: "10px" }}>Action</th>
    </tr>
  </thead>
  <tbody>
  {machinesgroups.map((group, index) => (
              <tr key={group.group_id}
              style={{
                backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#ffffff',
              }}
              >
                <td style={{ padding: "10px" }}><input
                    type="checkbox"
                    checked={selectedGroupIds.includes(group.group_id)}
                    onChange={() => handleGroupCheckboxChange(group.group_id)}
                  /></td>
                <td style={{ padding: "10px" }}>{index + 1}</td>
                <td style={{ padding: "10px" }}>{group.group_name}</td>
                <td style={{ padding: "10px" }}>
                  {group.machines.map(machine => machine.machine_name).join(', ')}
                </td>
                <td
                  style={{
                    padding: "10px",
                    color: "blue",
                    cursor: "pointer",
                    textAlign: "left",
                  }}
                  onClick={() => openEditModal(group.group_id)}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </td>
              </tr>
            ))}
  </tbody>
</table>

        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <button
            style={{
              margin: '0 5px',
              padding: '5px 10px',
              background: '#007acc',
              color: '#ffffff',
              border: 'none',
              borderRadius: '25px',
              cursor: 'pointer',
              fontSize: '12px',
            }}
          >
            Previous
          </button>

          <button
            style={{
              margin: '0 5px',
              padding: '5px 10px',
              background: '#007acc',
              color: '#ffffff',
              border: '1px solid #ddd',
              borderRadius: '25px',
              cursor: 'pointer',
              fontSize: '12px',
            }}
          >
            1
          </button>

          <button
            style={{
              margin: '0 5px',
              padding: '5px 10px',
              background: '#007acc',
              color: '#ffffff',
              border: 'none',
              borderRadius: '25px',
              cursor: 'pointer',
              fontSize: '12px',
            }}
          >
            Next
          </button>
        </div>
      </div>
      {isModalOpen && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              backgroundColor: 'white',
              padding: '30px',
              borderRadius: '8px',
              boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
              width: '60%',
              maxWidth: '600px',
              transition: 'all 0.3s ease',
            }}
          >
            <h4 style={{ marginBottom: '20px', textAlign: 'center' }}>Add New Machine Group</h4>
            <div style={{ display: 'flex', gap: '30px' }}>
              <div style={{ flex: '1', display: 'flex', flexDirection: 'column', gap: '15px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <label style={{ flex: '0 0 150px', marginRight: '10px' }}>Group Name</label>
                  <input
                    type="text"
                    value={groupName}
                    onChange={(e) => setGroupName(e.target.value)}
                    placeholder="Enter Group Name"
                    style={{
                      flex: '1',
                      padding: '10px',
                      border: '1px solid #ccc',
                      borderRadius: '8px',
                    }}
                  />
                </div>
                <div style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '20px',
                  marginBottom: '10px',
                }}>
                  <div style={{ flex: '1' }}>
                    <label
                      htmlFor="meters"
                      style={{
                        marginBottom: '5px',
                        display: 'block',
                        fontWeight: 'bold',
                      }}
                    >
                      Machine List
                    </label>
                    <select
                      multiple
                      style={{
                        width: '100%',
                        padding: '10px',
                        border: '1px solid #ccc',
                        borderRadius: '8px',
                        marginBottom: '10px',
                      }}
                      onChange={handleMachineSelect}
                    >
                        {machines.map((machine, index) => (
                            <option key={index} value={machine.machine_id}>
                              {machine.machine_id} 
                            </option>
                          ))}
                    </select>
                  </div>

                  
                  <div style={{ flex: '1' }}>
                    <label
                      style={{
                        marginBottom: '5px',
                        display: 'block',
                        fontWeight: 'bold',
                      }}
                    >
                      Added Machines
                    </label>
                    <select
                      multiple
                      disabled
                      style={{
                        width: '100%',
                        padding: '10px',
                        border: '1px solid #ccc',
                        borderRadius: '8px',
                        marginBottom: '10px',
                      }}
                    >
                      {selectedMachines.map((machineId, index) => {
                        const selectedMachine = machines.find(machine => machine.id === machineId);
                        return selectedMachine ? (
                          <option key={index} value={selectedMachine.machine_id}>
                            {selectedMachine.machine_id}
                          </option>
                        ) : null;
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
              <button
                onClick={() => setModalOpen(false)}
                style={{
                  padding: '10px 20px',
                  backgroundColor: '#f44336',
                  color: 'white',
                  border: 'none',
                  borderRadius: '8px',
                  cursor: 'pointer',
                }}
              >
                Cancel
              </button>
              <button
                onClick={handleAddMachine}
                style={{
                  padding: '10px 20px',
                  backgroundColor: '#007acc',
                  color: 'white',
                  border: 'none',
                  borderRadius: '8px',
                  cursor: 'pointer',
                }}
              >
                Add Machine Group
              </button>
            </div>
          </div>
        </div>
      )}
      {isEditModalOpen && (
  <div
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <div
      style={{
        backgroundColor: 'white',
        padding: '30px',
        borderRadius: '8px',
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
        width: '60%',
        maxWidth: '600px',
        transition: 'all 0.3s ease',
      }}
    >
      <h4 style={{ marginBottom: '20px', textAlign: 'center' }}>Edit Machine Group</h4>
      <div style={{ display: 'flex', gap: '30px' }}>
        <div style={{ flex: '1', display: 'flex', flexDirection: 'column', gap: '15px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <label style={{ flex: '0 0 150px', marginRight: '10px' }}>Group Name</label>
            <input
              type="text"
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
              placeholder="Enter Group Name"
              style={{
                flex: '1',
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '8px',
              }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '20px',
              marginBottom: '10px',
            }}
          >
            <div style={{ flex: '1' }}>
              <label
                htmlFor="meters"
                style={{
                  marginBottom: '5px',
                  display: 'block',
                  fontWeight: 'bold',
                }}
              >
                Machine List
              </label>
              <select
                multiple
                value={selectedMachines}
                onChange={handleeditMachineSelect}
                style={{
                  width: '100%',
                  padding: '10px',
                  border: '1px solid #ccc',
                  borderRadius: '8px',
                  marginBottom: '10px',
                }}
              >
                {machines.map((machine, index) => (
                  <option key={index} value={machine.id}>
                    {machine.machine_id}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
        <button
          onClick={() => setEditModalOpen(false)}
          style={{
            padding: '10px 20px',
            backgroundColor: '#f44336',
            color: 'white',
            border: 'none',
            borderRadius: '8px',
            cursor: 'pointer',
          }}
        >
          Cancel
        </button>
        <button
          onClick={handleEditMachine}
          style={{
            padding: '10px 20px',
            backgroundColor: '#007acc',
            color: 'white',
            border: 'none',
            borderRadius: '8px',
            cursor: 'pointer',
          }}
        >
          Save Changes
        </button>
      </div>
    </div>
  </div>
)}

    </div>

    
  );
};

export default MachineGroups;
