import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../components/header'; 
import '../scss/PageSettings.scss'; 

import BaseURL from '../assets/contants/BaseURL';

const Settings = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [userDetails, setUserDetails] = useState({ name: '', email: '', phone: '', lastLogin: '' });
  const [loading, setLoading] = useState(false);

  const fetchUserDetails = async () => {
    try {
      const token = localStorage.getItem("token");
      const storedUsername = localStorage.getItem("username");
      const loginTime = localStorage.getItem("loginTime");
      // console.log("Token:", token, "Username:", storedUsername);
  
      if (!token || !storedUsername) {
        setErrorMessage("No token or username found. Please login.");
        return;
      }
  
      const response = await axios.get(`${BaseURL}Userauth/userdetail/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
  
      // console.log("Full API Response:", response.data);
  
      const matchingUser = response.data.find(
        (user) => user.usermod?.username === storedUsername
      );
  
      if (matchingUser) {
        const {
          usermod: { first_name = "N/A", last_name = "", email = "N/A" },
          mobile_no = "N/A",
          designation = "N/A",
        } = matchingUser;
  
        const updatedDetails = {
          name: `${first_name} ${last_name}`.trim(),
          email,
          phone: mobile_no,
          designation,
          loginTime: loginTime || "N/A",
        };
  
        // console.log("Updated Details to Set:", updatedDetails);
  
        setUserDetails(updatedDetails);
      } else {
        console.log("No matching user found.");
        setErrorMessage("No user details found for the logged-in username.");
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
      setErrorMessage("Failed to load user details. Please try again.");
    }
  };
  

  useEffect(() => {
    fetchUserDetails();
  }, []);

  const handleChangePassword = async (e) => {
    e.preventDefault();

    if (!currentPassword || !newPassword || !confirmPassword) {
      setErrorMessage('Please fill in all fields.');
      return;
    }

    if (newPassword !== confirmPassword) {
      setErrorMessage('New password and confirm password do not match.');
      return;
    }
    setLoading(true); 

    try {
      const token = localStorage.getItem('token');

      const response = await axios.post(`${BaseURL}Userauth/userchangepassword/`, {
        old_password: currentPassword,
        new_password: newPassword,
        confirm_password: confirmPassword
      }, {
        headers: {
          'Authorization': `Token ${token}`
        }
      });

      console.log('Password changed successfully:', response.data);
      setErrorMessage('');
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
      setSuccessMessage('Password changed successfully!');
      setTimeout(() => {
        setSuccessMessage('');
      }, 3000);
    } catch (error) {
      console.error('Error changing password:', error);
      setErrorMessage('Failed to change password. Please try again.');
      setTimeout(() => {
        setErrorMessage('');
      }, 3000);
    }finally {
      setLoading(false); 
    }
  };

  const getUserInitials = (name) => {
    return name.split(' ').map(n => n[0]).join('').toUpperCase();
  };

  return (
    <div style={{ textAlign: 'center', width: '100%' }}>
      <div style={{
        backgroundColor: '#007acc',
        color: 'white',
        alignContent: 'center',
        fontSize: '14px',
        height: '40px'
      }}>
        <Header title="SETTINGS" />
      </div>
      <div className="main-card">
        <div className="profile-card">
          <h2 className="profile-heading">Profile</h2>
          <div className="profile-details">
            <div className="profile-info">
              <div className="info-item">
                <label style={{ fontWeight: 'bold' }}>Name:</label>
                <input type="text" value={userDetails.name || ""} disabled />
              </div>
              <div className="info-item">
                <label style={{ fontWeight: 'bold' }}>Email:</label>
                <input type="email" value={userDetails.email || ""} disabled />
              </div>
              <div className="info-item">
                <label style={{ fontWeight: 'bold' }}>Phone:</label>
                <input type="tel" value={userDetails.phone || ""} disabled />
              </div>
              <div className="info-item">
                <label style={{ fontWeight: 'bold' }}>Last Login:</label>
                <input type="text" value={userDetails.loginTime || ""} disabled />
              </div>
 </div>
              <div className="user-logo">
                <div className="user-initial-logo">{getUserInitials(userDetails.name)}</div>
              </div>
            </div>
          </div>

        <div className="profile-card">
          <h2 className="profile-heading">Reset Password</h2>
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          {successMessage && <div className="success-message">{successMessage}</div>}
          <div className="reset-password-details">
            <div className="reset-password-info">
              <div className="info-item">
                <label style={{ fontWeight: 'bold' }}>Current Password:</label>
                <input
                  type="password"
                  placeholder="Current Password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                />
              </div>
              <div className="info-item">
                <label style={{ fontWeight: 'bold' }}>New Password:</label>
                <input
                  type="password"
                  placeholder="New Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>
              <div className="info-item">
                <label style={{ fontWeight: 'bold' }}>Confirm Password:</label>
                <input
                  type="password"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
              <div className="info-item">
                <label style={{ fontWeight: 'bold' }}>&nbsp;</label>
                <button
                  className="update-btn"
                  onClick={handleChangePassword}
                  disabled={loading}
                >
                   {loading ? <div className="spinner"></div> : 'Update'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;