import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTrash, faPlus,faEdit  } from '@fortawesome/free-solid-svg-icons';
import BaseURL from '../assets/contants/BaseURL';
import axios from 'axios';
import Header from '../components/header';

const EnergyGroups = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [checkedItems, setCheckedItems] = useState({});
  const [newGroup, setNewGroup] = useState({ group_name: '', meters: [] });
  const [isModalOpen, setModalOpen] = useState(false);
  const [energyGroups, setEnergyGroups] = useState([]);
  const [meters, setMeters] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [editGroup, setEditGroup] = useState({ group_name: '', meters: [] });
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchEnergyGroups = async () => {
      try {
        const response = await axios.get(`${BaseURL}meters/emsgroups/`);
        setEnergyGroups(response.data);

        const metersResponse = await axios.get(`${BaseURL}meters/energymeters/`);
        setMeters(metersResponse.data);
      } catch (error) {
        console.error("Error fetching energy groups:", error);
      }
    };

    fetchEnergyGroups();
  }, []);

  const handleSearch = () => {
    const results = energyGroups.filter(item =>
      item.group_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setEnergyGroups(results.length > 0 ? results : []);
    setCheckedItems({});
  };

  const handleCheckboxChange = (groupId) => {
    setCheckedItems((prev) => ({
      ...prev,
      [groupId]: !prev[groupId],
    }));
  };

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    const newCheckedItems = {};
    energyGroups.forEach(item => {
      newCheckedItems[item.group_id] = isChecked; 
    });
    setCheckedItems(newCheckedItems);
  };
  const handleAddGroup = async () => {
    if (!newGroup.group_name || newGroup.meters.length === 0) {
      alert("Please fill in the group name and select meters.");
      return;
    }

    try {
      const response = await axios.post(`${BaseURL}meters/emsgroups/`, {
        group_name: newGroup.group_name,
        meter_list: newGroup.meters,
      });

      setEnergyGroups([...energyGroups, response.data]);
      setNewGroup({ group_name: '', meters: [] });
      setModalOpen(false);
      setSuccessMessage('Group added successfully!');
      setTimeout(() => setSuccessMessage(''), 3000);


      const updatedGroupsResponse = await axios.get(`${BaseURL}meters/emsgroups/`);
      setEnergyGroups(updatedGroupsResponse.data);
    } catch (error) {
      console.error("Error adding new group:", error);
    }
  };

const handleDeleteGroups = async () => {
  const confirmDelete = window.confirm("Are you sure you want to delete the selected groups?");
  if (confirmDelete) {
    
    const idsToDelete = Object.keys(checkedItems).filter(groupId => checkedItems[groupId]);

    if (idsToDelete.length === 0) {
      alert("No groups selected for deletion.");
      return;
    }

    try {
      for (let groupId of idsToDelete) {
        await axios.delete(`${BaseURL}meters/emsgroups/${groupId}/`);
        console.log(`Group with ID ${groupId} deleted.`);
      }

     
      const updatedGroups = energyGroups.filter(item => !checkedItems[item.group_id]);
      setEnergyGroups(updatedGroups);
      setCheckedItems({});
    } catch (error) {
      console.error("Error deleting groups:", error);
    }
  }
};

const handleEdit = (group) => {
  setEditGroup({
    group_name: group.group_name,
    meters: Array.isArray(group.meter) ? group.meter.map(meter => meter.id) : [], 
    group_id: group.group_id 
  });
  setEditModalOpen(true);
};
const handleUpdateGroup = async () => {
  try {
    const response = await axios.put(`${BaseURL}meters/emsgroups/${editGroup.group_id}/`, {
      group_name: editGroup.group_name,
      meter_list: editGroup.meters,
    });

   
    const updatedGroups = energyGroups.map(item => 
      item.group_id === editGroup.group_id ? response.data : item
    );
    setEnergyGroups(updatedGroups);
    setEditModalOpen(false);
    setSuccessMessage('Group updated successfully!');
    setTimeout(() => setSuccessMessage(''), 3000);
  } catch (error) {
    console.error("Error updating group:", error);
  }
};

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = energyGroups.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(energyGroups.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };
  

  return (
    <div style={{ textAlign: 'center', width: '100%' }}>
      <div style={{
        backgroundColor: '#007acc',
        color: 'white',
        alignContent: 'center',
        fontSize: '14px',
        height: '40px'
      }}>
        <Header title="EMS GROUPS" />
      </div>

      <div style={{
        border: '1px solid #ccc',
        padding: '10px',
        marginTop: '20px',
        boxShadow: '0 2px 8px rgba(0, 0,  0, 0.1)',
        backgroundColor: 'white',
        margin: '20px auto',
        maxWidth: '1000px',
      }}>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
          <div style={{ display: 'flex', alignItems: 'center', width: '30%' }}>
            <input
              type="text"
              placeholder="Search"
              style={{
                padding: '8px',
                border: '1px solid #ccc',
                borderRadius: '8px',
                flex: '1',
                marginRight: '10px',
              }}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <FontAwesomeIcon
              icon={faSearch}
              style={{ color: '#007acc', cursor: 'pointer' }}
              onClick={handleSearch}
            />
          </div>

          <div style={{ marginLeft: 'auto' }}>
            <button
              style={{
                padding: '10px 15px',
                marginRight: '10px',
                backgroundColor: '#f44336',
                color: 'white',
                border: 'none',
                borderRadius: '8px',
                cursor: 'pointer',
              }}
              onClick={handleDeleteGroups}
            >
              <FontAwesomeIcon icon={faTrash} style={{ marginRight: '5px' }} />
              Delete
            </button>
            <button
              style={{
                padding: '10px 15px',
                backgroundColor: '#4CAF50',
                color: 'white',
                border: 'none',
                borderRadius: '8px',
                cursor: 'pointer',
                marginRight: '10px',
              }}
              onClick={() => setModalOpen(true)}
            >
              <FontAwesomeIcon icon={faPlus} style={{ marginRight: '5px' }} />
              Add
            </button>
          </div>
        </div>
      </div>

      {successMessage && (
        <div style={{
          backgroundColor: '#4CAF50',
          color: 'white',
          padding: '10px',
          margin: '10px auto',
          maxWidth: '1000px',
        }}>
          {successMessage}
        </div>
      )}

      <div style={{
        marginTop: '20px',
        padding: '10px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
        backgroundColor: 'white',
        maxWidth: '1000px',
        margin: '20px auto',
        marginBottom: '70px',
      }}>
        <table style={{ width: '100%', textAlign: 'left', borderCollapse: 'collapse' }}>
          <thead style={{ backgroundColor: '#007acc', color: 'white' }}>
            <tr>
              <th style={{ padding: '10px' }}>
                <input
                  type="checkbox"
                  onChange={handleSelectAll}
                />
              </th>
              <th style={{ padding: '10px' }}>S.No</th>
              <th style={{ padding: '10px' }}>Group Name</th>
              <th style={{ padding: '10px' }}>Meters</th>
              <th style={{padding:'10px'}}>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.length > 0 ? (
              currentItems.map((item, index) => (
                <tr
                  key={item.group_id}
                  style={{
                    backgroundColor: (indexOfFirstItem + index) % 2 === 0 ? '#f2f2f2' : '#ffffff',
                  }}
                >
                  <td style={{ padding: '10px' }}>
                    <input
                      type="checkbox"
                      checked={checkedItems[item.group_id] || false}  
            onChange={() => handleCheckboxChange(item.group_id)}  
                    />
                  </td>
                  <td style={{ padding: '10px' }}>{indexOfFirstItem + index + 1}</td>
                  <td style={{ padding: '10px' }}>{item.group_name || '-'}</td>
                  <td style={{ padding: '10px' }}>
                  {item.meter.length > 0
                      ? item.meter.map((meter) => meter.meter_name).join(', ')
                      : '-'}
                  </td>
                  <td style={{padding:'10px',color:'blue',cursor:'pointer',marginLeft:'10px'}}> <FontAwesomeIcon icon={faEdit}  onClick={() => handleEdit(item)}/></td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" style={{ padding: '10px', textAlign: 'center' }}>
                  No Groups found.
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            style={{
              margin: '0 8px',
              padding: '5px 10px',
              background: currentPage === 1 ? '#007acc' : '#007acc',
              color: currentPage === 1 ? '#9e9e9e' : '#ffffff',
              border: 'none',
              borderRadius: '25px',
              cursor: currentPage === 1 ? 'not-allowed' : 'pointer',
              boxShadow: currentPage === 1 ? 'none' : '0px 4px 8px rgba(0, 0, 0, 0.2)',
              fontSize: '12px',
              transition: 'all 0.3s ease',
            }}
            onMouseEnter={(e) => {
              if (currentPage !== 1) e.target.style.boxShadow = '0px 6px 12px rgba(0, 0, 0, 0.3)';
            }}
            onMouseLeave={(e) => {
              if (currentPage !== 1) e.target.style.boxShadow = '0px 4px 8px rgba(0, 0, 0, 0.2)';
            }}
          >
            Previous
          </button>

          {Array.from({ length: totalPages }, (_, i) => (
            <button
              key={i}
              onClick={() => handlePageChange(i + 1)}
              style={{
                margin: '0 8px',
                padding: '5px 10px',
                background: currentPage === i + 1 ? '#007acc' : '#f7f7f7',
                color: currentPage === i + 1 ? '#ffffff' : '#333333',
                border: currentPage === i + 1 ? 'none' : '1px solid #ddd',
                borderRadius: '25px',
                cursor: 'pointer',
                fontWeight: 'bold',
                boxShadow: currentPage === i + 1 ? '0px 4px 8px rgba(0, 0, 0, 0.3)' : 'none',
                fontSize: '12px',
                transition: 'all 0.3s ease',
              }}
              onMouseEnter={(e) => {
                if (currentPage !== i + 1) e.target.style.background = 'linear-gradient(to right, #eeeeee, #f5f5f5)';
              }}
              onMouseLeave={(e) => {
                if (currentPage !== i + 1) e.target.style.background = '#f7f7f7';
              }}
            >
              {i + 1}
            </button>
          ))}

          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            style={{
              margin: '0 8px',
              padding: '5px 10px',
              background: currentPage === totalPages ? '#007acc' : '#007acc',
              color: currentPage === totalPages ? '#9e9e9e' : '#ffffff',
              border: 'none',
              borderRadius: '25px',
              cursor: currentPage === totalPages ? 'not-allowed' : 'pointer',
              boxShadow: currentPage === totalPages ? 'none' : '0px 4px 8px rgba(0, 0, 0, 0.2)',
              fontSize: '12px',
              transition: 'all 0.3s ease',
            }}
            onMouseEnter={(e) => {
              if (currentPage !== totalPages) e.target.style.boxShadow = '0px 6px 12px rgba(0, 0, 0, 0.3)';
            }}
            onMouseLeave={(e) => {
              if (currentPage !== totalPages) e.target.style.boxShadow = '0px 4px 8px rgba(0, 0, 0, 0.2)';
            }}
          >
            Next
          </button>
        </div>
      </div>

      {isModalOpen && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <div style={{
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '8px',
            maxWidth: '500px',
            width: '100%',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
          }}>
            <h3 style={{ marginBottom: '10px' }}>Add New Group</h3>

            <label htmlFor="group_name" style={{ marginBottom: '5px', display: 'block',marginRight:'400px',fontWeight:'bold' }}>
        Group Name 
      </label>
            <input
              type="text"
              
              placeholder="Group Name"
              value={newGroup.group_name}
              onChange={(e) => setNewGroup({ ...newGroup, group_name: e.target.value })}
              style={{
                width: '95%',
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '8px',
                marginBottom: '10px',
                marginRight:'30px'
              }}
            />
             <label htmlFor="meters" style={{ marginBottom: '5px', display: 'block',marginRight:'400px',fontWeight:'bold' }}>
         Meters List
      </label>
            <select
              multiple
              value={newGroup.meters}
              onChange={(e) => setNewGroup({ ...newGroup, meters: [...e.target.selectedOptions].map(option => option.value) })}
              style={{
                width: '100%',
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '8px',
                marginBottom: '10px',
              }}
            >
              {meters.map((meter) => (
                <option key={meter.id} value={meter.id}>
                  {meter.meter_name}
                </option>
              ))}
            </select>
            
            <button
              style={{
                width: '30%',
                padding: '12px',
                backgroundColor: '#4CAF50',
                color: 'white',
                border: 'none',
                borderRadius: '8px',
                cursor: 'pointer',
                marginBottom: '10px',
              }}
              onClick={handleAddGroup}
            >
              Add Group
            </button>
            <button
              style={{
                width: '30%',
                padding: '12px',
                backgroundColor: '#f44336',
                color: 'white',
                border: 'none',
                borderRadius: '8px',
                cursor: 'pointer',
                marginLeft:'20px'
              }}
              onClick={() => setModalOpen(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
      {isEditModalOpen && (
  <div style={{
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }}>
    <div style={{
      backgroundColor: 'white',
      padding: '20px',
      borderRadius: '8px',
      maxWidth: '500px',
      width: '100%',
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    }}>
      <h3 style={{ marginBottom: '10px' }}>Edit Group</h3>

      <label htmlFor="group_name" style={{ marginBottom: '5px', display: 'block', marginRight: '400px', fontWeight: 'bold' }}>
        Group Name 
      </label>
      <input
        type="text"
        placeholder="Group Name"
        value={editGroup.group_name}
        onChange={(e) => setEditGroup({ ...editGroup, group_name: e.target.value })}
        style={{
          width: '95%',
          padding: '10px',
          border: '1px solid #ccc',
          borderRadius: '8px',
          marginBottom: '10px',
          marginRight: '30px'
        }}
      />

      <label htmlFor="meters" style={{ marginBottom: '5px', display: 'block', marginRight: '400px', fontWeight: 'bold' }}>
        Meters List
      </label>
      <select
  multiple
  value={editGroup.meters}
  onChange={(e) => setEditGroup({ ...editGroup, meters: [...e.target.selectedOptions].map(option => option.value) })}
  style={{
    width: '100%',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '8px',
    marginBottom: '10px',
  }}
>
  {meters.map((meter) => (
    <option key={meter.id} value={meter.id}>
      {meter.meter_name}
    </option>
  ))}
</select>

      <button
        style={{
          width: '30%',
          padding: '12px',
          backgroundColor: '#4CAF50',
          color: 'white',
          border: 'none',
          borderRadius: '8px',
          cursor: 'pointer',
          marginBottom: '10px',
        }}
        onClick={handleUpdateGroup}
      >
        Update Group
      </button>
      <button
        style={{
          width: '30%',
          padding: '12px',
          backgroundColor: '#f44336',
          color: 'white',
          border: 'none',
          borderRadius: '8px',
          cursor: 'pointer',
          marginLeft: '20px'
        }}
        onClick={() => setEditModalOpen(false)}
      >
        Cancel
      </button>
    </div>
  </div>
)}
    </div>
  );
};

export default EnergyGroups;