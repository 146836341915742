import React, { useState, useEffect } from 'react';
import '../scss/dashboard.scss';
import Header from '../components/header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MdNavigateNext } from "react-icons/md";
import axios from 'axios';
import BaseURL from '../assets/contants/BaseURL';
import { useNavigate, useLocation } from 'react-router-dom';

const Groupportal = () => {
    const [view, setView] = useState('group'); 
    const [groupData, setGroupData] = useState(null);
    const [currentPageForWidgets, setCurrentPageForWidgets] = useState({});
    const [currentPageForTable, setCurrentPageForTable] = useState({});
    const itemsPerPage = 10;
    const navigate = useNavigate();
    const location = useLocation();

    const groupId = location.state?.groupId;
    const findGroup = (groupId) => {
        if (!groupData?.groups) return null;
        let groupFound = groupData.groups.find(group => group.group_id === groupId);
        if (!groupFound) {
            for (let group of groupData.groups) {
                if (group.subgroups && Array.isArray(group.subgroups)) {
                    groupFound = group.subgroups.find(subgroup => subgroup.group_id === groupId);
                }
                if (groupFound) break;
            }
        }

        return groupFound;
    };

    const filteredGroup = findGroup(groupId);

    const paginate = (array, pageNumber) => {
        const startIndex = (pageNumber - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return array.slice(startIndex, endIndex);
    };

    const widgetColors = [
        'linear-gradient(to right, #ff512f, #dd2476)',
    ];

    const handlePageChangeForWidgets = (groupName, direction) => {
        setCurrentPageForWidgets((prevState) => {
            const newPage = prevState[groupName] + direction;
            return {
                ...prevState,
                [groupName]: newPage >= 1 ? newPage : 1,
            };
        });
    };

    const handlePageChangeForTable = (groupName, direction) => {
        setCurrentPageForTable((prevState) => {
            const newPage = prevState[groupName] + direction;
            return {
                ...prevState,
                [groupName]: newPage >= 1 ? newPage : 1,
            };
        });
    };

    useEffect(() => {
        axios.get(BaseURL + '/data/groupportal/')
            .then(response => {
                setGroupData(response.data);
            })
            .catch(error => {
                console.error('Error fetching data: ', error);
            });
    }, []);

    const handleMeterClick = (meterId) => {
        navigate("/EMS/emssub", { state: { meterId } });
        console.log(meterId)
    };

    const renderWidget = (title, value, index, icon, onClick) => (
        <div
            key={index}
            onClick={onClick}
            style={{
                width: '150px',
                height: '80px',
                margin: '10px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                borderRadius: '15px',
                border: '1px solid #ddd',
                boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                position: 'relative',
                textAlign: 'left',
                padding: '15px',
                backgroundColor: widgetColors[index % widgetColors.length],
                transition: 'all 0.3s ease',
            }}
            className="widget"
        >
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                {icon && <FontAwesomeIcon icon={icon} size="3x" style={{ color: '#fff' }} />}
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <h4 style={{ margin: 0, fontSize: '0.9rem', fontWeight: 'bold', color: '#fff' }}>{title}</h4>
                    <p style={{ fontSize: '1rem', color: '#fff', fontWeight: '400' }}>{value}</p>
                </div>
            </div>
        </div>
    );

    const renderMachineWidgets = (group_name, index) => (
        <div
            key={index}
            className="machine-widgets"
            style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '20px',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '20px',
            }}
        >
            {renderWidget('Group Name:', group_name, index)}
        </div>
    );
    
    const renderMachines = (title, groupNames) => {
        const groups = Array.isArray(groupNames) ? groupNames : [groupNames];
        
        return (
            <div>
                <h2 className="heading-with-border" style={{ textAlign: 'center', marginBottom: '30px' }}>
                    {title}
                </h2>
                <div className="machines" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {groups.map((group_name, index) => renderMachineWidgets(group_name, index))}
                </div>
            </div>
        );
    };
    
    const renderCard = () => (
        <div className="group-card">
            {renderMachines('Group', filteredGroup.group_name)}
        </div>
    );

    const renderGroup1Widgets = (filteredGroup) => {
        if (!filteredGroup.meters || filteredGroup.meters.length === 0) {
            return <div>No meters available for this group.</div>;
        }
    
        const filteredGroupMeters = filteredGroup.meters;
        const currentPage = currentPageForWidgets[filteredGroup.group_name] || 1;
        const currentMachines = paginate(filteredGroupMeters, currentPage);
        const totalPages = Math.ceil(filteredGroupMeters.length / itemsPerPage);
    
        return (
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', justifyContent: 'center', marginBottom: '20px' }}>
                {currentMachines.map((machine, index) => (
                    <div
                        key={index}
                        style={{
                            width: '200px',
                            height: '90px',
                            background: widgetColors[index % widgetColors.length],
                            borderRadius: '10px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '20px',
                            boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                            textAlign: 'center',
                            cursor: 'pointer',
                        }}
                        onClick={() => handleMeterClick(machine.meter_id)}
                    >
                        <h4 style={{ margin: '5px 0', color: '#fff', fontSize: '14px' }}>{machine.meter_name}</h4>
                        <p style={{ margin: '0', color: '#fff', fontWeight: '600', fontSize: '12px' }}>kWh: {machine.Kilowatt_hour.kWh_R}</p>
                        <p style={{ margin: '0', color: '#fff', fontWeight: '600', fontSize: '12px'  }}>Cost: ₹{machine.Cost}</p>
                        <p style={{ margin: '0', color: '#fff', fontWeight: '600', fontSize: '12px'  }}>Group ID: {filteredGroup.group_id}</p> {/* Display Group ID */}
                    </div>
                ))}
                <div style={{ width: '100%', textAlign: 'center', marginTop: '20px' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                        <button
                            onClick={() => handlePageChangeForWidgets(filteredGroup.group_name, -1)}
                            disabled={currentPage === 1}
                            style={{
                                padding: '5px 10px',
                                borderRadius: '30px',
                                background: currentPage === 1 ? 'linear-gradient(to right, #ddd, #e0e0e0)' : 'linear-gradient(to right, #6a11cb, #2575fc)',
                                color: 'white',
                                border: 'none',
                                cursor: 'pointer',
                            }}
                        >
                            Previous
                        </button>
                        {[...Array(totalPages)].map((_, pageIndex) => (
                            <span
                                key={pageIndex}
                                onClick={() => handlePageChangeForWidgets(filteredGroup.group_name, pageIndex + 1)}
                                style={{
                                    padding: '5px 10px',
                                    cursor: 'pointer',
                                    borderRadius: '50%',
                                    fontWeight: 'bold',
                                    color: 'white',
                                    background: currentPage === pageIndex + 1 ? 'linear-gradient(to right, #ff8a00, #e52e71)' : 'linear-gradient(to right, #f7f7f7, #e0e0e0)',
                                }}
                            >
                                {pageIndex + 1}
                            </span>
                        ))}
                        <button
                            onClick={() => handlePageChangeForWidgets(filteredGroup.group_name, 1)}
                            disabled={currentMachines.length < itemsPerPage}
                            style={{
                                padding: '5px 10px',
                                borderRadius: '30px',
                                background: currentMachines.length < itemsPerPage ? 'linear-gradient(to right, #ddd, #e0e0e0)' : 'linear-gradient(to right, #6a11cb, #2575fc)',
                                color: 'white',
                                border: 'none',
                                cursor: 'pointer',
                            }}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    const renderGroup1Table = (filteredGroup) => {
        if (!filteredGroup.meters || filteredGroup.meters.length === 0) {
            return <div>No meters available for this group.</div>;
        }
    
        const filteredGroupMeters = filteredGroup.meters;
        const currentPage = currentPageForTable[filteredGroup.group_name] || 1;
        const currentMachines = paginate(filteredGroupMeters, currentPage);
        const totalPages = Math.ceil(filteredGroupMeters.length / itemsPerPage);
    
        const totals = currentMachines.reduce(
            (acc, meter) => {
                const kWh_R = parseFloat(meter.Kilowatt_hour.kWh_R) || 0;
                const kWh_Y = parseFloat(meter.Kilowatt_hour.kWh_Y) || 0;
                const kWh_B = parseFloat(meter.Kilowatt_hour.kWh_B) || 0;
                
                acc.todayKWh += (kWh_R + kWh_Y + kWh_B) / 3;
                return acc;
            },
            { todayKWh: 0 }
        );
    
        return (
            <div>
                <table style={{ width: '1000px', borderCollapse: 'collapse', margin: '20px auto' }}>
                    <thead style={{ backgroundColor: '#337BFF', color: '#fff' }}>
                        <tr>
                            <th style={{ padding: '10px' }}>S.No</th>
                            <th style={{ padding: '10px' }}>Meter Name</th>
                            <th style={{ padding: '10px' }}>Meter ID</th>
                            <th style={{ padding: '10px' }}>Today kWh</th>
                            <th style={{ padding: '10px' }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentMachines.map((meter, index) => (
                            <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#ffffff' }}>
                                <td style={{ padding: '10px' }}>{index + 1}</td>
                                <td style={{ padding: '10px' }}>{meter.meter_name}</td>
                                <td style={{ padding: '10px' }}>{meter.meter_id}</td>
                                <td style={{ padding: '10px' }}>{meter.Kilowatt_hour.kWh_R}</td>
                                <td style={{ padding: '10px' }}>
                                    <MdNavigateNext
                                        style={{
                                            cursor: 'pointer',
                                            color: 'dodgerblue',
                                            fontSize: '24px',
                                        }}
                                        onClick={() => handleMeterClick(meter.meter_id)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <p>Total kWh today: {totals.todayKWh.toFixed(2)}</p>
                    <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginBottom: '20px' }}>
                        <button
                            onClick={() => handlePageChangeForTable(filteredGroup.group_name, -1)}
                            disabled={currentPage === 1}
                            style={{
                                padding: '5px 10px',
                                borderRadius: '30px',
                                background: currentPage === 1 ? 'linear-gradient(to right, #ddd, #e0e0e0)' : 'linear-gradient(to right, #6a11cb, #2575fc)',
                                color: 'white',
                                border: 'none',
                                cursor: 'pointer',
                            }}
                        >
                            Previous
                        </button>
                        {[...Array(totalPages)].map((_, pageIndex) => (
                            <span
                                key={pageIndex}
                                onClick={() => handlePageChangeForTable(filteredGroup.group_name, pageIndex + 1)}
                                style={{
                                    padding: '5px 10px',
                                    cursor: 'pointer',
                                    borderRadius: '50%',
                                    fontWeight: 'bold',
                                    color: 'white',
                                    background: currentPage === pageIndex + 1 ? 'linear-gradient(to right, #ff8a00, #e52e71)' : 'linear-gradient(to right, #f7f7f7, #e0e0e0)',
                                }}
                            >
                                {pageIndex + 1}
                            </span>
                        ))}
                        <button
                            onClick={() => handlePageChangeForTable(filteredGroup.group_name, 1)}
                            disabled={currentMachines.length < itemsPerPage}
                            style={{
                                padding: '5px 10px',
                                borderRadius: '30px',
                                background: currentMachines.length < itemsPerPage ? 'linear-gradient(to right, #ddd, #e0e0e0)' : 'linear-gradient(to right, #6a11cb, #2575fc)',
                                color: 'white',
                                border: 'none',
                                cursor: 'pointer',
                            }}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        );
    };    

    const handleViewChange = (event) => {
        const selectedView = event.target.value;
        setView(selectedView);
    };

    if (!filteredGroup) {
        return <div>Loading...</div>;
    }

    return (
        <div className="dashboard" style={{ fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif" }}>
            <Header title="INDIVIDUAL GROUP" />
            <div className="content-container" style={{ padding: '20px' }}>
                <div className="card" style={{ background: '#fff', borderRadius: '10px' }}>
                    <div
                        className="dropdown-container"
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: '20px',
                            width: '1060px',
                            marginLeft: '1px',
                        }}
                    >
                        <select
                            value={view}
                            onChange={handleViewChange}
                            style={{
                                padding: '10px',
                                borderRadius: '5px',
                                border: '1px solid #ccc',
                                width: '20%',
                                fontSize: '14px',
                                cursor: 'pointer',
                                transition: 'border 0.3s ease',
                            }}
                        >
                            <option value="group">Group View</option>
                            <option value="list">List View</option>
                        </select>
                    </div>
                    <div className="machines-container">
                        {renderCard(filteredGroup.group_name, filteredGroup.meters)}
                            <div className="group-card">
                                <h2 className="heading-with-border">Meters</h2>
                                <div style={{ marginTop: '20px' }}>
                                {view === 'group'
                                    ? renderGroup1Widgets(filteredGroup)
                                    : renderGroup1Table(filteredGroup)
                                }
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Groupportal;
