import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BaseURL from '../assets/contants/BaseURL';
import Header from '../components/header'; 

const Alertssettings = () => {
    const [smtpSettings, setSmtpSettings] = useState({
        id: null,
        host: '',
        port: '',
        username: '',
        password: '',
        from_email: '',
        status: false
    });

    useEffect(() => {
        const fetchSmtpSettings = async () => {
            try {
                const response = await axios.get(`${BaseURL}/alerts/smtp/`);
                const data = response.data[0];
                setSmtpSettings({
                    id: data.id,
                    host: data.smtp_host,
                    port: data.smtp_port,
                    username: data.smtp_username,
                    password: data.smtp_password,
                    from_email: data.from_email,
                    status: data.use_ssl
                });
            } catch (error) {
                alert('Error fetching SMTP settings');
            }
        };

        fetchSmtpSettings();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSmtpSettings(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleToggleChange = () => {
        setSmtpSettings(prevState => ({
            ...prevState,
            status: !prevState.status
        }));
    };

    const handleUpdate = () => {
        
        const updateData = {
            smtp_host: smtpSettings.host,
            smtp_port: smtpSettings.port,
            smtp_username: smtpSettings.username,
            smtp_password: smtpSettings.password,
            from_email: smtpSettings.from_email,
            use_ssl: smtpSettings.status, 
            use_tls: false 
        };
    
        console.log(updateData); 
    
        axios.put(`${BaseURL}/alerts/smtp/${smtpSettings.id}/`, updateData)
            .then(response => {
                alert('Settings updated successfully!');
            })
            .catch(error => {
                console.error('Error updating settings:', error.response ? error.response.data : error);
                alert('Error updating settings');
            });
    };
    return (
        <div style={{ textAlign: 'center', width: '100%' }}>
            <div style={{
                color: 'white',
                alignContent: 'center',
                fontSize: '14px',
                height: '40px',
                marginTop: '50px'
            }}>
                <Header title="ALERTS SETTINGS" />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '20px',
                  }}
                >
                  <div
                    style={{
                      backgroundColor: '#fff',
                      borderRadius: '12px',
                      boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                      padding: '30px',
                      width: '500px',
                      marginBottom: '50px',
                    }}
                  >
                    <h3
                      style={{
                        marginBottom: '30px',
                        textAlign: 'center',
                        fontSize: '1.5rem',
                        color: '#333',
                        fontWeight: 'bold',
                        display: 'inline-block',
                        paddingBottom: '5px',
                      }}
                    >
                      SMTP Settings
                    </h3>

                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                        gap: '20px',
                        marginBottom: '20px',
                      }}
                    >
                      <div>
                        <label
                          style={{
                            fontWeight: 'bold',
                            display: 'block',
                            marginBottom: '8px',
                            fontSize: '0.9rem',
                            color: '#555',
                          }}
                        >
                          SMTP Host
                        </label>
                        <input
                          type="text"
                          name="host"
                          value={smtpSettings.host}
                          onChange={handleInputChange}
                          placeholder="Enter SMTP Host"
                          style={{
                            width: '80%',
                            padding: '12px',
                            borderRadius: '8px',
                            border: '1px solid #ccc',
                            boxShadow: 'inset 0 1px 3px rgba(0, 0, 0, 0.1)',
                            fontSize: '0.9rem',
                          }}
                        />
                      </div>

                      {/* Field 2 */}
                      <div>
                        <label
                          style={{
                            fontWeight: 'bold',
                            display: 'block',
                            marginBottom: '8px',
                            fontSize: '0.9rem',
                            color: '#555',
                          }}
                        >
                          SMTP Port
                        </label>
                        <input
                          type="text"
                          name="port"
                          value={smtpSettings.port}
                          onChange={handleInputChange}
                          placeholder="Enter SMTP Port"
                          style={{
                            width: '80%',
                            padding: '12px',
                            borderRadius: '8px',
                            border: '1px solid #ccc',
                            boxShadow: 'inset 0 1px 3px rgba(0, 0, 0, 0.1)',
                            fontSize: '0.9rem',
                          }}
                        />
                      </div>

                      {/* Field 3 */}
                      <div>
                        <label
                          style={{
                            fontWeight: 'bold',
                            display: 'block',
                            marginBottom: '8px',
                            fontSize: '0.9rem',
                            color: '#555',
                          }}
                        >
                          Username
                        </label>
                        <input
                          type="text"
                          name="username"
                          value={smtpSettings.username}
                          onChange={handleInputChange}
                          placeholder="Enter Username"
                          style={{
                            width: '80%',
                            padding: '12px',
                            borderRadius: '8px',
                            border: '1px solid #ccc',
                            boxShadow: 'inset 0 1px 3px rgba(0, 0, 0, 0.1)',
                            fontSize: '0.9rem',
                          }}
                        />
                      </div>

                      {/* Field 4 */}
                      <div>
                        <label
                          style={{
                            fontWeight: 'bold',
                            display: 'block',
                            marginBottom: '8px',
                            fontSize: '0.9rem',
                            color: '#555',
                          }}
                        >
                          Password
                        </label>
                        <input
                          type="password"
                          name="password"
                          value={smtpSettings.password}
                          onChange={handleInputChange}
                          placeholder="Enter Password"
                          style={{
                            width: '80%',
                            padding: '12px',
                            borderRadius: '8px',
                            border: '1px solid #ccc',
                            boxShadow: 'inset 0 1px 3px rgba(0, 0, 0, 0.1)',
                            fontSize: '0.9rem',
                          }}
                        />
                      </div>
                    </div>

                    {/* Single Row Field */}
                    <div style={{ marginBottom: '20px' }}>
                      <label
                        style={{
                          fontWeight: 'bold',
                          display: 'block',
                          marginBottom: '8px',
                          fontSize: '0.9rem',
                          color: '#555',
                        }}
                      >
                        From Email
                      </label>
                      <input
                        type="text"
                        name="from_email"
                        value={smtpSettings.from_email}
                        onChange={handleInputChange}
                        placeholder="Enter From Email"
                        style={{
                          width: '80%',
                          padding: '12px',
                          borderRadius: '8px',
                          border: '1px solid #ccc',
                          boxShadow: 'inset 0 1px 3px rgba(0, 0, 0, 0.1)',
                          fontSize: '0.9rem',
                        }}
                      />
                    </div>

                    <div style={{ marginBottom: '30px', textAlign: 'center' }}>
                      <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                        <label
                          style={{
                            fontWeight: '600',
                            color: '#555',
                            fontSize: '14px',
                            marginRight: '10px',
                          }}
                        >
                          Status:
                        </label>
                        <input
                          type="checkbox"
                          id="status-toggle"
                          style={{ display: 'none' }}
                          checked={smtpSettings.status}
                          onChange={handleToggleChange}
                        />
                        <label
                          htmlFor="status-toggle"
                          style={{
                            width: '50px',
                            height: '24px',
                            backgroundColor: smtpSettings.status ? '#4caf50' : '#ccc',
                            borderRadius: '50px',
                            position: 'relative',
                            cursor: 'pointer',
                            transition: 'background-color 0.3s',
                          }}
                        >
                          <span
                            style={{
                              position: 'absolute',
                              top: '4px',
                              left: smtpSettings.status ? '26px' : '4px',
                              width: '16px',
                              height: '16px',
                              backgroundColor: 'white',
                              borderRadius: '50%',
                              transition: '0.3s',
                            }}
                          />
                        </label>
                      </div>
                    </div>

                    <button
                      onClick={handleUpdate}
                      style={{
                        backgroundColor: '#007acc',
                        color: 'white',
                        padding: '12px 30px',
                        border: 'none',
                        borderRadius: '8px',
                        cursor: 'pointer',
                        width: '100%',
                        fontSize: '1rem',
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
                      }}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>

    );
};

export default Alertssettings;