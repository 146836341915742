import React, { useState, useEffect,useRef } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTrash, faPlus, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import BaseURL from '../assets/contants/BaseURL';
import Header from '../components/header';

const Alerts = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);
  const [alertsData, setAlertsData] = useState([]);
  const [parameters, setParameters] = useState({});
  const [meters, setMeters] = useState({});
  const [usersData, setUsersData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [selectedAlerts, setSelectedAlerts] = useState({});
  const [newAlert, setNewAlert] = useState({
    parameterName: '',
    meterId: '',
    minValue: '',
    maxValue: '',
    users: [],
    emailNotification: false,
    message: ''
  });
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const indexOfLastAlert = currentPage * itemsPerPage;
  const indexOfFirstAlert = indexOfLastAlert - itemsPerPage;
  const currentAlerts = alertsData.slice(indexOfFirstAlert, indexOfLastAlert);

  const totalPages = Math.ceil(alertsData.length / itemsPerPage);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [alertToEdit, setAlertToEdit] = useState(null);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleEditAlert = (alert) => {
    setAlertToEdit(alert);
    setEditModalOpen(true);
  };

  const modalRef = useRef();
  const editModalRef = useRef();
  useEffect(() => {
    const fetchAlerts = async () => {
      try {
        const alertsResponse = await axios.get(`${BaseURL}/alerts/alerts/`);
        setAlertsData(alertsResponse.data);

        const parametersResponse = await axios.get(`${BaseURL}/alerts/electrical-parameters/`);
        const parametersMap = parametersResponse.data.reduce((map, obj) => {
          map[obj.id] = obj.parameter;
          return map;
        }, {});
        setParameters(parametersMap);

        const metersResponse = await axios.get(`${BaseURL}/meters/energymeters/`);
        const metersMap = metersResponse.data.reduce((map, obj) => {
          map[obj.id] = obj.meter_id;
          return map;
        }, {});
        setMeters(metersMap);

        const token = localStorage.getItem('token');
        const usersResponse = await axios.get(`${BaseURL}Userauth/userdetail/`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });

        // console.log('Users Response:', usersResponse.data);

        const usersMap = usersResponse.data.reduce((map, obj) => {
          if (obj.usermod && obj.usermod.username) {
            map[obj.user_id] = obj.usermod.username;
          } else {
            console.error('Missing usermod or username:', obj);
          }
          return map;
        }, {});

        setUsersData(usersMap);

      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAlerts();
  }, []);

  const handleSearch = () => {
    const filteredData = alertsData.filter(alert =>
      parameters[alert.parameter_name]?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      meters[alert.meter_id]?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setAlertsData(filteredData);
  };

  const handleAddAlert = async () => {
    try {
      const alertWithEmailAction = {
        min_value: newAlert.minValue,
          max_value: newAlert.maxValue,
          action: newAlert.emailNotification ? 'email' : 'none',
          message: newAlert.message,
          parameter_name: newAlert.parameterName,
          meter_id: newAlert.meterId,
          users: newAlert.users,
      };

      const response = await axios.post(`${BaseURL}/alerts/alerts/`, alertWithEmailAction);
      // console.log('Alert Added:', response.data);
      setAlertsData((prevData) => [...prevData, response.data]);
      setModalOpen(false);
    } catch (error) {
      console.error('Error adding alert:', error);
    }
  };

  const handleParameterChange = (event) => {
    const selectedParameterId = event.target.value;
    setNewAlert((prevAlert) => ({
      ...prevAlert,
      parameterName: selectedParameterId,
    }));
  };

  const handleMeterChange = (event) => {
    const selectedMeterId = event.target.value;
    setNewAlert((prevAlert) => ({
      ...prevAlert,
      meterId: selectedMeterId,
    }));
  };

  const handleMinValueChange = (event) => {
    const minValue = event.target.value;
    setNewAlert((prevAlert) => ({
      ...prevAlert,
      minValue: minValue,
    }));
  };

  const handleMaxValueChange = (event) => {
    const maxValue = event.target.value;
    setNewAlert((prevAlert) => ({
      ...prevAlert,
      maxValue: maxValue,
    }));
  };

  const toggleEmailNotification = () => {
    setNewAlert(prevState => ({
      ...prevState,
      emailNotification: !prevState.emailNotification,
    }));
  };

  const handleUserSelectChange = (e) => {
    const selectedUsers = Array.from(e.target.selectedOptions, option => option.value);
    setNewAlert(prevState => ({
      ...prevState,
      users: selectedUsers,
    }));
  };

  const handleRemoveUser = (userId) => {
    setNewAlert(prevState => ({
      ...prevState,
      users: prevState.users.filter(id => id !== userId),
    }));
  };

  const handleMessageChange = (event) => {
    setNewAlert({
      ...newAlert,
      message: event.target.value,
    });
  };

  const handleDeleteSelectedAlerts = async () => {
    const alertsToDelete = Object.keys(selectedAlerts).filter(alertId => selectedAlerts[alertId]);
    
    if (alertsToDelete.length === 0) {
      alert("No alerts selected for deletion.");
      return;
    }
  
    try {
      await Promise.all(alertsToDelete.map(alertId => 
        axios.delete(`${BaseURL}/alerts/alerts/${alertId}/`)
      ));
      
     
      setAlertsData(prevData => prevData.filter(alert => !alertsToDelete.includes(alert.id.toString())));
      setSelectedAlerts({}); 
    } catch (error) {
      console.error('Error deleting alerts:', error);
    }
  };
  const handleCheckboxChange = (alertId) => {
    setSelectedAlerts((prevSelected) => ({
      ...prevSelected,
      [alertId]: !prevSelected[alertId], 
    }));
  };
  
  const handleSelectAllCheckbox = (event) => {
    const isChecked = event.target.checked;
    const newSelectedAlerts = {};
    currentAlerts.forEach(alert => {
      newSelectedAlerts[alert.id] = isChecked; 
    });
    setSelectedAlerts(newSelectedAlerts);
  };

  useEffect(() => {
    const handleMouseDown = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setModalOpen(false); 
      }
    };
  
    document.addEventListener('mousedown', handleMouseDown);
    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, [modalRef]);
  useEffect(() => {
    const handleMouseDown = (event) => {
      if (editModalRef.current && !editModalRef.current.contains(event.target)) {
        setEditModalOpen(false);
      }
    };
  
    if (isEditModalOpen) {
      document.addEventListener('mousedown', handleMouseDown);
    }
  
    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, [isEditModalOpen]);



  return (
    <div style={{ textAlign: 'center', width: '100%' }}>
      <div style={{
        backgroundColor: '#007acc',
        color: 'white',
        alignContent: 'center',
        fontSize: '14px',
        height: '40px'
      }}>
        <Header title="ALERTS" />
      </div>

      <div style={{
        border: '1px solid #ccc',
        padding: '10px',
        marginTop: '20px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
        backgroundColor: 'white',
        margin: '20px auto',
        maxWidth: '1000px',
      }}>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
          <div style={{ display: 'flex', alignItems: 'center', width: '30%' }}>
            <input
              type="text"
              placeholder="Search"
              style={{
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '8px',
                flex: '1',
                marginRight: '10px',
              }}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <FontAwesomeIcon
              icon={faSearch}
              style={{ color: '#007acc', cursor: 'pointer' }}
              onClick={handleSearch}
            />
          </div>

          <div style={{ marginLeft: 'auto' }}>
        
            <button
              style={{
                padding: '10px 15px',
                marginRight: '10px',
                backgroundColor: '#f44336',
                color: 'white',
                border: 'none',
                borderRadius: '8px',
                cursor: 'pointer',
              }}
              onClick={handleDeleteSelectedAlerts}
            >
              <FontAwesomeIcon icon={faTrash} style={{ marginRight: '5px' }} />
              Delete
            </button>
            <button
              style={{
                padding: '10px 15px',
                backgroundColor: '#4CAF50',
                color: 'white',
                border: 'none',
                borderRadius: '8px',
                cursor: 'pointer',
              }}
              onClick={() => setModalOpen(true)}
            >
              <FontAwesomeIcon icon={faPlus} style={{ marginRight: '5px' }} />
              Add
            </button>
          </div>
        </div>
      </div>

      <div style={{ marginTop: '20px', padding: '10px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)', backgroundColor: 'white', maxWidth: '1000px', margin: '20px auto' }}>
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <div>
            <table style={{ width: '100%', textAlign: 'left', borderCollapse: 'collapse' }}>
              <thead style={{ backgroundColor: '#007acc', color: 'white' }}>
                <tr>
                  <th style={{ padding: '10px' }}>
                    <input type="checkbox"  onChange={handleSelectAllCheckbox} />
                  </th>
                  <th style={{ padding: '10px' }}>S.No</th>
                  <th style={{ padding: '10px' }}>Parameter Name</th>
                  <th style={{ padding: '10px' }}>Meter ID</th>
                  <th style={{ padding: '10px' }}>Min Value</th>
                  <th style={{ padding: '10px' }}>Max Value</th>
                  <th style={{ padding: '10px' }}>Send to User</th>
                  <th style={{ padding: '10px' }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentAlerts.map((alert, index) => (
                  <tr key={alert.id} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#ffffff', cursor: 'pointer'}}
                  onClick={() => handleEditAlert(alert)}
                  >
                  <td style={{ padding: '10px' }}>
                    <input 
                  type="checkbox" 
                  checked={!!selectedAlerts[alert.id]} 
                  onChange={() => handleCheckboxChange(alert.id)} 
                  />
                    </td>
                    <td style={{ padding: '10px' }}>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                    <td style={{ padding: '10px' }}>{parameters[alert.parameter_name]}</td>
                    <td style={{ padding: '10px' }}>{meters[alert.meter_id]}</td>
                    <td style={{ padding: '10px' }}>{alert.min_value}</td>
                    <td style={{ padding: '10px' }}>{alert.max_value}</td>
                    <td style={{ padding: '10px' }}>
                      {alert.users && alert.users.length > 0 ? (
                        alert.users.map(userId => usersData[userId] || 'Unknown').join(', ')
                      ) : (
                        'No users'
                      )}
                    </td>
                    <td style={{ padding: '10px' }}>
                      <button style={{ padding: '5px 10px', backgroundColor: '#007acc', color: 'white', border: 'none', borderRadius: '8px' }}>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              <button
                onClick={handlePrevPage}
                disabled={currentPage === 1}
                style={{
                  padding: '5px 10px',
                  background: currentPage === 1 ? '#007acc' : '#007acc',
                  color: 'white',
                  border: 'none',
                  borderRadius: '25px',
                  margin: '0 5px',
                  cursor: currentPage === 1 ? 'not-allowed' : 'pointer',
                  transition: 'all 0.3s ease',
                  fontSize: '12px',
                }}
              >
                Previous
              </button>
              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index + 1}
                  onClick={() => handlePageClick(index + 1)}
                  style={{
                    margin: '0 8px',
                    padding: '5px 10px',
                    background: currentPage === index + 1 ? '#007acc' : '#f7f7f7',
                    color: currentPage === index + 1 ? '#ffffff' : '#333333',
                    border: currentPage === index + 1 ? 'none' : '1px solid #ddd',
                    borderRadius: '25px',
                    cursor: 'pointer',
                    boxShadow: currentPage === index + 1 ? '0px 4px 8px rgba(0, 0, 0, 0.3)' : 'none',
                    fontSize: '12px',
                    transition: 'all 0.3s ease',
                  }}
                  onMouseEnter={(e) => {
                    if (currentPage !== index + 1) e.target.style.background = 'linear-gradient(to right, #eeeeee, #f5f5f5)';
                  }}
                  onMouseLeave={(e) => {
                    if (currentPage !== index + 1) e.target.style.background = '#f7f7f7';
                  }}
                >
                  {index + 1}
                </button>
              ))}
              <button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
                style={{
                  padding: '5px 10px',
                  background: currentPage === totalPages ? '#007acc' : '#007acc',
                  color: 'white',
                  border: 'none',
                  borderRadius: '25px',
                  margin: '0 5px',
                  cursor: currentPage === totalPages ? 'not-allowed' : 'pointer',
                  transition: 'all 0.3s ease',
                  fontSize: '12px',
                }}
              >
                Next
              </button>
            </div>
          </div>
        )}
      </div>
      {isModalOpen && (
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
         ref={modalRef} 
          style={{
            backgroundColor: 'white',
            padding: '25px',
            borderRadius: '10px',
            boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
            maxWidth: '900px',
            maxHeight: '70vh',
            overflowY: 'auto',
            width: '100%',
          }}
        >
          <h3 style={{ marginBottom: '20px', textAlign: 'center', color: '#333' }}>
            Add New Alerts
          </h3>

          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '-10px' }}>
            <div style={{ width: '30%' }}>
              <div style={{ marginBottom: '15px' }}>
                <label style={{ marginBottom: '8px', display: 'block', fontWeight: 'bold' }}>
                  Parameter Name
                </label>
                <select
                  style={{
                    padding: '10px',
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                    width: '100%',
                    marginBottom: '10px',
                  }}
                  value={newAlert.parameterName}
                  onChange={handleParameterChange}
                >
                  <option value="">Select Parameter</option>
                  {Object.keys(parameters).map((parameterId) => (
                    <option key={parameterId} value={parameterId}>
                      {parameters[parameterId]}
                    </option>
                  ))}
                </select>
              </div>

              <div style={{ marginBottom: '15px' }}>
                <label style={{ marginBottom: '8px', display: 'block', fontWeight: 'bold' }}>
                  Meter ID
                </label>
                <select
                  style={{
                    width: '100%',
                    padding: '10px',
                    borderRadius: '5px',
                    border: '1px solid #ccc',
                    marginBottom: '10px',
                  }}
                  value={newAlert.meterId}
                  onChange={handleMeterChange}
                >
                  <option value="">Select Meter ID</option>
                  {Object.keys(meters).map((meterId) => (
                    <option key={meterId} value={meterId}>
                      {meters[meterId]}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div style={{ width: '45%' }}>
              <div style={{ display: 'flex', flexDirection: 'column', marginTop: '-5px' }}>
                <div style={{ marginBottom: '15px' }}>
                  <label style={{ marginBottom: '8px', display: 'block', fontWeight: 'bold' }}>
                    Min Value
                  </label>
                  <input
                    type="number"
                    placeholder="Min Value"
                    value={newAlert.minValue}
                    onChange={handleMinValueChange}
                    style={{
                      width: '60%',
                      padding: '10px',
                      borderRadius: '5px',
                      border: '1px solid #ccc',
                    }}
                  />
                </div>

                <div style={{ marginTop: '4px' }}>
                  <label style={{ marginBottom: '8px', display: 'block', fontWeight: 'bold' }}>
                    Max Value
                  </label>
                  <input
                    type="number"
                    placeholder="Max Value"
                    value={newAlert.maxValue}
                    onChange={handleMaxValueChange}
                    style={{
                      width: '60%',
                      padding: '10px',
                      borderRadius: '5px',
                      border: '1px solid #ccc',
                    }}
                  />
                </div>
              </div>
              <div style={{ marginTop: '20px' }}>
                <label style={{ marginBottom: '-23px', display: 'flex', fontWeight: 'bold' }}>
                  Email :
                </label>
                <button
                  style={{
                    width: '40px',
                    height: '25px',
                    backgroundColor: newAlert.emailNotification ? 'green' : 'red',
                    border: 'none',
                    borderRadius: '15px',
                    color: 'white',
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: newAlert.emailNotification ? 'flex-end' : 'flex-start',
                    padding: '2px',
                    marginBottom: '16px',
                    marginLeft: '60px',
                  }}
                  aria-label="Toggle Email"
                  onClick={toggleEmailNotification}
                >
                  <div
                    style={{
                      width: '20px',
                      height: '20px',
                      borderRadius: '50%',
                      backgroundColor: 'white',
                    }}
                  ></div>
                </button>
              </div>
            </div>
          </div>

          <div style={{ display: 'flex', marginBottom: '20px' }}>
            <div style={{ width: '48%', marginRight: '4%' }}>
              <label style={{ marginBottom: '8px', display: 'flex', fontWeight: 'bold' }}>
                User Select
              </label>
              <select
                multiple
                style={{
                  width: '100%',
                  padding: '10px',
                  borderRadius: '5px',
                  border: '1px solid #ccc',
                  marginRight: '450px',
                  height: '100px',
                }}
                value={newAlert.users}
                onChange={handleUserSelectChange}
              >
                {/* <option value="">Select User</option> */}
                {Object.entries(usersData).map(([userId, username]) => (
                  <option key={userId} value={userId}>
                    {username}
                  </option>
                ))}
              </select>
            </div>
            <div style={{ width: '48%' }}>
              <label style={{ marginBottom: '8px', display: 'block', fontWeight: 'bold' }}>
                Added Users
              </label>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '10px',
                  borderRadius: '5px',
                  border: '1px solid #ccc',
                  backgroundColor: '#f9f9f9',
                  height: '80px',
                  overflowY: 'auto',
                }}
              >
                {newAlert.users.map(userId => (
                  <div
                    key={userId}
                    style={{
                      backgroundColor: '#e0e0e0',
                      marginBottom: '5px',
                      padding: '8px',
                      borderRadius: '3px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <span>{usersData[userId]}</span>
                    <button
                      onClick={() => handleRemoveUser(userId)}
                      style={{
                        backgroundColor: 'red',
                        color: 'white',
                        border: 'none',
                        padding: '5px',
                        borderRadius: '5px',
                        cursor: 'pointer',
                      }}
                    >
                      Remove
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div style={{ marginBottom: '20px' }}>
            <label style={{ marginBottom: '8px', display: 'block', fontWeight: 'bold' }}>
              Message
            </label>
            <textarea
              value={newAlert.message}
              onChange={handleMessageChange}
              placeholder="Enter your message"
              style={{
                width: '50%',
                padding: '10px',
                borderRadius: '5px',
                border: '1px solid #ccc',
                resize: 'none',
                minHeight: '100px',
              }}
            />
          </div>

          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', gap: '15px' }}>
            <button
              style={{
                backgroundColor: '#4CAF50',
                color: 'white',
                border: 'none',
                padding: '10px 20px',
                borderRadius: '5px',
                cursor: 'pointer',
                width: '20%',
              }}
              onClick={handleAddAlert}
            >
              Add
            </button>
            <button
              style={{
                backgroundColor: '#f44336',
                color: 'white',
                border: 'none',
                padding: '10px 20px',
                borderRadius: '5px',
                cursor: 'pointer',
                width: '20%',
              }}
              onClick={() => setModalOpen(false)}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    )}
{isEditModalOpen && alertToEdit && (
  <div
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <div
     ref={editModalRef} 
      style={{
        backgroundColor: 'white',
        padding: '25px',
        borderRadius: '10px',
        boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
        maxWidth: '900px',
        maxHeight: '70vh',
        overflowY: 'auto',
        width: '100%',
      }}
    >
      <h3 style={{ marginBottom: '20px', textAlign: 'center', color: '#333' }}>
        Edit Alert
      </h3>

      {/* Similar structure as Add Modal */}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '-10px' }}>
        <div style={{ width: '30%' }}>
          <div style={{ marginBottom: '15px' }}>
            <label style={{ marginBottom: '8px', display: 'block', fontWeight: 'bold' }}>
              Parameter Name
            </label>
            <select
              style={{
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '8px',
                width: '100%',
                marginBottom: '10px',
              }}
              value={alertToEdit.parameter_name}
              onChange={(e) => setAlertToEdit({ ...alertToEdit, parameter_name: e.target.value })}
            >
              <option value="">Select Parameter</option>
              {Object.keys(parameters).map((parameterId) => (
                <option key={parameterId} value={parameterId}>
                  {parameters[parameterId]}
                </option>
              ))}
            </select>
          </div>

          <div style={{ marginBottom: '15px' }}>
            <label style={{ marginBottom: '8px', display: 'block', fontWeight: 'bold' }}>
              Meter ID
            </label>
            <select
              style={{
                width: '100%',
                padding: '10px',
                borderRadius: '5px',
                border: '1px solid #ccc',
                marginBottom: '10px',
              }}
              value={alertToEdit.meter_id}
              onChange={(e) => setAlertToEdit({ ...alertToEdit, meter_id: e.target.value })}
            >
              <option value="">Select Meter ID</option>
              {Object.keys(meters).map((meterId) => (
                <option key={meterId} value={meterId}>
                  {meters[meterId]}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div style={{ width: '45%' }}>
          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '-5px' }}>
            <div style={{ marginBottom: '15px' }}>
              <label style={{ marginBottom: '8px', display: 'block', fontWeight: 'bold' }}>
                Min Value
              </label>
              <input
                type="number"
                placeholder="Min Value"
                value={alertToEdit.min_value}
                onChange={(e) => setAlertToEdit({ ...alertToEdit, min_value: e.target.value })}
                style={{
                  width: '60%',
                  padding: '10px',
                  borderRadius: '5px',
                  border: '1px solid #ccc',
                }}
              />
            </div>

            <div style={{ marginTop: '4px' }}>
              <label style={{ marginBottom: '8px', display: 'block', fontWeight: 'bold' }}>
                Max Value
              </label>
              <input
                type="number"
                placeholder="Max Value"
                value={alertToEdit.max_value}
                onChange={(e) => setAlertToEdit({ ...alertToEdit, max_value: e.target.value })}
                style={{
                  width: '60%',
                  padding: '10px',
                  borderRadius: '5px',
                  border: '1px solid #ccc',
                }}
              />
            </div>
          </div>
          <div style={{ marginTop: '20px' }}>
            <label style={{ marginBottom: '-23px', display: 'flex', fontWeight: 'bold' }}>
              Email :
            </label>
            <button
              style={{
                width: '40px',
                height: '25px',
                backgroundColor: alertToEdit.action === 'email' ? 'green' : 'red',
                border: 'none',
                borderRadius: '15px',
                color: 'white',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: alertToEdit.action === 'email' ? 'flex-end' : 'flex-start',
                padding: '2px',
                marginBottom: '16px',
                marginLeft: '60px',
              }}
              aria-label="Toggle Email"
              onClick={() => setAlertToEdit({ ...alertToEdit, action: alertToEdit.action === 'email' ? 'none' : 'email' })}
            >
              <div
                style={{
                  width: '20px',
                  height: '20px',
                  borderRadius: '50%',
                  backgroundColor: 'white',
                }}
              ></div>
            </button>
          </div>
        </div>
      </div>

      <div style={{ display: 'flex', marginBottom: '20px' }}>
        <div style={{ width: '48%', marginRight: '4%' }}>
          <label style={{ marginBottom: '8px', display: 'flex', fontWeight: 'bold' }}>
            User Select
          </label>
          <select
            multiple
            style={{
              width: '100%',
              padding: '10px',
              borderRadius: '5px',
              border: '1px solid #ccc',
              height: '100px',
            }}
            value={alertToEdit.users}
            onChange={(e) => {
              const selectedUsers = Array.from(e.target.selectedOptions, option => option.value);
              setAlertToEdit({ ...alertToEdit, users: selectedUsers });
            }}
          >
            {Object.entries(usersData).map(([userId, username]) => (
              <option key={userId} value={userId}>
                {username}
              </option>
            ))}
          </select>
        </div>
        <div style={{ width: '48%' }}>
          <label style={{ marginBottom: '8px', display: 'block', fontWeight: 'bold' }}>
            Added Users
          </label>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '10px',
              borderRadius: '5px',
              border: '1px solid #ccc',
              backgroundColor: '#f9f9f9',
              height: '80px',
              overflowY: 'auto',
            }}
          >
            {alertToEdit.users.map(userId => (
              <div
                key={userId}
                style={{
                  backgroundColor: '#e0e0e0',
                  marginBottom: '5px',
                  padding: '8px',
                  borderRadius: '3px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <span>{usersData[userId]}</span>
                <button
                  onClick={() => setAlertToEdit({ ...alertToEdit, users: alertToEdit.users.filter(id => id !== userId) })}
                  style={{
                    backgroundColor: 'red',
                    color: 'white',
                    border: 'none',
                    padding: '5px',
                    borderRadius: '5px',
                    cursor: 'pointer',
                  }}
                >
                  Remove
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div style={{ marginBottom: '20px' }}>
        <label style={{ marginBottom: '8px', display: 'block', fontWeight: 'bold' }}>
          Message
        </label>
        < textarea
          value={alertToEdit.message}
          onChange={(e) => setAlertToEdit({ ...alertToEdit, message: e.target.value })}
          placeholder="Enter your message"
          style={{
            width: '50%',
            padding: '10px',
            borderRadius: '5px',
            border: '1px solid #ccc',
            resize: 'none',
            minHeight: '100px',
          }}
        />
      </div>

      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', gap: '15px' }}>
        <button
          style={{
            backgroundColor: '#4CAF50',
            color: 'white',
            border: 'none',
            padding: '10px 20px',
            borderRadius: '5px',
            cursor: 'pointer',
            width: '20%',
          }}
          onClick={async () => {
            try {
              await axios.put(`${BaseURL}/alerts/alerts/${alertToEdit.id}/`, alertToEdit);
              setAlertsData(prevData => prevData.map(alert => alert.id === alertToEdit.id ? alertToEdit : alert));
              setEditModalOpen(false);
            } catch (error) {
              console.error('Error updating alert:', error);
            }
          }}
        >
          Save
        </button>
        <button
          style={{
            backgroundColor: '#f44336',
            color: 'white',
            border: 'none',
            padding: '10px 20px',
            borderRadius: '5px',
            cursor: 'pointer',
            width: '20%',
          }}
          onClick={() => setEditModalOpen(false)}
        >
          Close
        </button>
      </div>
    </div>
  </div>
)}
</div>
  );
};

export default Alerts;