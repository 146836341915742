import React from 'react';
import { useNavigate } from 'react-router-dom';

const VerificationPage = () => {
    const navigate = useNavigate();
    const containerStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        fontFamily: 'Arial, sans-serif',
        position: 'relative',
        color: '#FFFFFF',
        overflow: 'hidden',
    };

    const backgroundStyle = {
        backgroundImage: 'url("https://images.unsplash.com/photo-1517336714731-489689fd1ca8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8MTY0MjY1Mzg4Ng&ixlib=rb-1.2.1&q=80&w=1080")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: -1,
        filter: 'brightness(0.4)', 
    };

    const overlayStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.6)', 
    };

    const messageBoxStyle = {
        background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.25))',
        padding: '60px 50px', 
        borderRadius: '15px',
        textAlign: 'center',
        maxWidth: '500px', 
        width: '90%',
        minHeight: '200px', 
        backdropFilter: 'blur(15px)', 
        boxShadow: '0px 8px 30px rgba(0, 0, 0, 0.5)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center', 
        justifyContent: 'center',
    };

    const messageStyle = {
        fontSize: '26px',
        fontWeight: '600',
        color: '#FFFFFF',
        marginBottom: '30px',
        textShadow: '1px 1px 3px rgba(0, 0, 0, 0.8)',
    };

    const buttonStyle = {
        padding: '12px 32px',
        fontSize: '18px',
        fontWeight: 'bold',
        backgroundColor: '#005fa3',
        color: '#FFFFFF',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        transition: 'transform 0.2s ease, background-color 0.3s ease',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)',
    };

    const handleMouseOver = (e) => {
        e.currentTarget.style.backgroundColor = '#004080'; 
        e.currentTarget.style.transform = 'scale(1.05)'; 
    };

    const handleMouseOut = (e) => {
        e.currentTarget.style.backgroundColor = '#005fa3'; 
        e.currentTarget.style.transform = 'scale(1)'; 
    };
    const handleClick = () => {
        navigate('/pending-verification');
    };

    return (
        <div style={containerStyle}>
            <div style={backgroundStyle}></div>
            <div style={overlayStyle}></div>
            <div style={messageBoxStyle}>
                <div style={messageStyle}>Verification mail has been sent to your email.</div>
                <button
                    style={buttonStyle}
                    onClick={handleClick}
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                >
                    OK
                </button>
            </div>
        </div>
    );
};

export default VerificationPage;
