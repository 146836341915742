import React from 'react';
import { Route, Routes } from 'react-router-dom';
import EmsSub from './EMS/EmsSub';
import Emsmain from './EMS/EmsMain';
import EnergyMeter from './EMS/EnergyMeter';
import EMSGroups from './EMS/EmsGroups';
import SubGroup from './EMS/subgroup';
import EmsSubGroup from './EMS/EmsSubGroup';
import Reports from './EMS/Reports';
import Alerts from './EMS/Alerts';
import UsersGroups from './EMS/UsersGroups';
import Settings from './EMS/Settings';
import Groupportal from './EMS/Groupportal';
import PeakTimings from './EMS/PeakTimings';
import Productionreport from './EMS/Productionreport';
import Alertssettings from './EMS/Alertssettings';
import MachineList from './EMS/Machinelist';
import MachineGroups from './EMS/Machinegroups';
import ProductionDetails from './EMS/Productiondetails';
import AlertReports from './EMS/Alertreport';
import ReportCard from './EMS/Reportcard';


const RoutesComponent = () => {
  return (
    <Routes>
       <Route path="/EMS/dashboard" element={<Emsmain/>} />
       <Route path="/EMS/emssub" element={<EmsSub />} />
       <Route path="/EMS/EmsSubGroup" element={<EmsSubGroup />} />
       <Route path="/EMS/energymeter" element={<EnergyMeter />} />
       <Route path="/EMS/report" element={<ReportCard />} />
       
       <Route path="/EMS/emsgroups" element={<EMSGroups />} />
       <Route path="/EMS/group-portal" element={<Groupportal/>} />
       <Route path="/EMS/subgroup" element={<SubGroup/>} />
       <Route path="/EMS/machine" element={<MachineList/>}/>
       <Route path="/EMS/groups" element={<MachineGroups/>}/>
       <Route path="/EMS/productiondetails" element={<ProductionDetails/>}/>

       <Route path="/EMS/reports" element={<Reports/>}/>
       <Route path="/EMS/productionreports"element={<Productionreport/>}/>
       <Route path="/EMS/alertreports"element={<AlertReports/>}/>

       <Route path="/EMS/alerts" element={<Alerts/>}/>
       <Route path="/EMS/alertssettings"element={<Alertssettings/>}/>
       <Route path="/EMS/usersgroups" element={<UsersGroups/>}/>
       <Route path="/EMS/settings" element={<Settings/>}/>
       <Route path="/EMS/peaktimings" element={<PeakTimings/>}/>
    </Routes>
  );
};

export default RoutesComponent;
