import React from 'react';
import { useSelector } from 'react-redux';
import AppSidebar from '../components/AppSidebar';
import AppFooter from '../components/AppFooter';
// import AppHeader from '../components/AppHeader';
import RoutesComponent from '../routes';
import '../scss/Layout.scss';

const DefaultLayout = () => {
  const sidebarShow = useSelector((state) => state.sidebarShow);
  const unfoldable = useSelector((state) => state.sidebarUnfoldable);

  return (
    <div className="layout-container">
      <AppSidebar className={`${sidebarShow ? 'visible' : ''} ${unfoldable ? 'unfoldable' : ''}`} />
      <div className="main-content">
        {/* <AppHeader /> */}
        <div className="content-area">
          <RoutesComponent />
        </div>
        <AppFooter />
      </div>
    </div>
  );
};

export default DefaultLayout;
