import React, { useState,useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'; 
// import SimpleBar from 'simplebar-react';
import 'simplebar-core/dist/simplebar.css';
import navigation from '../_nav'; 
import 'font-awesome/css/font-awesome.min.css';
import '../scss/Appsidebar.scss';


const AppSidebar = (isSidebarFolded ) => {
  const dispatch = useDispatch();
  const unfoldable = useSelector((state) => state.sidebarUnfoldable);
  const sidebarShow = useSelector((state) => state.sidebarShow);

  const [openDropdown, setOpenDropdown] = useState(null);

  const handleDropdownToggle = (index) => {
    setOpenDropdown(openDropdown === index ? null : index);  
  };

  useEffect(() => {
    if (isSidebarFolded) {
      setOpenDropdown(null);
    }
  }, [isSidebarFolded]);


  // const username = localStorage.getItem('username') || 'Admin';
  // const userInitial = username.charAt(0).toUpperCase();

  return (
    <div className="app-container">
      <div 
        className={`app-sidebar ${sidebarShow ? 'visible' : 'hidden'} ${unfoldable ? 'unfoldable' : ''}`}
        style={{
          position: 'fixed',
          overflowY: 'auto',
          backgroundColor: '#047BC4',
          color: 'white',
          width: unfoldable ? '200px' : '55px',
          transition: 'width 0.3s',
        }}
        onMouseEnter={() => dispatch({ type: 'SET_SIDEBAR_UNFOLDABLE', payload: true })} 
onMouseLeave={() => dispatch({ type: 'SET_SIDEBAR_UNFOLDABLE', payload: false })}

      >
       <div className="sidebar-brand">
          <img
            src="/brad.png"
            alt="Logo"
            className="sidebar-logo"
            style={{
              width: unfoldable ? '120px' : '53.5px', 
              height: 'auto',
              transition: 'width 0.3s ease-in-out',
            }}
          />
        </div>

        <nav className="sidebar-nav" style={{ width: '250px', color: 'white', marginTop: '20px', }}>
  <ul style={{ listStyle: 'none', padding: '0', margin: '0' }}>
    {Array.isArray(navigation) ? (
      navigation.map((item, index) => (
        <li
          key={index}
          style={{
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            marginBottom: '5px',
          }}
        >
          {item.submenu ? (
            <>
              <div
                onClick={() => handleDropdownToggle(index)}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  padding: '12px 10px', 
                  borderRadius: '5px',
                }}
              >
                 <span style={{ marginRight: '10px', fontSize: '18px' }}>{item.icon}</span>
                {unfoldable && (
                  <span style={{ fontSize: '16px', fontWeight: 'bold', flex: 1 }}>
                    {item.name}
                  </span>
                )}
                {unfoldable && ( 
    <span>
      {openDropdown === index ? (
        <i className="fas fa-chevron-up" style={{ fontSize: '12px' }} />
      ) : (
        <i className="fas fa-chevron-down" style={{ fontSize: '12px' }} />
      )}
    </span>
  )}
</div>

              {openDropdown === index && (
                <ul
                  style={{
                    marginTop: '5px',
                    paddingLeft: '20px',
                    // borderLeft: '3px solid #666',
                  }}
                >
                  {item.submenu.map((subItem, subIndex) => (
                    <li key={subIndex} style={{ margin: '5px 0' }}>
                      <Link
                        to={subItem.path}
                        style={{
                          fontSize: '14px',
                          color: 'white',
                          textDecoration: 'none',
                          display: 'block',
                          padding: '5px 10px',
                          borderRadius: '5px',
                          transition: 'background 0.3s',
                        }}
                        onMouseOver={(e) => (e.target.style.backgroundColor = 'transparent')}
                        onMouseOut={(e) => (e.target.style.backgroundColor = 'transparent')}
                      >
                        {subItem.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </>
          ) : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '12px 10px',
                // backgroundColor: '#444',
                borderRadius: '5px',
              }}
            >
              <span style={{ marginRight: unfoldable ? '10px' : '0', fontSize: '18px' }}>
                {item.icon}
              </span>
              {unfoldable && (
                <Link
                  to={item.path}
                  style={{
                    color: 'white',
                    textDecoration: 'none',
                    fontSize: '16px',
                    fontWeight: 'bold',
                  }}
                >
                  {item.name}
                </Link>
              )}
            </div>
          )}
        </li>
      ))
    ) : (
      <li style={{ padding: '10px', color: 'red' }}>Error: Navigation data is not an array</li>
    )}
  </ul>
</nav>




        {/* Optional user section */}
        {/* Uncomment if needed */}
        {/* {unfoldable && ( 
          <div className="admin-section" style={{margin: '10px', color: 'black', marginTop: '162 px', display: 'flex', alignItems: 'center', marginRight: '10px' }}>
            <div 
              className="user-avatar" 
              style={{ 
                backgroundColor: 'orange', 
                color: 'white', 
                borderRadius: '50%', 
                width: '40px', 
                height: '40px', 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center', 
                fontSize: '14px', 
                fontWeight: 'bold',
                marginRight: '15px',
              }}
            >
              {userInitial}
            </div>
            <div>
              <div style={{ fontSize: '12px', color: 'white' }}>{username}</div>
            </div>
          </div>
        )} */}
      </div>
      
      <div className="main-content">
        {/* Main content area */}
      </div>
    </div>
  );
};

export default React.memo(AppSidebar);