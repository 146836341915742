import React,{useState,useEffect} from 'react';
import Header from '../components/header';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload,faSearch } from '@fortawesome/free-solid-svg-icons';
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import BaseURL from '../assets/contants/BaseURL';
import axios from 'axios';


const AlertReports = () => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [alertReports, setAlertReports] = useState([]);
  const [selectedMeter, setSelectedMeter] = useState(null);
  const [usersData, setUsersData] = useState({});
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [alertDetails, setAlertDetails] = useState({
    message: '',
    user: '',
    minValue: '',
    maxValue: '',
  });

  useEffect(() => {
    const fetchAlertReports = async () => {
      try {
        const response = await axios.get(`${BaseURL}alerts/alert-reports/`);
        setAlertReports(response.data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchAlertReports();
  }, []);

   useEffect(() => {
      const fetchGroups = async () => {
        try {
          const response = await axios.get(`${BaseURL}meters/emsgroups/`);
          setGroups(response.data);
        } catch (error) {
          console.error("Error fetching EMS group data:", error);
        }
      };
    
      fetchGroups();
    }, []);

    useEffect(() => {
      const fetchAlerts = async () => {
        try {
          const token = localStorage.getItem('token');
          const usersResponse = await axios.get(`${BaseURL}Userauth/userdetail/`, {
            headers: {
              Authorization: `Token ${token}`,
            },
          });

          const usersMap = usersResponse.data.reduce((map, obj) => {
            if (obj.usermod && obj.usermod.username) {
              map[obj.user_id] = obj.usermod.username;
            } else {
              console.error('Missing usermod or username:', obj);
            }
            return map;
          }, {});
  
          setUsersData(usersMap);
  
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
  
      fetchAlerts();
    }, []);

    const handleRowClick = (message, minValue, maxValue, users) => {
      const userName = users && users.length > 0 ? users.map(id => usersData[id]).join(", ") : "N/A";
      setAlertDetails({ message, minValue, maxValue, user: userName });
      setSelectedUsers(users);
      setShowModal(true);
  };
  
    const handleClose = () => {
      setShowModal(false);
    };
  

    return (
        <div style={{ textAlign: 'center', width: '100%' }}>
          <div style={{
           backgroundColor: '#007acc',
           color: 'white',
           alignContent: 'center',
           fontSize: '14px',
           height:'40px'
          }}>
           <Header title="ALERT REPORTS" />
          </div>
          <div style={{
            border: '1px solid #ccc',
            padding: '10px',
            marginTop: '20px',
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
            backgroundColor: 'white',
            maxWidth: '1000px',
            marginLeft: 'auto',
            marginRight: 'auto',
            borderRadius: '8px'
      }}>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
         <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
             <label style={{ fontWeight: "bold" }}>From Date:</label>
             <Datetime
               selected={fromDate}
               onChange={(date) => setFromDate(date)}
              inputProps={{
                placeholder: "Select From Date Time",
                style: { borderRadius: "10px", border: "1px solid #ccc", padding: "10px", width: "80%" }
              }}
            />

            <label style={{ fontWeight: "bold" }}>To Date:</label>
            <Datetime
              selected={toDate}
              onChange={(date) => setToDate(date)}
              inputProps={{
                placeholder: "Select To Date Time",
                style: { borderRadius: "10px", border: "1px solid #ccc", padding: "10px", width: "80%" }
              }}
            />
          </div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <select
              onChange={(e) => setSelectedMeter(e.target.value)}
              style={{
                // padding: '5px',
                fontSize: '12px',
                width: '200px',
                height: '35px',
                border: '1px solid #ccc',
                borderRadius: '8px',
              }}
            >
              <option value="">Select Meter</option>
              {groups.map((group) =>
                group.meter.map((meter) => (
                  <option key={`${group.group_id}-${meter.meter_id}`} value={meter.meter_id}>
                    {`${group.group_name} - ${meter.meter_name}`}
                  </option>
                ))
              )}
              </select>
            </div>
            <button
            style={{
              padding: '8px 12px',
              backgroundColor: '#007acc',
              color: 'white',
              border: 'none',
              cursor: 'pointer',
              borderRadius: '8px',
            }}
            // onClick={fetchReportData}
          >
            <FontAwesomeIcon icon={faSearch} />
          </button>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <button style={{
              display: 'flex',
              alignItems: 'center',
              padding: '8px 12px',
              backgroundColor: '#007acc',
              color: 'white',
              border: 'none',
              cursor: 'pointer',
              borderRadius: '8px'
            }}>
              <span>Download</span>
              <FontAwesomeIcon icon={faDownload} style={{ marginLeft: '5px' }} />
            </button>
            <select
            style={{
              padding: '5px 10px',
              fontSize: '16px',
              borderRadius: '8px',
              border: '1px solid #ccc',
              backgroundColor: '#f9f9f9',
              color: '#333',
              cursor: 'pointer',
              transition: 'all 0.3s ease',
              outline: 'none',
              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
            }}
          >
          <option value="pdf">PDF</option>
          <option value="csv">CSV</option>
        </select>
        </div>
        </div>
        </div>
        <div
        style={{
          marginTop: '20px',
          padding: '10px',
          boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
          backgroundColor: 'white',
          maxWidth: '1000px',
          margin: '20px auto',
          borderRadius: '8px',
        }}
    >
  <table style={{ width: '100%', textAlign: 'left', borderCollapse: 'collapse' }}>
    <thead>
      <tr style={{ backgroundColor: '#007acc', color: 'white' }}>
        <th style={{ padding: '10px' }}>S.No</th>
        <th style={{ padding: '10px' }}>Date</th>
        <th style={{ padding: '10px' }}>Time</th>
        <th style={{ padding: '10px' }}>Meter ID</th>
        <th style={{ padding: '10px' }}>Parameter Name</th>
        <th style={{ padding: '10px' }}>Min Value</th>
        <th style={{ padding: '10px' }}>Max Value</th>
        <th style={{ padding: '10px' }}>Actual Value</th>
      </tr>
    </thead>
    <tbody>
    {alertReports.map((report, index) => (
    <tr onClick={() => handleRowClick(report.message, report.min_value, report.max_value, report.users)} style={{ cursor: 'pointer' }}>
        <td style={{ padding: '10px' }}>{index + 1}</td>
                <td style={{ padding: '10px' }}>{report.date}</td>
                <td style={{ padding: '10px' }}>{report.time}</td>
                <td style={{ padding: '10px' }}>{report.meter_id}</td>
                <td style={{ padding: '10px' }}>{report.parameter_name}</td>
                <td style={{ padding: '10px' }}>{report.min_value}</td>
                <td style={{ padding: '10px' }}>{report.max_value}</td>
                <td style={{ padding: '10px' }}>{report.actual_value}</td>
              </tr>
            ))}
            {alertReports.length === 0 && (
              <tr>
                <td colSpan="8" style={{ padding: '10px', textAlign: 'center' }}>
                  No data available
                </td>
              </tr>
            )}
    </tbody>
  </table>
</div>

{showModal && (
  <div className="modal" style={{
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '350px',
    padding: '25px',
    boxShadow: '0 8px 20px rgba(0, 0, 0, 0.25)',
    backgroundColor: 'white',
    zIndex: 1000,
    borderRadius: '12px',
    border: '1px solid #ddd',
  }}>
    <h2 style={{
      marginBottom: '20px',
      textAlign: 'center',
      color: '#333',
    }}>Alert Details</h2>
    <div style={{
      marginBottom: '15px',
      padding: '10px',
      borderRadius: '8px',
      backgroundColor: '#f9f9f9',
      border: '1px solid #ddd',
    }}>
      <strong style={{ display: 'block', color: '#555' }}>Message:</strong>
      <span style={{ display: 'block', fontSize: '14px', paddingLeft: '5px' }}>{alertDetails.message || 'N/A'}</span>
    </div>
    <div style={{
      marginBottom: '15px',
      padding: '10px',
      borderRadius: '8px',
      backgroundColor: '#f9f9f9',
      border: '1px solid #ddd',
    }}>
      <strong style={{ display: 'block', color: '#555' }}>User:</strong>
      <select
                multiple
                value={selectedUsers}
                onChange={(e) =>setSelectedUsers(Array.from(e.target.selectedOptions, (option) => Number(option.value)))}
                style={{
                  width: '100%',
                  padding: '10px',
                  borderRadius: '5px',
                  border: '1px solid #ccc',
                  marginRight: '450px',
                  height: '100px',
                }}
                // onChange={handleUserSelectChange}
              >
                {Object.entries(usersData).map(([userId, username]) => (
                  <option key={userId} value={userId}>
                    {username}
                  </option>
                ))}
              </select>
    </div>
    <div style={{
      marginBottom: '15px',
      padding: '10px',
      borderRadius: '8px',
      backgroundColor: '#f9f9f9',
      border: '1px solid #ddd',
    }}>
      <strong style={{ display: 'block', color: '#555' }}>Min Value:</strong>
      <span style={{ display: 'block', fontSize: '14px', paddingLeft: '5px' }}>{alertDetails.minValue || 'N/A'}</span>
    </div>
    <div style={{
      marginBottom: '20px',
      padding: '10px',
      borderRadius: '8px',
      backgroundColor: '#f9f9f9',
      border: '1px solid #ddd',
    }}>
      <strong style={{ display: 'block', color: '#555' }}>Max Value:</strong>
      <span style={{ display: 'block', fontSize: '14px', paddingLeft: '5px' }}>{alertDetails.maxValue || 'N/A'}</span>
    </div>
    <button onClick={handleClose} style={{
      display: 'block',
      width: '100%',
      padding: '10px',
      backgroundColor: '#f44336',
      color: 'white',
      border: 'none',
      cursor: 'pointer',
      borderRadius: '5px',
      textAlign: 'center',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
      transition: 'background-color 0.3s',
    }}
  >
      Close
    </button>
  </div>
)}



</div>
);
};

export default AlertReports;