import React, { useState, useEffect,useCallback } from 'react';
import BaseURL from '../assets/contants/BaseURL';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTrash, faPlus,faEdit } from '@fortawesome/free-solid-svg-icons';
import Header from '../components/header';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [checkedItems, setCheckedItems] = useState({});
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [uuidModalOpen, setUuidModalOpen] = useState(false);
  const [currentUser , setCurrentUser ] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [newUser, setNewUser] = useState({
    name: '',
    mobile_no: '',
    email: '',
    password: '',
    confirm_password: '',
    user_active: false,
  });

  const handleAuthError = (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('token');
      window.location.href = '/';
    } else {
      console.error("An error occurred:", error);
    }
  };

  const handleToggle = () => setIsActive(!isActive);

  const fetchUsers = useCallback(() => {
    setIsLoading(true);
    const token = localStorage.getItem('token');
    axios.get(BaseURL + 'Userauth/userdetail/', {
      headers: {
        'Authorization': `Token ${token}`
      }
    })
      .then(response => {
        const transformedData = response.data.map(user => ({
          ...user,
          name: `${user.usermod.first_name} ${user.usermod.last_name}`.trim(),
          id: user.usermod.id,
          userdetail_id: user.userdetail_id
        }));
        setUsers(transformedData);
        setFilteredUsers(transformedData);
        setIsLoading(false);
      })
      .catch(error => {
        handleAuthError(error);
        setIsLoading(false);
      });
  }, []); 
  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);
  

  const handleSearch = () => {
    const results = users.filter(item =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredUsers(results.length > 0 ? results : []);
    setCheckedItems({});
  };

 
  

  const handleEditUser  = (user) => {
    setCurrentUser (user);
    setIsActive(user.userActive);
    setUserModalOpen(true);
  };
  const handleDeleteUsers = () => {
    const confirmDelete = window.confirm("Are you sure you want to delete the selected users?");
    if (confirmDelete) {
      const token = localStorage.getItem('token');
      const userIdsToDelete = Object.keys(checkedItems).filter(user_id => checkedItems[user_id]);
      userIdsToDelete.forEach(user_id => {
        const userToDelete = users.find(user => user.id === parseInt(user_id));
        if (userToDelete) {
          const { userdetail_id } = userToDelete;
          axios.delete(`${BaseURL}Userauth/userdetail/${userdetail_id}/`, {
            headers: {
              Authorization: `Token ${token}`
            }
          })
          .then(response => {
            console.log(`User with userdetail_id ${userdetail_id} deleted successfully`);
            
            fetchUsers();
          })
          .catch(error => {
            console.error("Error deleting user:", error);
            
          });
        }
      });
  
      
      setCheckedItems({});
    }
  };
  
  
  
  
  
  const handleCheckboxChange = (id) => {
    setCheckedItems(prev => {
      const updated = { ...prev, [id]: !prev[id] };
      
      
      console.log("Checkbox Changed for ID:", id);
      console.log("Updated Checked Items:", updated);
  
      return updated;
    });
  };
  

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    const updatedCheckedItems = {};
    filteredUsers.forEach(item => {
      updatedCheckedItems[item.id] = isChecked;
    });
    setCheckedItems(updatedCheckedItems);
  
    
    console.log("Select All Toggled:", isChecked);
    console.log("Updated Checked Items:", updatedCheckedItems);
  };
  


  const openUuidModal = () => {
    setUuidModalOpen(true);
  };

  // const openUserModal = (user) => {
  //   setCurrentUser(user);
  //   setIsActive(user.userActive);
  //   setUserModalOpen(true);
  // };
  
  const handleAddUser = () => {
    const token = localStorage.getItem('token');
    
    if (
      !newUser.name ||
      !newUser.email ||
      !newUser.password ||
      !newUser.designation ||
      newUser.password !== newUser.confirm_password
    ) {
      alert('Please fill all fields correctly and ensure passwords match.');
      return;
    }
  
    axios
      .post(
        BaseURL + 'Userauth/web-create/', 
        {
          name: newUser.name,
          mobile_no: newUser.mobile_no,
          email: newUser.email,
          password: newUser.password,
          designation: newUser.designation || "",
          user_active: newUser.user_active,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((response) => {
        // setUsers((prevUsers) => [...prevUsers, response.data]);
        // setFilteredUsers((prevUsers) => [...prevUsers, response.data]);
        fetchUsers();
        setUuidModalOpen(false);
        setNewUser({
          name: '',
          mobile_no: '',
          email: '',
          password: '',
          confirm_password: '',
          designation: '',
          user_active: false,
        });
      })
      .catch((error) => {
        handleAuthError(error);
      });
  };

 

  const handleUpdateUser  = () => {
    const token = localStorage.getItem('token');
    axios.put(BaseURL +`Userauth/userdetail/${currentUser.id}/`, {
      ...currentUser ,
      userActive: isActive
    }, {
      headers: {
        'Authorization':`Token ${token}`
      }
    })
      .then(response => {
        const updatedUsers = users.map(user =>
          user.id === currentUser.id ? { ...user, ...response.data } : user );
        setUsers(updatedUsers);
        setFilteredUsers(updatedUsers);
        setUserModalOpen(false);
        setCurrentUser (null);
      })
      .catch(error => {
        handleAuthError(error);
      });
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ textAlign: 'center', width: '100%' }}>
      <div style={{
        backgroundColor: '#007acc',
        color: 'white',
        alignContent: 'center',
        fontSize: '14px',
        height: '40px'
      }}>
        <Header title="USERS" />
      </div>

      <div style={{
        border: '1px solid #ccc',
        padding: '10px',
        marginTop: '20px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
        backgroundColor: 'white',
        margin: '20px auto',
        maxWidth: '1000px',
      }}>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
          <div style={{ display: 'flex', alignItems: 'center', width: '30%' }}>
            <input
              type="text"
              placeholder="Search"
              style={{
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '8px',
                flex: '1',
                marginRight: '10px',
              }}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <FontAwesomeIcon
              icon={faSearch}
              style={{ color: '#007acc', cursor: 'pointer' }}
              onClick={handleSearch}
            />
          </div>

          <div style={{ marginLeft: 'auto' }}>
            <button
              style={{
                padding: '10px 15px',
                marginRight: '10px',
                backgroundColor: '#f44336',
                color: 'white',
                border: 'none',
                borderRadius: '8px',
                cursor: 'pointer',
              }}
              onClick={handleDeleteUsers}
            >
              <FontAwesomeIcon icon={faTrash} style={{ marginRight: '5px' }} />
              Delete
            </button>
            <button
              style={{
                padding: '10px 15px',
                backgroundColor: '#4CAF50',
                color: 'white',
                border: 'none',
                borderRadius: '8px',
                cursor: 'pointer',
                marginRight: '10px',
              }}
              onClick={openUuidModal}
            >
              <FontAwesomeIcon icon={faPlus} style={{ marginRight: '5px' }} />
              Add
            </button>
          </div>
        </div>
      </div>

      <div style={{
        marginTop: '20px',
        padding: '10px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
        backgroundColor: 'white',
        maxWidth: '1000px',
        margin: '20px auto'
      }}>
        <table style={{ width: '100%', textAlign: 'left', borderCollapse: 'collapse' }}>
          <thead style={{ backgroundColor: '#007acc', color: 'white' }}>
            <tr>
              <th style={{ padding: '10px' }}>
                <input
                  type="checkbox"
                  onChange={handleSelectAll}

                  
                />
              </th>
              <th style={{ padding: '10px' }}>S.No</th>
              <th style={{ padding: '10px' }}>Name</th>
              <th style={{ padding: '10px' }}>Email</th>
              <th style={{ padding: '10px' }}>Phone</th>
              <th style={{ padding: '10px' }}>Designation</th>
              <th style={{ padding: '10px' }}>Status</th>
             <th style={{padding:'10px'}}>Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.length > 0 ? (
              filteredUsers
                .slice()
                .reverse()
                .map((item, index) => (
                  <tr
                    key={item.id}
                    style={{
                      backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white',
                  
                    }}
                  >
                    <td style={{ padding: '10px' }}>
                      <input
                        type="checkbox"
                        checked={!!checkedItems[item.id]}
                        onChange={() => handleCheckboxChange(item.id)}
                      />
                    </td>
                    <td style={{ padding: '10px' }}>{index + 1}</td>
                    <td style={{ padding: '10px' }}>{item.usermod.first_name}</td>
                    <td style={{ padding: '10px' }}>{item.usermod.email}</td>
                    <td style={{ padding: '10px' }}>{item.mobile_no}</td>
                    <td style={{ padding: '10px' }}>{item.designation}</td>
                    <td style={{ padding: '10px' }}>{item.userActive ? 'Active' : 'Inactive'}</td>
                    <td style={{ padding: '10px' }}>
                      <FontAwesomeIcon 
                        icon={faEdit} 
                        style={{ cursor: 'pointer', color: '#007acc' }} 
                        onClick={() => handleEditUser (item)} 
                      />
                    </td>
                  </tr>
                ))
            ) : (
              <tr>
                <td colSpan="7" style={{ padding: '10px', textAlign: 'center' }}>
                  No results found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {uuidModalOpen && (
      <div
        style={{
          position: 'fixed',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          // zIndex: '1000',
        }}
      >
        <div
          style={{
            backgroundColor: '#fff',
            padding: '30px',
            borderRadius: '16px',
            width: '600px',
            maxWidth: '90%',
            boxShadow: '0 10px 20px rgba(0, 0, 0, 0.15)',
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          <button
            onClick={() => setUuidModalOpen(false)}
            style={{
              position: 'absolute',
              top: '15px',
              right: '15px',
              background: 'none',
              border: 'none',
              color: '#888',
              fontSize: '22px',
              cursor: 'pointer',
            }}
          >
            ✖
          </button>

          <h3 style={{ textAlign: 'center', marginBottom: '20px', fontSize: '22px', fontWeight: '600', color: '#333' }}>Add User</h3>
          
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleAddUser();
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
              <div style={{ width: '48%' }}>
                <label style={{ fontWeight: '600', color: '#555', fontSize: '14px' }}>Name:</label>
                <input
                  type="text"
                  placeholder="Enter Name"
                  style={{
                    width: '100%',
                    padding: '14px',
                    margin: '8px 0',
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                    fontSize: '14px',
                    outline: 'none',
                    boxSizing: 'border-box',
                  }}
                  value={newUser.name}
                  onChange={(e) => setNewUser({ ...newUser, name: e.target.value })}
                />
              </div>

              <div style={{ width: '48%' }}>
                <label style={{ fontWeight: '600', color: '#555', fontSize: '14px' }}>Mobile No:</label>
                <input
                  type="text"
                  placeholder="Enter Mobile No"
                  style={{
                    width: '100%',
                    padding: '14px',
                    margin: '8px 0',
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                    fontSize: '14px',
                    outline: 'none',
                    boxSizing: 'border-box',
                  }}
                  value={newUser.mobile_no}
                  onChange={(e) => setNewUser({ ...newUser, mobile_no: e.target.value })}
                />
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
              <div style={{ width: '48%' }}>
                <label style={{ fontWeight: '600', color: '#555', fontSize: '14px' }}>Email:</label>
                <input
                  type="email"
                  placeholder="Enter Email"
                  style={{
                    width: '100%',
                    padding: '14px',
                    margin: '8px 0',
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                    fontSize: '14px',
                    outline: 'none',
                    boxSizing: 'border-box',
                  }}
                  value={newUser.email}
                  onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
                />
              </div>

              <div style={{ width: '48%' }}>
                <label style={{ fontWeight: '600', color: '#555', fontSize: '14px' }}>Designation:</label>
                <input
                  type="text"
                  placeholder="Enter Designation"
                  style={{
                    width: '100%',
                    padding: '14px',
                    margin: '8px 0',
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                    fontSize: '14px',
                    outline: 'none',
                    boxSizing: 'border-box',
                  }}
                  value={newUser.designation}
                  onChange={(e) => setNewUser({ ...newUser, designation: e.target.value })}
                />
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
              <div style={{ width: '48%' }}>
                <label style={{ fontWeight: '600', color: '#555', fontSize: '14px' }}>Password:</label>
                <input
                  type="password"
                  placeholder="Enter Password"
                  style={{
                    width: '100%',
                    padding: '14px',
                    margin: '8px 0',
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                    fontSize: '14px',
                    outline: 'none',
                    boxSizing: 'border-box',
                  }}
                  value={newUser.password}
                  onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
                />
              </div>

              <div style={{ width: '48%' }}>
                <label style={{ fontWeight: '600', color: '#555', fontSize: '14px' }}>Confirm Password:</label>
                <input
                  type="password"
                  placeholder="Confirm Password"
                  style={{
                    width: '100%',
                    padding: '14px',
                    margin: '8px 0',
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                    fontSize: '14px',
                    outline: 'none',
                    boxSizing: 'border-box',
                  }}
                  value={newUser.confirm_password}
                  onChange={(e) => setNewUser({ ...newUser, confirm_password: e.target.value })}
                />
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <label style={{ fontWeight: '600', color: '#555', fontSize: '14px', marginRight: '10px' }}>User Active:</label>
                <input
                  type="checkbox"
                  id="custom-checkbox"
                  style={{
                    display: 'none',
                  }}
                  checked={newUser.user_active}
                  onChange={(e) => setNewUser({ ...newUser, user_active: e.target.checked })}
                />
                <label
                  htmlFor="custom-checkbox"
                  style={{
                    width: '40px',
                    height: '20px',
                    backgroundColor: newUser.user_active ? '#4caf50' : '#ccc',
                    borderRadius: '50px',
                    position: 'relative',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s',
                  }}
                >
                  <span
                    style={{
                      position: 'absolute',
                      top: '3px',
                      left: newUser.user_active ? '20px' : '3px',
                      width: '14px',
                      height: '14px',
                      backgroundColor: 'white',
                      borderRadius: '50%',
                      transition: '0.3s',
                    }}
                  />
                </label>
              </div>
            </div>
            <div style={{ textAlign: 'center' }}>
              <button
                type="submit"
                style={{
                  backgroundColor: '#4CAF50',
                  color: 'white',
                  padding: '12px 18px',
                  borderRadius: '8px',
                  border: 'none',
                  cursor: 'pointer',
                  fontSize: '16px',
                  transition: 'background-color 0.3s ease',
                }}
                onMouseEnter={(e) => (e.target.style.backgroundColor = '#45a049')}
                onMouseLeave={(e) => (e.target.style.backgroundColor = '#4CAF50')}
              >
                Add User
              </button>
            </div>
          </form>
        </div>
      </div>
    )}
      {userModalOpen && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '20px',
          overflow: 'hidden',
          zIndex: 1000,
        }}>
          <div style={{
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '10px',
            width: '80%',
            maxWidth: '550px',
            maxHeight: '70vh',
            overflowY: 'auto',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
          }}>
            <h3 style={{ textAlign: 'center', marginBottom: '20px' }}>User  Details</h3>

            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }}>
              <div style={{ flex: 1, marginRight: '10px' }}>
                <label style={{ fontWeight: 'bold', display: 'block', marginBottom: '5px' }}>Name:</label>
                <input
                  type="text"
                  placeholder="Enter Name"
                  value={currentUser  ? currentUser.usermod.first_name : ''}
                  onChange={(e) => setCurrentUser ({ ...currentUser , usermod: { ...currentUser.usermod, first_name: e.target.value } })}
                  style={{
                    width: '90%',
                    padding: '10px',
                    borderRadius: '6px',
                    border: '1px solid #ccc',
                    outline: 'none'
                  }}
                />
              </div>
              <div style={{ flex: 1 }}>
                <label style={{ fontWeight: 'bold', display: 'block', marginBottom: '5px' }}>Designation:</label>
                <input
                  type="text"
                  placeholder="Enter Designation"
                  value={currentUser  ? currentUser.designation : ''}
                  onChange={(e) => setCurrentUser ({ ...currentUser , designation: e.target.value })}
                  style={{
                    width: '90%',
                    padding: '10px',
                    borderRadius: '6px',
                    border: '1px solid #ccc',
                    outline: 'none'
                  }}
                />
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }}>
              <div style={{ flex: 1, marginRight: '10px' }}>
                <label style={{ fontWeight: 'bold', display: 'block', marginBottom: '5px' }}>Phone:</label>
                <input
                  type="text"
                  placeholder="Enter Phone"
                  value={currentUser  ? currentUser.mobile_no : ''}
                  onChange={(e) => setCurrentUser ({ ...currentUser , mobile_no: e.target.value })}
                  style={{
                    width: '90%',
                    padding: '10px',
                    borderRadius: '6px',
                    border: '1px solid #ccc',
                    outline: 'none'
                  }}
                />
              </div>
              <div style={{ flex: 1 }}>
                <label style={{ fontWeight: 'bold', display: 'block', marginBottom: '5px' }}>Email:</label>
                <input
                  type="email"
                  placeholder="Enter Email"
                  value={currentUser  ? currentUser.usermod.email : ''}
                  onChange={(e) => setCurrentUser ({ ...currentUser , usermod: { ...currentUser.usermod, email: e.target.value } })}
                  style={{
                    width: '90%',
                    padding: '10px',
                    borderRadius: '6px',
                    border: '1px solid #ccc',
                    outline: 'none'
                  }}
                />
              </div>
            </div>

            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
              <label style={{ fontWeight: 'bold', marginRight: '10px' }}>Status:</label>

              <label style={{
                position: 'relative',
                display: 'inline-block',
                width: '40px',
                height: '20px',
              }}>
                <input
                  type="checkbox"
                  style={{ opacity: 0, width: 0, height: 0 }}
                  checked={isActive}
                  onChange={handleToggle}
                />

                <span style={{
                  position: 'absolute',
                  cursor: 'pointer',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: isActive ? '#4CAF50' : '#ccc',
                  transition: '0.4s',
                  borderRadius: '20px',
                }} />

                <span style={{
                  position: 'absolute',
                  height: '16px',
                  width: '16px',
                  backgroundColor: 'white',
                  borderRadius: '50%',
                  top: '2px',
                  left: isActive ? '22px' : '2px',
                  transition: '0.4s',
                }} />
              </label>
			  

              <span style={{ marginLeft: '10px' }}>{isActive ? 'Active' : 'Inactive'}</span>
            </div>


            {["Dashboard", "Energy Meter", "EMS Groups", "Alerts", "Users"].map((section) => (
        <div key={section} style={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#e6f7ff',
          padding: '5px',
          borderRadius: '8px',
          marginBottom: '15px',
        }}>
          <label style={{
            fontWeight: 'bold',
            marginRight: '20px',
            minWidth: '150px'
          }}>
            {section}:
          </label>

          <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
            {["Read", "Write", "Update", "Delete"].map((permission) => (
              <label key={permission} style={{
                display: 'flex',
                alignItems: 'center',
                padding: '6px 6px',
                borderRadius: '4px',
                gap: '3px',
                cursor: 'pointer',
              }}>
                <input type="checkbox" style={{ marginRight: '5px' }} />
                <span>{permission}</span>
              </label>
            ))}
          </div>
        </div>
      ))}


            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '25px'
            }}>
              <button
                onClick={() => setUserModalOpen(false)}
                style={{
                  padding: '10px 20px',
                  backgroundColor: '#f44336',
                  color: 'white',
                  border: 'none',
                  borderRadius: '8px',
                  cursor: 'pointer',
                  flex: 1,
                  marginRight: '10px'
                }}
              >
                Cancel
              </button>
              <button
                onClick={handleUpdateUser }
                style={{
                  padding: '10px 20px',
                  backgroundColor: '#4CAF50',
                  color: 'white',
                  border: 'none',
                  borderRadius: '8px',
                  cursor: 'pointer',
                  flex: 1,
                  marginRight: '10px'
                }}
              >
                Update
              </button>
              <button
                style={{
                  padding: '10px 20px',
                  backgroundColor: '#1E90FF',
                  color: 'white',
                  border: 'none',
                  borderRadius: '8px',
 cursor: 'pointer',
                  flex: 1
                }}
              >
                Send Reset Password
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Users;