// import React, { useState, useEffect } from 'react';
// import Datetime from "react-datetime";
// import "react-datetime/css/react-datetime.css";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faDownload, faSearch } from '@fortawesome/free-solid-svg-icons';
// import axios from 'axios';
// import { jsPDF } from 'jspdf';
// import 'jspdf-autotable';
// import BaseURL from '../assets/contants/BaseURL';
//  import Header from '../components/header';

// const Reports = () => {
//   const [fromDate, setFromDate] = useState(null);
//   const [toDate, setToDate] = useState(null);
//   // const [meters, setMeters] = useState([]);
//   const [selectedMeter, setSelectedMeter] = useState('');
//   const [reportData, setReportData] = useState([]);
//   const [downloadFormat, setDownloadFormat] = useState('pdf');
//   const [groups, setGroups] = useState([]);
//   const [selectedGroup, setSelectedGroup] = useState("");

//   useEffect(() => {
//     const fetchGroups = async () => {
//       try {
//         const response = await axios.get(`${BaseURL}meters/emsgroups/`);
//         if (response.status === 200) {
//           setGroups(response.data);
//         }
//       } catch (error) {
//         console.error("Error fetching meter groups:", error);
//       }
//     };

//     fetchGroups();
//   }, []);

//   const handleGroupSelect = (groupId) => {
//     if (selectedGroup === groupId) {
//       setSelectedGroup("");
//     } else {
//       setSelectedGroup(groupId);
//     }
//   };

//   const fetchReportData = async () => {
//     if (!fromDate || !toDate || !selectedMeter) {
//       alert('Please select a valid date range and meter.');
//       return;
//     }

//     const requestData = {
//       start_datetime: fromDate.toISOString().slice(0, 16),
//       end_datetime: toDate.toISOString().slice(0, 16),
//       meter_id: selectedMeter,
//     };

//     try {
//       const response = await axios.post(`${BaseURL}data/report/`, requestData);
//       if (response.status === 200) {
//         setReportData(response.data);
//       } else {
//         console.error('Failed to fetch report data:', response);
//       }
//     } catch (error) {
//       console.error('Error fetching report data:', error);
//     }
//   };

//   const downloadReport = () => {
//     if (!reportData || reportData.length === 0) {
//       alert('No data available to download.');
//       return;
//     }

//     const fromDateObj = new Date(fromDate);
//     const toDateObj = new Date(toDate);
  
//     if (downloadFormat === 'pdf') {
//       const doc = new jsPDF('landscape');
  
//       const meterId = reportData[0]?.meter_id;
//       const fromDateFormatted = !isNaN(fromDateObj) ? fromDateObj.toLocaleDateString() : 'N/A';
//       const toDateFormatted = !isNaN(toDateObj) ? toDateObj.toLocaleDateString() : 'N/A';
  
//       doc.setFontSize(20);
//       doc.text(`${meterId}`, doc.internal.pageSize.width / 2, 10, { align: 'center' });
  
//       if (fromDateFormatted && toDateFormatted) {
//         doc.text(
//           `From: ${fromDateFormatted} To: ${toDateFormatted}`,
//           doc.internal.pageSize.width / 2,
//           20,
//           { align: 'center' }
//         );
//       }
  
//       const headers = [
//         'Date', 'kWhR', 'kWhY', 'kWhB',
//         'VPHASE(RY)', 'VPHASE(YB)', 'VPHASE(BR)',
//         'VLINE(R)', 'VLINE(Y)', 'VLINE(B)',
//         'VHar(R)', 'VHar(Y)', 'VHar(B)',
//         'CuR', 'CuY', 'CuB',
//         'CuHar(R)', 'CuHar(Y)', 'CuHar(B)',
//         'PFR', 'PFY', 'PFB',
//       ];
  
//       const rows = reportData.map(data => [
//         data.date,
//         data.total_kWh_R, data.total_kWh_Y, data.total_kWh_B,
//         data.total_VPHASE_RY, data.total_VPHASE_YB,
//         data.total_VPHASE_BR,
//         data.total_VLINE_R, data.total_VLINE_Y, data.total_VLINE_B,
//         data.total_VHar_R, data.total_VHar_Y, data.total_VHar_B,
//         data.total_Cu_R, data.total_Cu_Y, data.total_Cu_B,
//         data.total_CuHar_R, data.total_CuHar_Y, data.total_CuHar_B,
//         data.total_PF_R, data.total_PF_Y, data.total_PF_B,
//       ]);
  
//       doc.autoTable({
//         head: [headers],
//         body: rows,
//         startY: 30,
//         margin: { top: 10, left: 10, right: 10 },
//         pageBreak: 'auto',
//         theme: 'striped',
//         headStyles: {
//           fontSize: 6,
//           fillColor: [0, 102, 204],
//           textColor: 255,
//           halign: 'center',
//         },
//         bodyStyles: {
//           fontSize: 6,
//           textColor: 0,
//           halign: 'center',
//         },
//       });
  
//       doc.save('report.pdf');
//     } else if (downloadFormat === 'csv') {
//       const headers = [
//         'Date', 'kWhR', 'kWhY', 'kWhB',
//         'VPHASE(RY)', 'VPHASE(YB)', 'VPHASE(BR)',
//         'VLINE(R)', 'VLINE(Y)', 'VLINE(B)',
//         'VHar(R)', 'VHar(Y)', 'VHar(B)',
//         'CuR', 'CuY', 'CuB',
//         'CuHar(R)', 'CuHar(Y)', 'CuHar(B)',
//         'PFR', 'PFY', 'PFB',
//       ];
  
//       const rows = reportData.map(data => [
//         data.date,
//         data.total_kWh_R, data.total_kWh_Y, data.total_kWh_B,
//         data.total_VPHASE_RY, data.total_VPHASE_YB,
//         data.total_VPHASE_BR,
//         data.total_VLINE_R, data.total_VLINE_Y, data.total_VLINE_B,
//         data.total_VHar_R, data.total_VHar_Y, data.total_VHar_B,
//         data.total_Cu_R, data.total_Cu_Y, data.total_Cu_B,
//         data.total_CuHar_R, data.total_CuHar_Y, data.total_CuHar_B,
//         data.total_PF_R, data.total_PF_Y, data.total_PF_B,
//       ]);
  
//       const meterId = reportData[0]?.meter_id;
//       const fromDateFormatted = !isNaN(fromDateObj) ? fromDateObj.toLocaleDateString() : 'N/A';
//       const toDateFormatted = !isNaN(toDateObj) ? toDateObj.toLocaleDateString() : 'N/A';
  
//       const metadata = `Meter ID: ${meterId}\nFrom Date: ${fromDateFormatted}  To Date: ${toDateFormatted}\n\n`;
  
//       const csvWithHeader =
//         metadata + headers.join(',') + '\n' + rows.map(row => row.join(',')).join('\n');
  
//       const blob = new Blob([csvWithHeader], { type: 'text/csv' });
//       const url = window.URL.createObjectURL(blob);
  
//       const link = document.createElement('a');
//       link.href = url;
//       link.download = 'report.csv';
//       link.click();
  
//       window.URL.revokeObjectURL(url);
//     }
//   };

//   return (
    // <div style={{ textAlign: 'center', width: '100%' }}>
    //   <div
    //     style={{
    //       backgroundColor: '#007acc',
    //       color: 'white',
    //       alignContent: 'center',
    //       fontSize: '14px',
    //       height: '40px',
    //     }}
    //   >
    //     <Header title="REPORTS" />
    //   </div>

//       <div
//         style={{
//           border: '1px solid #ccc',
//           padding: '10px',
//           marginTop: '20px',
//           boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
//           backgroundColor: 'white',
//           maxWidth: '1000px',
//           marginLeft: 'auto',
//           marginRight: 'auto',
//           borderRadius: '8px',
//         }}
//       >
//         <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
//           <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
//             <label style={{ fontWeight: "bold" }}>From Date:</label>
//             <Datetime
//               selected={fromDate}
//               onChange={(date) => setFromDate(date)}
//               inputProps={{
//                 placeholder: "Select From Date Time",
//                 style: { borderRadius: "10px", border: "1px solid #ccc", padding: "10px", width: "80%" }
//               }}
//             />

//             <label style={{ fontWeight: "bold" }}>To Date:</label>
//             <Datetime
//               selected={toDate}
//               onChange={(date) => setToDate(date)}
//               inputProps={{
//                 placeholder: "Select To Date Time",
//                 style: { borderRadius: "10px", border: "1px solid #ccc", padding: "10px", width: "80%" }
//               }}
//             />
//           </div>

//           <div
//         style={{
//           position: 'relative',
//           display: 'inline-block',
//           width: '150px',
//         }}
//       >
//         <select
//           style={{
//             padding: "10px 15px", 
//             fontSize: "14px", 
//             width: "100%", 
//             height: "38px", 
//             marginBottom: "15px", 
//             marginTop: "15px",
//             borderRadius: "8px", 
//             border: "1px solid #ccc", 
//             backgroundColor: "#f8f8f8", 
//             boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)", 
//             appearance: "none",
//             cursor: "pointer",
//           }}
//           value={selectedGroup}
//           onChange={(e) => handleGroupSelect(e.target.value)}
//         >
//           <option value="">Select Group</option>
//           {groups.map((group) => (
//             <option key={group.group_id} value={group.group_id}>
//               {group.group_name}
//             </option>
//           ))}
//         </select>

//         {selectedGroup && (
//           <div
//             style={{
//               position: 'absolute',
//               top: '35px',
//               left: '0',
//               width: '100%',
//               zIndex: '10',
//               marginTop: "25px",
//               display: 'block',
//             }}
//           >
//             <select
//               style={{
//                 padding: "5px", fontSize: "14px", width: "100%", height: "30px", marginBottom: "10px"
//               }}
//               value={selectedMeter}
//               onChange={(e) => setSelectedMeter(e.target.value)}
//             >
//               {groups
//                 .find((group) => group.group_id.toString() === selectedGroup)
//                 ?.meter.map((meter) => (
//                   <option key={meter.id} value={meter.meter_id}>
//                     {meter.meter_name}
//                   </option>
//                 ))}
//             </select>
//           </div>
//         )}
//       </div>
//           <button
//             style={{
//               padding: '8px 12px',
//               backgroundColor: '#007acc',
//               color: 'white',
//               border: 'none',
//               cursor: 'pointer',
//               borderRadius: '8px',
//             }}
//             onClick={fetchReportData}
//           >
//             <FontAwesomeIcon icon={faSearch} />
//           </button>

//           <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
//             <button
//               style={{
//                 display: 'flex',
//                 alignItems: 'center',
//                 padding: '8px 12px',
//                 backgroundColor: '#007acc',
//                 color: 'white',
//                 border: 'none',
//                 cursor: 'pointer',
//                 borderRadius: '8px',
//               }}
//               onClick={downloadReport}
//             >
//               <span>Download</span>
//               <FontAwesomeIcon icon={faDownload} style={{ marginLeft: '5px' }} />
//             </button>

//             <select
//               style={{ padding: '5px', fontSize: '16px', borderRadius: '8px' }}
//               value={downloadFormat}
//               onChange={(e) => setDownloadFormat(e.target.value)}
//             >
//               <option value="pdf">PDF</option>
//               <option value="csv">CSV</option>
//             </select>
//           </div>
//         </div>
//       </div>

//       <div
//         style={{
//           marginTop: '20px',
//           padding: '10px',
//           boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
//           backgroundColor: 'white',
//           maxWidth: '1000px',
//           margin: '20px auto',
//           borderRadius: '8px',
//         }}
//       >
//         <table style={{ width: '100%', textAlign: 'left', borderCollapse: 'collapse' }}>
//           <thead style={{ backgroundColor: '#007acc', color: 'white' }}>
//             <tr>
//               <th style={{ padding: '10px' }}>Date</th>
//               <th style={{ padding: '10px' }}>Meter ID</th>
//               <th style={{ padding: '10px' }}>Meter Name</th>
//               <th style={{ padding: '10px' }}>First kWh</th>
//               <th style={{ padding: '10px' }}>Last kWh</th>
//               <th style={{ padding: '10px' }}>Total kWh</th>
//               <th style={{ padding: '10px' }}>Unit Rate</th>
//             </tr>
//           </thead>
//           <tbody>
//             {reportData.map((data, index) => (
//               <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white' }}>
//                 <td style={{ padding: '10px' }}>{data.date}</td>
//                 <td style={{ padding: '10px' }}>{data.meter_id}</td>
//                 <td style={{ padding: '10px' }}>{data.meter_name}</td>
//                 <td style={{ padding: '10px' }}>{data.first_kwhr}</td>
//                 <td style={{ padding: '10px' }}>{data.last_kwhr}</td>
//                 <td style={{ padding: '10px' }}>{data.total_kwh}</td>
//                 <td style={{ padding: '10px' }}>{data.unit_rate}</td>
//               </tr>
//             ))}
//             {reportData.length === 0 && (
//               <tr>
//                 <td colSpan="7" style={{ textAlign: 'center', padding: '10px' }}>
//                   No data available
//                 </td>
//               </tr>
//             )}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default Reports;


import React, { useState, useEffect } from "react";
import "../scss/ReportCard.scss";
import 'chartjs-adapter-moment';
import 'chart.js/auto';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import ExcelJS from 'exceljs';
import Header from '../components/header';
import BaseURL from '../assets/contants/BaseURL';
import axios from 'axios';

const ReportCard = () => {
  const [selectedMeter, setSelectedMeter] = useState("");
  const [selecteddailyMeter, setSelecteddailyMeter] = useState("");
  const [selectedlogMeter, setSelectedlogMeter] = useState("");
  const [selectedhistMeter, setSelectedhistMeter] = useState("");
  const [meters, setMeters] = useState([]);
  const [activeTab, setActiveTab] = useState("Hourly Report");
  const [selectedreportDate, setSelectedreportDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [hourlyData, setHourlyData] = useState([]);
  const [hourlyTotalKwh, setHourlyTotalKwh] = useState(0);
  const [hourlyTotalCost, setHourlyTotalCost] = useState(0);
  const [dailyreportData, setDailyreportData] = useState([]);
  const [dailyTotalKwh, setDailyTotalKwh] = useState(0);
  const [dailyTotalCost, setDailyTotalCost] = useState(0);
  const [logData, setLogData] = useState([]);
  const [logTotalKwh, setLogTotalKwh] = useState(0);
  const [historicalTrend, setHistoricalTrend] = useState("Voltage");
  const [chartssData, setChartssData] = useState([]);
  const [currentreportPage, setCurrentreportPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [currenthourlyPage, setCurrenthourlyPage] = useState(1);
  const [rowsPerhourlyPage] = useState(10);


  const tabs = ["Hourly Report", "Daily Report", "Log Report", "Historical Trend"];
  
  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await axios.get(`${BaseURL}meters/emsgroups/`);
        setMeters(response.data);
      } catch (error) {
        console.error("Error fetching EMS group data:", error);
      }
    };
  
    fetchGroups();
  }, []);

  const handleHistoricalTrendChange = (event) => {
      setHistoricalTrend(event.target.value);
      setChartssData([]);
  };

  const handleSubmit = async () => {
      if (historicalTrend && selectedreportDate) {
        try {
          const response = await axios.post(`${BaseURL}/data/historical-trend/`, {
            meter_id: selectedhistMeter,
            date: selectedreportDate,
            parameter: historicalTrend.toLowerCase(),
          });
  
          const { time_intervals, ...parameterValues } = response.data;
          const formattedData = time_intervals.map((time, index) => {
            const entry = { time };
            for (const [key, values] of Object.entries(parameterValues)) {
              entry[key] = values[index];
            }
            return entry;
          });
  
          setChartssData(formattedData);
        } catch (error) {
          console.error("Error fetching historical trend data:", error);
        }
      }
    };

  const handleGetHourlyReport = async () => {
      if (!selectedreportDate) {
        alert("Please select a date.");
        return;
      }
  
      try {
        const response = await axios.post(`${BaseURL}/data/hourly-report/`, {
          meter_id : selectedMeter,
          date: selectedreportDate,
        });
  
        const {selected_day, columns, time_intervals, total_kwh_all_hours, total_cost_all_hours } = response.data;

  const startKwhData = columns.find(column => column.name === "start kwh").data;
  const endKwhData = columns.find(column => column.name === "end kwh").data;
  const unitRateData = columns.find(column => column.name === "unit rate").data;

  const hourlyData = time_intervals.map((interval, index) => ({
    date : selected_day,
    timeInterval: interval,
    startKwh: startKwhData[index],
    endKwh: endKwhData[index],
    unitRate: unitRateData[index],
    totalKwh: endKwhData[index] - startKwhData[index],
    totalCost: (endKwhData[index] - startKwhData[index]) * unitRateData[index],
  }));

  console.log("Processed Hourly Data:", hourlyData);

  setHourlyData(hourlyData);
  setHourlyTotalKwh(total_kwh_all_hours);
  setHourlyTotalCost(total_cost_all_hours);
  setCurrenthourlyPage(1);

} catch (error) {
  console.error("Error fetching hourly report:", error);
  alert("Failed to fetch hourly report. Please try again.");
}
};

  const totalhourlyPage = Math.ceil(hourlyData.length / rowsPerhourlyPage);
  const indexOfhourlyLastRow = currenthourlyPage * rowsPerhourlyPage;
  const indexOfhourlyFirstRow = indexOfhourlyLastRow - rowsPerhourlyPage;
  const currenthourlyRows = hourlyData.slice(indexOfhourlyFirstRow, indexOfhourlyLastRow);

  const handlehourlyPageChange = (page) => {
      setCurrenthourlyPage(page);
  };

  const handleGetDailyReport = async () => {
      try {
      const response = await axios.post(`${BaseURL}/data/daily-report/`, {
          meter_id: selecteddailyMeter,
          start_date: fromDate,
          end_date: toDate,
      });

      const data = response.data;
          
          const formattedData = data.dates.map((date, index) => ({
              date,
              fromDate: date,
              toDate: date,
              startKwh: data.columns[0].data[index],
              endKwh: data.columns[1].data[index],
              totalKwh: data.columns[2].data[index],
              unitRate: data.columns[3].data[index],
              totalCost: data.columns[4].data[index]
          }));

          setDailyreportData(formattedData);
          setDailyTotalKwh(data.total_kwh_all_days);
          setDailyTotalCost(data.total_cost_all_days);
      } catch (error) {
          console.error("Error fetching daily report:", error);
      }
  };

  const handleGetLogReport = async () => {
      try {
          const response = await axios.post(`${BaseURL}/data/logdata-report/`, {
              meter_id: selectedlogMeter,
              date: selectedreportDate,
          });

          const data = response.data;
          const formattedData = data.time.map((time, index) => ({
              datetime: `${selectedreportDate} ${time}`,
              VR: data.vr_data[index],
              VY: data.vy_data[index],
              VB: data.vb_data[index],
              VRY: data.vr_data[index],
              VYB: data.vyb_data[index],
              VBR: data.vbr_data[index],
              IR: data.pf_avg_data[index],
              IY: data.pf_r_data[index],
              IB: data.pf_y_data[index], 
              KVA: data.kwh_r_data[index] / 1000,
              KW: data.kwh_y_data[index] / 1000,
              KVAR: data.kwh_b_data[index] / 1000,
              PF: data.pf_b_data[index],
              KWH: data.kwh_r_data[index] / 1000,
          }));

          setLogData(formattedData);
          setLogTotalKwh(data.total_kwh / 1000000000);
      } catch (error) {
          console.error("Error fetching log report:", error);
      }
  };

  const totalreportPage = Math.ceil(logData.length / rowsPerPage);
  const indexOfLastRow = currentreportPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = logData.slice(indexOfFirstRow, indexOfLastRow);

  const handlePageChange = (page) => {
      setCurrentreportPage(page);
  };

  const handleExportToExcel = (data) => {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Report');
  
      worksheet.getColumn(1).width = 30;
      worksheet.getColumn(2).width = 20; 
      worksheet.getColumn(3).width = 15; 
      worksheet.getColumn(4).width = 15; 
      worksheet.getColumn(5).width = 15;
      worksheet.getColumn(6).width = 15; 
      worksheet.getColumn(7).width = 15; 
  
      let dateRow;
  
  if (activeTab === "Log Report") {
      dateRow = worksheet.addRow([`Date and Time: ${selectedreportDate}`]);
      worksheet.mergeCells(dateRow.number, 1, dateRow.number, 15); 
  
      for (let i = 1; i <= 15; i++) { 
          dateRow.getCell(i).fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFD9EAD3' }, 
          };
      }
  } else if (activeTab === "Hourly Report") {
      
      dateRow = worksheet.addRow([`Date: ${selectedreportDate}`]);
      worksheet.mergeCells(dateRow.number, 1, dateRow.number, 7); 
  
      for (let i = 1; i <= 7; i++) { 
          dateRow.getCell(i).fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFD9EAD3' }, 
          };
      }
  } else if (activeTab === "Daily Report") {
     
      dateRow = worksheet.addRow([`From Date: ${fromDate}, To Date: ${toDate}`]);
      worksheet.mergeCells(dateRow.number, 1, dateRow.number, 7); 
  
      for (let i = 1; i <= 7; i++) { 
          dateRow.getCell(i).fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFD9EAD3' }, 
          };
      }
  }
  
  dateRow.font = { bold: true, size: 14 };
  dateRow.alignment = { horizontal: 'center', vertical: 'middle' }; 
  
  const branchRow = worksheet.addRow(['Branch: Coimbatore', ' ', ' ',' ','Place of Installation: Group Control']);
  branchRow.font = { bold: true, size: 12 };
  branchRow.getCell(1).alignment = { horizontal: 'left' }; 
  branchRow.getCell(3).alignment = { horizontal: 'center' }; 
  
  const branchCellRange = activeTab === "Log Report" ? 15 : 7; 
  for (let i = 1; i <= branchCellRange; i++) {
      branchRow.getCell(i).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFDDDDDD' }, 
      };
  }
  
  
  const titleRow = worksheet.addRow([`${activeTab}`]);
  titleRow.font = { bold: true, size: 16 };
  titleRow.alignment = { horizontal: 'center' };
  
  worksheet.mergeCells(titleRow.number, 1, titleRow.number, branchCellRange);
  
  for (let i = 1; i <= branchCellRange; i++) {
      titleRow.getCell(i).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FF1E90FF' }, 
      };
  }
  
      if (data.length > 0) {
          let headers;
          if (activeTab === "Hourly Report") {
              headers = ['Date', 'Hour', 'Start WH', 'End WH', 'Total WH', 'Unit Rate', 'Total Cost'];
          } else if (activeTab === "Daily Report") {
              headers = ['Date','From Date', 'To Date', 'Start WH', 'End WH', 'Total WH', 'Total Cost'];
          } else if (activeTab === "Log Report") {
              headers = ['Date Time', 'VR', 'VY', 'VB', 'VRY', 'VYB', 'VBR','IR', 'IY', 'IB', 'KVA', 'KW', 'KVAR', 'PF', 'KWH'];
          }
  
         
          const headerRow = worksheet.addRow(headers);
          headerRow.font = { bold: true };
          
          
          headerRow.eachCell((cell, colIndex) => {
              if (activeTab === "Log Report" && colIndex >= 1 && colIndex <= 15) {
                  cell.fill = {
                      type: 'pattern',
                      pattern: 'solid',
                      fgColor: { argb: 'FFFFA500' }, 
                  };
              } else if (colIndex >= 1 && colIndex <= 7) { 
                  cell.fill = {
                      type: 'pattern',
                      pattern: 'solid',
                      fgColor: { argb: 'FFFFA500' }, 
                  };
              }
          });
          
  
         
          data.forEach((row, rowIndex) => {
            const rowData = activeTab === "Hourly Report"
                ? [row.date, row.timeInterval, row.startKwh, row.endKwh, (row.endKwh - row.startKwh).toFixed(2), row.unitRate.toFixed(2), ((row.endKwh - row.startKwh) * row.unitRate).toFixed(2)]
                : activeTab === "Daily Report"
                ? [row.date,row.fromDate, row.toDate, row.startKwh, row.endKwh, (row.endKwh - row.startKwh).toFixed(2), ((row.endKwh - row.startKwh) * row.unitRate).toFixed(2)]
                : [row.datetime, row.VR, row.VY, row.VB, row.VRY, row.VYB, row.VBR, row.IR, row.IY, row.IB, row.KVA, row.KW, row.KVAR, row.PF, row.KWH];
        
            const dataRow = worksheet.addRow(rowData);
        
            dataRow.eachCell((cell, colIndex) => {
                let fillColor;
                if (activeTab === "Log Report" && colIndex >= 1 && colIndex <= 15) {
                    fillColor = rowIndex % 2 === 0 ? 'FFE8EAF6' : 'FFFFFFFF'; 
                } else if (colIndex >= 1 && colIndex <= 7) {
                    fillColor = rowIndex % 2 === 0 ? 'FFE8EAF6' : 'FFFFFFFF'; 
                }
                
                if (fillColor) {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: fillColor }, 
                    };
                }
            });
        });
        
  
              const totals = activeTab === "Log Report" ? calculateLogTotals(data) : calculateTotals(data);
              const totalsRow = worksheet.addRow(
                  activeTab === "Log Report"
                      ? ['TOTAL', '', '', '', '', '', '', '', '', '', '', '', '', ' ', totals.totalKwh.toFixed(2)] 
                      : ['', '', 'TOTAL', '', totals.totalKwh.toFixed(2), '', totals.totalCost ? totals.totalCost.toFixed(2) : ''] 
              );
              
              totalsRow.font = { bold: true };
              totalsRow.eachCell((cell, colIndex) => {
                  if (activeTab === "Log Report") {
                    if (colIndex >= 1 && colIndex <= 15) { 
                          cell.fill = {
                              type: 'pattern',
                              pattern: 'solid',
                              fgColor: { argb: 'FFFFEB3B' },
                          };
                          cell.alignment = { horizontal: 'right' };
                          cell.border = {
                              top: { style: 'thin' },
                              left: { style: 'thin' },
                              bottom: { style: 'thin' },
                              right: { style: 'thin' },
                          };
                      }
                  } else {
                      if (colIndex >= 1 && colIndex <= 7) { 
                          cell.fill = {
                              type: 'pattern',
                              pattern: 'solid',
                              fgColor: { argb: 'FFFFEB3B' }, 
                          };
                          cell.alignment = { horizontal: 'right' };
                          cell.border = {
                              top: { style: 'thin' },
                              left: { style: 'thin' },
                              bottom: { style: 'thin' },
                              right: { style: 'thin' },
                          };
                      }
                  }
              });
              
            } else {
          worksheet.addRow(['No data available']).font = { italic: true };
      }

      const meterId = selectedMeter || selecteddailyMeter || selectedlogMeter || selectedhistMeter;
    const date = selectedreportDate || fromDate || toDate || new Date().toISOString().split('T')[0]; // Fallback to today's date if no date is selected
    const reportName = activeTab.replace(/\s+/g, '_'); // Replace spaces with underscores
    const fileName = `${reportName}_${meterId}_${date}.xlsx`;
  
      workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], { type: 'application/octet-stream' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          a.click();
          window.URL.revokeObjectURL(url);
      });
  };

  const calculateTotals = (data) => {
      const totalKwh = data.reduce((sum, row) => sum + (row.endKwh - row.startKwh), 0);
      const totalCost = data.reduce(
      (sum, row) => sum + (row.endKwh - row.startKwh) * row.unitRate,
      0
      );
      return { totalKwh, totalCost };
  };
  
  const calculateLogTotals = (data) => {
      const totalKwh = data.reduce((sum, row) => sum + row.KWH, 0);
      return { totalKwh };
  };

  let colorIndex = 0;

  const getRandomColor = () => {
      const colors = ["red", "blue", "green", "yellow"];
      const color = colors[colorIndex];
      colorIndex = (colorIndex + 1) % colors.length;
      return color;
  };

  const options = {
      title: {
          text: `${historicalTrend}`,
      },
      rangeSelector: {
          enabled: false
      },
      xAxis: {
          type: "datetime",
          categories: chartssData.map((item) => item.time),
      },
      yAxis: {
          title: {
              text: historicalTrend,
          },
      },
      series: Object.keys(chartssData[0] || {})
          .filter((key) => key !== "time")
          .map((key) => ({
              name: `${key}_${historicalTrend}`,
              data: chartssData.map((item) => item[key]),
              tooltip: {
                  valueSuffix: ` ${historicalTrend}`,
              },
              color: getRandomColor(),
          })),
  };


return (
  <div style={{ textAlign: 'center', width: '100%' }}>
    <div
      style={{
        backgroundColor: '#007acc',
        color: 'white',
        alignContent: 'center',
        fontSize: '14px',
        height: '40px',
      }}
    >
      <Header title="REPORTS" />
    </div>
  <div className="dashboard">
    <div className="main-container">
      {/* Navbar */}
      <div className="nav-bar">
        {tabs.map((tab) => (
          <button
            key={tab}
            className={activeTab === tab ? "active" : ""}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </button>
        ))}
      </div>

      {/* Hourly Report Content */}
      {activeTab === "Hourly Report" && (
        <div className="hourly-report">
        <div className="card controls-card">
            <select
                className="select-dropdown"
                value={selectedMeter}
                onChange={(e) => setSelectedMeter(e.target.value)}
            >
                <option value="">Select Meter</option>
                {meters.map((group) =>
                    group.meter.map((meter) => (
                        <option key={meter.id} value={meter.meter_id}>
                            {group.group_name} - {meter.meter_name}
                        </option>
                    ))
                )}
            </select>

            <input
                type="date"
                value={selectedreportDate}
                onChange={(e) => setSelectedreportDate(e.target.value)}
                placeholder="Select Date"
            />
            <button onClick={handleGetHourlyReport}>Get Report</button>
            <button onClick={() => handleExportToExcel(hourlyData)}>
                <i className="excel-icon">📊</i> Excel
            </button>
        </div>

        <div className="table-container">
                  <h4 className="table-title">
                      Hourly Log View on {selectedreportDate || "Selected Date"}
                  </h4>
                  <table>
                      <thead>
                      <tr>
                          <th>Date</th>
                          <th>Hour</th>
                          <th>Start WH</th>
                          <th>End WH</th>
                          <th>Total WH</th>
                          <th>Unit Rate</th>
                          <th>Total Cost</th>
                      </tr>
                      </thead>
                      <tbody>
                      {currenthourlyRows.length > 0 ? (
                          currenthourlyRows.map((row, index) => (
                              <tr key={index}>
                                  <td>{row.date}</td>
                                  <td>{row.timeInterval}</td>
                                  <td>{row.startKwh.toFixed(2)}</td>
                                  <td>{row.endKwh.toFixed(2)}</td>
                                  <td>{(row.endKwh - row.startKwh).toFixed(2)}</td>
                                  <td>{row.unitRate.toFixed(2)}</td>
                                  <td>{((row.endKwh - row.startKwh) * row.unitRate).toFixed(2)}</td>
                              </tr>
                          ))
                      ) : (
                          <tr>
                              <td colSpan="7" style={{ textAlign: "center" }}>
                                  No Data Available
                              </td>
                          </tr>
                      )}
                  </tbody>
                  <tfoot>
                      <tr>
                          <td colSpan="4" style={{ textAlign: "right" }}>TOTAL</td>
                          <td>{hourlyTotalKwh.toFixed(2)}</td>
                          <td></td>
                          <td>{hourlyTotalCost.toFixed(2)}</td>
                      </tr>
                  </tfoot>
              </table>
          </div>
          <div className="pagination-controls">
              <button
                  disabled={currenthourlyPage === 1}
                  onClick={() => handlehourlyPageChange(currenthourlyPage - 1)}
              >
                  Previous
              </button>
              <span>  {currenthourlyPage}   of   {totalhourlyPage}  </span>
              <button
                  disabled={currenthourlyPage === totalhourlyPage}
                  onClick={() => handlehourlyPageChange(currenthourlyPage + 1)}
              >
                  Next
              </button>
          </div>
              </div>
              )}

      {/* Daily Report Content */}
      {activeTab === "Daily Report" && (
        <div className="daily-report">
         <div className="card controls-card">
         <select
            className="select-dropdown"
            value={selecteddailyMeter}
            onChange={(e) => setSelecteddailyMeter(e.target.value)}
          >
            <option value="">Select Meter</option>
            {meters.map((group) =>
              group.meter.map((meter) => (
                <option key={meter.id} value={meter.meter_id}>
                  {group.group_name} - {meter.meter_name}
                </option>
              ))
            )}
          </select>


          <input
                      type="date"
                      value={fromDate}
                      onChange={(e) => setFromDate(e.target.value)}
                      placeholder="From Date"
                  />
                  <input
                      type="date"
                      value={toDate}
                      onChange={(e) => setToDate(e.target.value)}
                      placeholder="To Date"
                  />
            <button onClick={handleGetDailyReport}>Get Report</button>
            <button onClick={() => handleExportToExcel(dailyreportData)}>
              <i className="excel-icon">📊</i> Excel
            </button>
            {/* <button onClick={handlePrint}>
              <i className="print-icon">🖨️</i> Print
            </button> */}
          </div>

          <div className="table-container">
                  <h4 className="table-title"> 
                      Daily Log View from {fromDate} to {toDate}
                  </h4>
                  <table>
                      <thead>
                      <tr>
                          <th>Date</th>
                          <th>From Date</th>
                          <th>To Date</th>
                          <th>Start WH</th>
                          <th>End WH</th>
                          <th>Total WH</th>
                          {/* <th>Unit Rate</th> */}
                          <th>Total Cost</th>
                      </tr>
                      </thead>
                      <tbody>
                          {dailyreportData.length > 0 ? (
                              dailyreportData.map((row, index) => (
                                  <tr key={index}>
                                      <td>{row.date}</td>
                                      <td>{row.fromDate}</td>
                                      <td>{row.toDate}</td>
                                      <td>{row.startKwh.toFixed(2)}</td>
                                      <td>{row.endKwh.toFixed(2)}</td>
                                      <td>{(row.endKwh - row.startKwh).toFixed(2)}</td>
                                      {/* <td>{row.unitRate.toFixed(2)}</td> */}
                                      <td>
                                          {((row.endKwh - row.startKwh) * row.unitRate).toFixed(2)}
                                      </td>
                                  </tr>
                              ))
                          ) : (
                              <tr>
                                  <td colSpan="7" style={{ textAlign: "center" }}>
                                      No Data Available
                                  </td>
                              </tr>
                          )}
                      </tbody>
                      <tfoot>
                      <tr>
                          <td colSpan="4" style={{ textAlign: "right" }}>TOTAL</td>
                          <td>{dailyTotalKwh.toFixed(2)}</td>
                          <td></td>
                          <td>{dailyTotalCost.toFixed(2)}</td>
                      </tr>
                      </tfoot>
                  </table>
                  </div>
              </div>
              )}

      {/* Log Report Content */}
        {/* Log Report Content */}
        {activeTab === "Log Report" && (
        <div className="log-report">
        <div className="card controls-card">
        <select
          className="select-dropdown"
          value={selectedlogMeter}
          onChange={(e) => setSelectedlogMeter(e.target.value)}
        >
          <option value="">Select Meter</option>
          {meters.map((group) =>
            group.meter.map((meter) => (
              <option key={meter.id} value={meter.meter_id}>
                {group.group_name} - {meter.meter_name}
              </option>
            ))
          )}
        </select>


        <input
                      type="date"
                      value={selectedreportDate}
                      onChange={(e) => setSelectedreportDate(e.target.value)}
                      placeholder="Select Date"
                  />
            <button onClick={handleGetLogReport}>Get Report</button>
            <button onClick={() => handleExportToExcel(logData)}>
              <i className="excel-icon">📊</i> Excel
          </button>
                  
            {/* <button onClick={handlePrint}>
              <i className="print-icon">🖨️</i> Print
            </button> */}
          </div>

          <div className="table-container">
                  <h4 className="table-title">
                      Meter Log View on {selectedreportDate || "Selected Date"}
                  </h4>
                  <div className="table-wrapper">
                      <table>
                          <thead>
                              <tr>
                                  <th>DateTime</th>
                                  <th>VR</th>
                                  <th>VY</th>
                                  <th>VB</th>
                                  <th>VRY</th>
                                  <th>VYB</th>
                                  <th>VBR</th>
                                  <th>IR</th>
                                  <th>IY</th>
                                  <th>IB</th>
                                  <th>KVA</th>
                                  <th>KW</th>
                                  <th>KVAR</th>
                                  <th>PF</th>
                                  <th>KWH</th>
                              </tr>
                          </thead>
                          <tbody>
                              {currentRows.length > 0 ? (
                                  currentRows.map((row, index) => (
                                      <tr key={index}>
                                          <td>{row.datetime}</td>
                                          <td>{row.VR}</td>
                                          <td>{row.VY}</td>
                                          <td>{row.VB}</td>
                                          <td>{row.VRY}</td>
                                          <td>{row.VYB}</td>
                                          <td>{row.VBR}</td>
                                          <td>{row.IR}</td>
                                          <td>{row.IY}</td>
                                          <td>{row.IB}</td>
                                          <td>{row.KVA}</td>
                                          <td>{row.KW}</td>
                                          <td>{row.KVAR}</td>
                                          <td>{row.PF}</td>
                                          <td>{row.KWH}</td>
                                      </tr>
                                  ))
                              ) : (
                                  <tr>
                                      <td colSpan="15" style={{ textAlign: "center" }}>
                                          No Data Available
                                      </td>
                                  </tr>
                              )}
                          </tbody>
                          <tfoot>
                              <tr>
                                  <td colSpan="14" style={{ textAlign: "right" }}>TOTAL</td>
                                  <td>{logTotalKwh.toFixed(2)}</td>
                              </tr>
                          </tfoot>
                      </table>
                  </div>
              </div>

                  <div className="pagination-controls">
                      <button
                          disabled={currentreportPage === 1}
                          onClick={() => handlePageChange(currentreportPage - 1)}
                      >
                          Previous
                      </button>
                      <span>  {currentreportPage}   of   {totalreportPage}  </span>
                      <button
                          disabled={currentreportPage === totalreportPage}
                          onClick={() => handlePageChange(currentreportPage + 1)}
                      >
                          Next
                      </button>
                  </div>
              </div>
              )}

{activeTab === "Historical Trend" && (
<div className="historical-trend">
<div className="card controls-card">
<select
className="select-dropdown"
value={selectedhistMeter}
onChange={(e) => setSelectedhistMeter(e.target.value)}
>
<option value="">Select Meter</option>
{meters.map((group) =>
  group.meter.map((meter) => (
    <option key={meter.id} value={meter.meter_id}>
      {group.group_name} - {meter.meter_name}
    </option>
  ))
)}
</select>
<select
              value={historicalTrend}
              onChange={handleHistoricalTrendChange}
          >
              <option>Select Parameters</option>
              <option value="voltage_phase_to_phase">Voltage Phase to Phase</option>
              <option value="voltage_phase_to_neutral">Voltage Phase to Neutral</option>
              <option value="frequency">Frequency</option>
              <option value="current">Current</option>
              <option value="current_harmonics">Current Harmonics</option>
              <option value="voltage_harmonics">Voltage Harmonics</option>
              <option value="power_factor">PF</option>
              <option value="KWH">KWH</option>
              <option value="kvah">KVAH</option>
              <option value="kw">KW</option>
              <option value="va">VA</option>
              <option value="power">Power</option>
          </select>
          <input
              type="date"
              value={selectedreportDate}
              onChange={(e) => setSelectedreportDate(e.target.value)}
              placeholder="Select Date"
          />
          <button onClick={handleSubmit}>Submit</button>
          </div>

          <div className="trend-container">
          <h3 className="trend-title">{historicalTrend} Trend Report</h3>
          <HighchartsReact
              key={historicalTrend} 
              highcharts={Highcharts}
              constructorType={"stockChart"}
              options={options}
          />
          </div>
          </div>
      )}
      </div>
      </div>
              </div>
          );
      };

export default ReportCard;