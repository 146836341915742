import React,{useState} from 'react';
import Header from '../components/header';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

const Productionreport = () => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectedItem, setSelectedItem] = useState("all");

    return (
        <div style={{ textAlign: 'center', width: '100%' }}>
          <div style={{
           backgroundColor: '#007acc',
           color: 'white',
           alignContent: 'center',
           fontSize: '14px',
           height:'40px'
          }}>
           <Header title="PRODUCTION REPORTS" />
          </div>
          <div style={{
        border: '1px solid #ccc',
        padding: '10px',
        marginTop: '20px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
        backgroundColor: 'white',
        maxWidth: '1000px',
        marginLeft: 'auto',
        marginRight: 'auto',
        borderRadius: '8px'
      }}>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <label style={{ fontWeight: 'bold' }}>From Date:</label>
            <DatePicker
              selected={fromDate}
              onChange={(date) => setFromDate(date)}
              placeholderText="Select From Date"
              showPopperArrow={false}
              dateFormat="yyyy-MM-dd"
              style={{ padding: '5px', fontSize: '16px', borderRadius: '8px' }}
            />

            <label style={{ fontWeight: 'bold' }}>To Date:</label>
            <DatePicker
              selected={toDate}
              onChange={(date) => setToDate(date)}
              placeholderText="Select To Date"
              showPopperArrow={false}
              dateFormat="yyyy-MM-dd"
              style={{ padding: '5px', fontSize: '16px', borderRadius: '8px' }}
            />
          </div>

          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <select
              value={selectedItem}
              onChange={(e) => setSelectedItem(e.target.value)}
              style={{
                padding: '5px', // Increase padding for a larger clickable area
                fontSize: '14px', // Increase font size for better readability
                width: '150px', // Increase the width of the dropdown
                height: '30px', // Increase height of the dropdown
              }}
            >
              <option value="all">All units</option>
</select>
            </div>

          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <button style={{
              display: 'flex',
              alignItems: 'center',
              padding: '8px 12px',
              backgroundColor: '#007acc',
              color: 'white',
              border: 'none',
              cursor: 'pointer',
              borderRadius: '8px'
            }}>
              <span>Download</span>
              <FontAwesomeIcon icon={faDownload} style={{ marginLeft: '5px' }} />
            </button>

            <select style={{ padding: '5px', fontSize: '16px', borderRadius: '8px' }}>
              <option value="pdf">PDF</option>
              <option value="csv">CSV</option>
            </select>
          </div>
        </div>
        <div
  style={{
    marginTop: '20px',
    padding: '10px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    backgroundColor: 'white',
    maxWidth: '1000px',
    margin: '20px auto',
    borderRadius: '8px',
  }}
>
  <table style={{ width: '100%', textAlign: 'left', borderCollapse: 'collapse' }}>
    <thead>
      <tr style={{ backgroundColor: '#007acc', color: 'white' }}>
        <th style={{ padding: '10px' }}>S.No</th>
        <th style={{ padding: '10px' }}>Date</th>
        <th style={{ padding: '10px' }}>Time</th>
        <th colSpan="2" style={{ padding: '10px', borderRight: '2px solid #ccc', borderBottom: '2px solid #ccc',borderLeft:'2px solid #ccc',textAlign:'center' }}>Units</th>
        <th style={{ padding: '10px' }}>Total Units</th>
        <th style={{ padding: '10px' }}>Melted Tons</th>
        <th style={{ padding: '10px' }}>Poured Tons</th>
        <th style={{ padding: '10px' }}>Melted Units/Tons</th>
        <th style={{ padding: '10px' }}>Poured Units/Tons</th>
      </tr>
      <tr style={{ backgroundColor: '#007acc', color: 'white' }}>
        <th style={{ padding: '10px' }}></th>
        <th style={{ padding: '10px' }}></th>
        <th style={{ padding: '10px' }}></th>
        <th
          style={{
            padding: '10px',
            borderRight: '2px solid #ccc', // Line separating Furnace A and Furnace B
            borderLeft:'2px solid #ccc'
           
          }}
        >
          Furnace A
        </th>
        <th style={{ padding: '10px', borderRight: '2px solid #ccc', }}>Furnace B</th>
        <th style={{ padding: '10px' }}></th>
        <th style={{ padding: '10px' }}></th>
        <th style={{ padding: '10px' }}></th>
        <th style={{ padding: '10px' }}></th>
        <th style={{ padding: '10px' }}></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td style={{ padding: '10px' }}>1</td>
        <td style={{ padding: '10px' }}>2024-11-20</td>
        <td style={{ padding: '10px' }}>10:00 AM</td>
        <td style={{ padding: '10px', textAlign: 'center' }}>
          150
        </td>
        <td style={{ padding: '10px', textAlign: 'center' }}>
          130
        </td>
        <td style={{ padding: '10px'  }}>280</td>
        <td style={{ padding: '10px' }}>5</td>
        <td style={{ padding: '10px' }}>4</td>
        <td style={{ padding: '10px' }}>56</td>
        <td style={{ padding: '10px'}}>46</td>
      </tr>
      {/* Add more rows here as needed */}
    </tbody>
  </table>
</div>


          </div>
          </div>
);
};
export default Productionreport;
