import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BaseURL from '../assets/contants/BaseURL';
import '../scss/Resetform.scss';

const LoginForm = () => {
  const navigate = useNavigate();
  const [username, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const now = new Date();
  const loginTime = now.toLocaleString();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage(''); 

    const requestBody = {
      username,
      password,
    };

    try {
      const response = await fetch(`${BaseURL}Userauth/weblogin/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error('Login failed');
      }

      const data = await response.json();
      const token = data.token;
      localStorage.setItem('token', token);
      localStorage.setItem('username', username);
      localStorage.setItem('loginTime', loginTime);

      navigate('/EMS/dashboard');
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage('Invalid username or password. Please try again.'); 
    } finally {
      setLoading(false);
    }
  };


  const navigateToForgotPassword = () => {
    navigate('/forgot'); 
  };


  const containerStyle = {
    width: '500px',
    margin: '50px auto',
    padding: '30px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    boxShadow: '0 2px 15px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    backgroundColor: '#fff',
  };

  const headerStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#0073e6',
    marginBottom: '20px',
  };

  const inputStyle = {
    width: '90%',
    padding: '12px',
    margin: '10px auto',
    border: '1px solid #ccc',
    borderRadius: '4px',
    fontSize: '16px',
    outline: 'none',
  };

  const submitBtnStyle = {
    width: '50%',
    padding: '12px',
    backgroundColor: loading ? '#f0c14b' : '#f0c14b',
    color: '#111',
    border: '1px solid #a88734',
    borderRadius: '50px',
    fontSize: '18px',
    fontWeight: 'bold',
    cursor: loading ? 'not-allowed' : 'pointer',
    transition: 'background-color 0.3s',
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    
  };


  const spinnerStyle = {
    width: '20px',
    height: '20px',
    border: '3px solid #f3f3f3', 
    borderTop: '3px solid #3498db',
    borderRadius: '50%',
    animation: 'spin 1s linear infinite',
  
  };


  const switchScreenStyle = {
    marginTop: '20px',
    fontSize: '14px',
    color: '#555',
  };

  const forgetPasswordTextStyle = {
    color: '#0073e6', 
    cursor: 'pointer', 
    textDecoration: 'underline', 
  };

  // const backgroundStyle = {
  //   backgroundImage: 'url("https://images.unsplash.com/photo-1517336714731-489689fd1ca8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8MTY0MjY1Mzg4Ng&ixlib=rb-1.2.1&q=80&w=1080")',
  //   backgroundSize: 'cover',
  //   height: '100vh',
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  // };


  const errorMessageStyle = {
    backgroundColor: '#f8d7da',
    color: '#721c24', 
    border: '1px solid #f5c6cb', 
    padding: '10px',
    borderRadius: '4px',
    marginTop: '15px',
    fontSize: '14px',
    textAlign: 'center',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
  };

  return (
    
    <div className="live-background">
      <div style={containerStyle}>
        {/* <h2 style={headerStyle}>LOGIN</h2> */}
        
        <img src="/brad.png" alt="Logo" style={{ width: '150px', marginBottom: '10px' }} />
         <h2 style={headerStyle}>LOGIN</h2>

         {errorMessage && (
          <div style={errorMessageStyle}>
            {errorMessage}
          </div>
        )}


        <form onSubmit={handleLogin}>
        <div style={{ textAlign: 'left', marginBottom: '10px' }}>
            <label htmlFor="username" style={{ fontWeight: 'bold' }}>Username:</label>
            <input
              type="text"
              id="username"
              placeholder="Email or mobile phone number"
              style={inputStyle}
              value={username}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div style={{ textAlign: 'left', marginBottom: '20px' }}>
            <label htmlFor="password" style={{ fontWeight: 'bold' }}>Password:</label>
            <input
              type="password"
              id="password"
              placeholder="Password"
              style={inputStyle}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
         <button type="submit"style={(submitBtnStyle)} disabled={loading}>
         {loading ? <div style={spinnerStyle}></div> : 'Login'}
      </button>
        </form>

        <div style={switchScreenStyle}>
          <span onClick={navigateToForgotPassword} style={forgetPasswordTextStyle}>Forgot Password?</span>
        </div>
      </div>
    </div>
  );
}

export default LoginForm;
