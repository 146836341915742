import React, { useState, useEffect } from 'react';
import '../scss/Emsmain.scss';
import Chart from 'react-apexcharts';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; 
import { useNavigate } from 'react-router-dom';
import { MdFactory } from "react-icons/md";
import axios from 'axios';
import Header from '../components/header'
import BaseURL from '../assets/contants/BaseURL';
import ReactSpeedometer from "react-d3-speedometer";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import ExcelJS from 'exceljs';

const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];
const getStatusColor = (status) => {
    if (status === 'Inactive') return '#f61612'; 
    if (status === 'Active') return '#4ded4f'; 
    return 'yellow';
};
const Emsmain = () => {
    const navigate = useNavigate();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [dateselectedDate, setdateSelectedDate] = useState(new Date());
    const [timeselectedDate, settimeSelectedDate] = useState(new Date());
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedNewYear, setSelectedNewYear] = useState(new Date().getFullYear());
    const [energyData, setEnergyData] = useState({
        today: { kWh: 0, cost: 0, co2: 0.0 },
        yesterday: { kWh: 0, cost: 0, co2: 0.0 },
        current_month: { kWh: 0, cost: 0, co2: 0.0 },
        last_month: { kWh: 0, cost: 0, co2: 0.0 },
    });
    const [details, setDetails] = useState({});
    const [co2, setCo2] = useState(0);
    const [vpn, setVpn] = useState({});
    const [vpp, setVpp] = useState({});
    const [iavg, setIavg] = useState({});
    const [pfAvg, setPfAvg] = useState(0);
    const [pf, setPf] = useState(0);
    const [freq, setFreq] = useState(0);
    const [vthd, setVTHD] = useState({
        VTHD_Avg: 0,
        VHar_R: 0,
        VHar_Y: 0,
        VHar_B: 0,
    });
    const [ithd, setITHD] = useState({
        ITHD_Avg: 0,
        CuHar_R: 0,
        CuHar_Y: 0,
        CuHar_B: 0,
    });
    const [submachines, setSubmachines] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [kwGauge, setKwGauge] = useState([0, 5000]);
    const [KW, setKW] = useState({
        Total: 0,
        R: 0,
        Y: 0,
        B: 0,
    });
    const [kvaGauge, setKvaGauge] = useState([0, 5000]);
    const [KVA, setKVA] = useState({
        Total: 0,
        R: 0,
        Y: 0,
        B: 0,
    });
    const [chartData, setChartData] = useState({
        selected_day: "",
        graph_title: "",
        x_axis_label: "",
        y_axis_label: "",
        bars: [
            {
                name: "",
                data: [],
            },
            {
                name: "",
                data: [],
            },
            {
                name: "",
                data: [],
            },
        ],
        time_intervals: [
        ],
    });
    const [dailyData, setDailyData] = useState({
        categories: [],
        kw: [],
        cost: [],
        co2: []
    });
    const [lineChartOptions, setLineChartOptions] = useState({
        chart: {
          type: 'line',
          height: 350,
          toolbar: {
                    tools: {
                        zoom: false,
                        zoomin: false,
                        zoomout: false,
                        pan: false,
                        reset: false,
                        download: false,
                    },
                },
          zoom: {
            enabled: true,
          },
        },
        stroke: {
          curve: 'straight',
        },
        xaxis: {
          categories: [],
          labels: {
            show: false,
          },
        },
        yaxis: {
          tickAmount: 3,
          labels: {
            show: true,
          },
        },
        grid: {
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        annotations: {
            yaxis: [
              {
                y: 0,
                borderColor: '#000000',
                label: {
                  style: {
                    color: '#000000',
                    background: '#fff',
                  },
                },
              },
            ],
          },
        series: [
          { name: 'P_R', data: [] },
          { name: 'P_Y', data: [] },
          { name: 'P_B', data: [] },
          { name: 'P_Avg', data: [] },
        ],
      });

      const [monthlyData, setMonthlyData] = useState({
        categories: [],
        kWh: [],
        cost: [],
        co2: [],
    });

    const [chartsData, setChartsData] = useState({
        options: {
            chart: {
                id: "timeslot-chart",
                toolbar: {
                    tools: {
                        zoom: false,
                        zoomin: false,
                        zoomout: false,
                        pan: false,
                        reset: false,
                        download: false,
                    },
                },
                zoom: {
                    enabled: false,
                },
            },
            xaxis: {
                categories: [],
                tooltip: {
                    enabled: true,
                },
            },
            yaxis: {
                title: {
                    text: 'Value',
                },
                labels: {
                    formatter: (value) => {
                        return new Intl.NumberFormat('en-US').format(value);
                    },
                },
            },
            dataLabels: {
                enabled: false,
            },
            legend: {
                position: 'top',
                horizontalAlign: 'center',
                offsetX: 0,
                offsetY: 0,
            },
            tooltip: {
                y: {
                    formatter: function (value, { seriesIndex }) {
                        if (seriesIndex === 1) {
                            return `Rs ${value.toLocaleString()}`;
                        }
                        return value.toLocaleString();
                    },
                },
            },
        },
        series: [
            {
                name: "Today's kWh",
                data: [],
            },
            {
                name: "Total Cost",
                data: [],
            },
            {
                name: "CO₂",
                data: [],
            },
        ],
    });
    
    const [tableData, setTableData] = useState([]);
    const [totalKWhr, setTotalKWhr] = useState(0);
    const [totalCost, setTotalCost] = useState(0);
    const [totalCo2, setTotalCo2] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const indiaTime = new Date(timeselectedDate.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' }));
                const formattedDate = `${indiaTime.getFullYear()}-${(indiaTime.getMonth() + 1).toString().padStart(2, '0')}-${indiaTime.getDate().toString().padStart(2, '0')}`;
                const response = await axios.post(`${BaseURL}/data/peak-timing-consumption/`, {
                    meter_id: 'GT1_EMS1',
                    date: formattedDate
                });
    
                const data = response.data;

                setChartsData((prevState) => ({
                    ...prevState,
                    options: {
                        ...prevState.options,
                        xaxis: {
                            categories: data.time_intervals,
                        },
                    },
                    series: [
                        { name: "Today's kWh", data: data.bars[0].data },
                        { name: "Total Cost", data: data.bars[1].data },
                        { name: "CO₂", data: data.bars[2].data },
                    ],
                }));

                const updatedTableData = data.time_intervals.map((timeSlot, index) => ({
                    Timings: timeSlot,
                    Rate: data.rates[index],
                    TodaysKWhr: data.bars[0].data[index],
                    TotalCost: data.bars[1].data[index],
                    TotalCo2: data.bars[2].data[index],
                }));
    
                setTableData(updatedTableData);
                setTotalKWhr(updatedTableData.reduce((acc, row) => acc + row.TodaysKWhr, 0));
                setTotalCost(updatedTableData.reduce((acc, row) => acc + row.TotalCost, 0));
                setTotalCo2(updatedTableData.reduce((acc, row) => acc + row.TotalCo2, 0));
    
            } catch (error) {
                console.error('Error fetching data', error);
            }
        };
        fetchData();
        const intervalId = setInterval(() => {
            fetchData();
        },  180000);
        return () => clearInterval(intervalId);
    }, [timeselectedDate]);

    useEffect(() => {
        const fetchEnergyData = async () => {
            try {
                const today = new Date();
                const indiaTime = new Date(today.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' }));
                const formattedDate = `${indiaTime.getFullYear()}-${(indiaTime.getMonth() + 1).toString().padStart(2, '0')}-${indiaTime.getDate().toString().padStart(2, '0')}`;
                const response = await axios.post(`${BaseURL}/data/energy-data/`, {
                    meter_id: 'GT1_EMS1',
                    date: formattedDate,
                });
                const data = response.data;
                setEnergyData({
                    today: {
                        kWh: new Intl.NumberFormat().format(parseFloat(data.today.kWh.toFixed(2))),
                        cost: new Intl.NumberFormat().format(parseFloat(data.today.cost.toFixed(2))),
                        co2: new Intl.NumberFormat().format(parseFloat(data.today.co2.toFixed(2))),
                    },
                    yesterday: {
                        kWh: new Intl.NumberFormat().format(parseFloat(data.yesterday.kWh.toFixed(2))),
                        cost: new Intl.NumberFormat().format(parseFloat(data.yesterday.cost.toFixed(2))),
                        co2: new Intl.NumberFormat().format(parseFloat(data.yesterday.co2.toFixed(2))),
                    },
                    current_month: {
                        kWh: new Intl.NumberFormat().format(parseFloat(data.current_month.kWh.toFixed(2))),
                        cost: new Intl.NumberFormat().format(parseFloat(data.current_month.cost.toFixed(2))),
                        co2: new Intl.NumberFormat().format(parseFloat(data.current_month.co2.toFixed(2))),
                    },
                    last_month: {
                        kWh: new Intl.NumberFormat().format(parseFloat(data.last_month.kWh.toFixed(2))),
                        cost: new Intl.NumberFormat().format(parseFloat(data.last_month.cost.toFixed(2))),
                        co2: new Intl.NumberFormat().format(parseFloat(data.last_month.co2.toFixed(2))),
                    },
                });
            } catch (error) {
                console.error('Error fetching energy data:', error);
            }
        };

        fetchEnergyData();
        const intervalId = setInterval(() => {
            fetchEnergyData();
        },  180000);
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const today = new Date();
                const indiaTime = new Date(today.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' }));
                const formattedDate = `${indiaTime.getFullYear()}-${(indiaTime.getMonth() + 1).toString().padStart(2, '0')}-${indiaTime.getDate().toString().padStart(2, '0')}`;
                const response = await axios.post(`${BaseURL}/data/meter-data/`, {
                    meter_id: "GT1_EMS1",
                    date: formattedDate,
                });
                const data = response.data;
                setDetails(data.details);
                setCo2(parseFloat((data.co2 || 0).toFixed(2)));
                setVpn({
                    VLN_Avg: parseFloat((data.vpn?.VLN_Avg || 0).toFixed(2)),
                    V_R: parseFloat((data.vpn?.V_R || 0).toFixed(2)),
                    V_Y: parseFloat((data.vpn?.V_Y || 0).toFixed(2)),
                    V_B: parseFloat((data.vpn?.V_B || 0).toFixed(2)),
                });
                setVpp({
                    VLL_Avg: parseFloat((data.vpp?.VLL_Avg || 0).toFixed(2)),
                    V_RY: parseFloat((data.vpp?.V_RY || 0).toFixed(2)),
                    V_YB: parseFloat((data.vpp?.V_YB || 0).toFixed(2)),
                    V_BR: parseFloat((data.vpp?.V_BR || 0).toFixed(2)),
                });
                setIavg({
                    Cu_Avg: parseFloat((data.iavg?.Cu_Avg || 0).toFixed(2)),
                    Cu_R: parseFloat((data.iavg?.Cu_R || 0).toFixed(2)),
                    Cu_Y: parseFloat((data.iavg?.Cu_Y || 0).toFixed(2)),
                    Cu_B: parseFloat((data.iavg?.Cu_B || 0).toFixed(2)),
                });
                setPfAvg(data.pf_avg);
                setPf(data.PF);
                setFreq(data.freq);
                setVTHD({
                    VHar_R: parseFloat((data.VTHD?.VHar_R || 0).toFixed(2)),
                    VHar_Y: parseFloat((data.VTHD?.VHar_Y || 0).toFixed(2)),
                    VHar_B: parseFloat((data.VTHD?.VHar_B || 0).toFixed(2)),
                    VTHD_Avg: parseFloat((data.VTHD?.VTHD_Avg || 0).toFixed(2)),
                });
    
                setITHD({
                    CuHar_R: parseFloat((data.ITHD?.CuHar_R || 0).toFixed(2)),
                    CuHar_Y: parseFloat((data.ITHD?.CuHar_Y || 0).toFixed(2)),
                    CuHar_B: parseFloat((data.ITHD?.CuHar_B || 0).toFixed(2)),
                    ITHD_Avg: parseFloat((data.ITHD?.ITHD_Avg || 0).toFixed(2)),
                });
                setKwGauge(data.details.kw_gauge || [0, 5000]);
                setKW(data.KW || { Total: 0, R: 0, Y: 0, B: 0 });
                setKvaGauge(data.details.kva_gauge || [0, 5000]);
                setKVA(data.KVA || { Total: 0, R: 0, Y: 0, B: 0 });
            } catch (error) {
                console.error('Error fetching meter data:', error);
            }
        };
        fetchData();
        const intervalId = setInterval(() => {
            fetchData();
        },  120000);
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        const fetchPFData = async () => {
          try {
            const indiaTime = new Date(selectedDate.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' }));
            const formattedDate = `${indiaTime.getFullYear()}-${(indiaTime.getMonth() + 1).toString().padStart(2, '0')}-${indiaTime.getDate().toString().padStart(2, '0')}`;
            const response = await axios.post(`${BaseURL}/data/powerfactor-with-timings/`, {
                date: formattedDate,
                meter_id: "GT1_EMS1",
              });
            const data = response.data;

            const timeLabels = data.map(item => item[0]);
            const pRData = data.map(item => item[1]);
            const pYData = data.map(item => item[2]);
            const pBData = data.map(item => item[3]); 
            const pAvgData = data.map(item => item[4]);

            setLineChartOptions(prevState => ({
                ...prevState,
                xaxis: {
                  categories: timeLabels,
                },
                series: [
                  { name: 'P_R', data: pRData },
                  { name: 'P_Y', data: pYData },
                  { name: 'P_B', data: pBData },
                  { name: 'P_Avg', data: pAvgData },
                ],
              }));
          } catch (error) {
            console.error("Error fetching power factor data:", error);
          }
        };
    
        fetchPFData();
        const intervalId = setInterval(() => {
            fetchPFData();
        },  180000);
        return () => clearInterval(intervalId);
      }, [selectedDate]);

      useEffect(() => {
        const fetchChartData = async () => {
            try {
                const indiaTime = new Date(dateselectedDate.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' }));
                const formattedDate = `${indiaTime.getFullYear()}-${(indiaTime.getMonth() + 1).toString().padStart(2, '0')}-${indiaTime.getDate().toString().padStart(2, '0')}`;
                const response = await axios.post(`${BaseURL}data/hourly-consumption/`, {
                    meter_id: "GT1_EMS1",
                    date: formattedDate
                });
    
                const data = response.data;

                setChartData({
                    selected_day: data.selected_day,
                    graph_title: data.graph_title,
                    x_axis_label: data.x_axis_label,
                    y_axis_label: data.y_axis_label,
                    bars: [
                        { name: "kWh", data: data.bars[0].data },
                        { name: "cost", data: data.bars[1].data },
                        { name: "co2", data: data.bars[2].data }
                    ],
                    time_intervals: data.time_intervals
                });
            } catch (error) {
                console.error("Error fetching chart data", error);
            }
        };

        fetchChartData();
        const intervalId = setInterval(() => {
            fetchChartData();
        },  120000);
        return () => clearInterval(intervalId);
    }, [dateselectedDate]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post(`${BaseURL}/data/daily-consumption/`, {
                    meter_id: "GT1_EMS1",
                    month: selectedMonth,
                    year: selectedYear
                });

                const { bars, time_intervals } = response.data;
                const kw = bars.find(bar => bar.name === 'kw').data;
                const cost = bars.find(bar => bar.name === 'cost').data;
                const co2 = bars.find(bar => bar.name === 'co2').data;

                setDailyData({
                    categories: time_intervals,
                    kw: kw,
                    cost: cost,
                    co2: co2
                });
            } catch (error) {
                console.error("Error fetching daily consumption data:", error);
            }
        };

        fetchData();
        const intervalId = setInterval(fetchData, 180000);
        return () => clearInterval(intervalId);
    }, [selectedMonth, selectedYear]);

    const handleMonthChange = (e) => setSelectedMonth(parseInt(e.target.value));
    const handleYearChange = (e) => setSelectedYear(parseInt(e.target.value));
    const handleNewYearChange = (e) => setSelectedNewYear(parseInt(e.target.value));


    useEffect(() => {
        const fetchData = async () => {
            axios.post(`${BaseURL}/data/monthly-consumption/`, { meter_id: "GT1_EMS1", year: selectedNewYear })
                .then((response) => {
                    const data = response.data;
                    setMonthlyData({
                        categories: data.time_intervals,
                        kWh: data.bars.find(bar => bar.name === 'kw').data,
                        cost: data.bars.find(bar => bar.name === 'cost').data,
                        co2: data.bars.find(bar => bar.name === 'co2').data,
                    });
                })
                .catch((error) => {
                    console.error('Error fetching monthly consumption data:', error);
                });
        }
        fetchData();
        const intervalId = setInterval(() => {
            fetchData();
        },  180000);
        return () => clearInterval(intervalId);
    }, [selectedNewYear]);

    useEffect(() => {
        const fetchGroups = async () => {
            try {
                const response = await axios.get(`${BaseURL}/data/ems-groups/`);
                setSubmachines(response.data);
            } catch (error) {
                console.error('Error fetching group data:', error);
            }
        };
        fetchGroups();
        const intervalId = setInterval(() => {
            fetchGroups();
        },  180000);
        return () => clearInterval(intervalId);
    }, []);

    const [activeTab, setActiveTab] = useState("Hourly Report");
    const [selectedreportDate, setSelectedreportDate] = useState("");
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [hourlyData, setHourlyData] = useState([]);
    const [hourlyTotalKwh, setHourlyTotalKwh] = useState(0);
    const [hourlyTotalCost, setHourlyTotalCost] = useState(0);
    const [dailyreportData, setDailyreportData] = useState([]);
    const [dailyTotalKwh, setDailyTotalKwh] = useState(0);
    const [dailyTotalCost, setDailyTotalCost] = useState(0);
    const [logData, setLogData] = useState([]);
    const [logTotalKwh, setLogTotalKwh] = useState(0);
    const [historicalTrend, setHistoricalTrend] = useState("Voltage");
    const [chartssData, setChartssData] = useState([]);
    const [currentreportPage, setCurrentreportPage] = useState(1);
    const [rowsPerPage] = useState(10);
    const [currenthourlyPage, setCurrenthourlyPage] = useState(1);
    const [rowsPerhourlyPage] = useState(10);


    const tabs = ["Hourly Report", "Daily Report", "Log Report", "Historical Trend"];

    const handleHistoricalTrendChange = (event) => {
        setHistoricalTrend(event.target.value);
    };

        const handleSubmit = async () => {
            if (historicalTrend && selectedreportDate) {
              try {
                const response = await axios.post(`${BaseURL}/data/historical-trend/`, {
                  meter_id: "GT1_EMS1",
                  date: selectedreportDate,
                  parameter: historicalTrend.toLowerCase(),
                });
        
                const { time_intervals, ...parameterValues } = response.data;
                const formattedData = time_intervals.map((time, index) => {
                  const entry = { time };
                  for (const [key, values] of Object.entries(parameterValues)) {
                    entry[key] = values[index];
                  }
                  return entry;
                });
        
                setChartssData(formattedData);
              } catch (error) {
                console.error("Error fetching historical trend data:", error);
              }
            }
          };

    const handleGetHourlyReport = async () => {
        if (!selectedreportDate) {
          alert("Please select a date.");
          return;
        }
    
        try {
          const response = await axios.post(`${BaseURL}/data/hourly-report/`, {
            meter_id : "GT1_EMS1",
            date: selectedreportDate,
          });
    
          const {selected_day, columns, time_intervals, total_kwh_all_hours, total_cost_all_hours } = response.data;

    const startKwhData = columns.find(column => column.name === "start kwh").data;
    const endKwhData = columns.find(column => column.name === "end kwh").data;
    const unitRateData = columns.find(column => column.name === "unit rate").data;

    const hourlyData = time_intervals.map((interval, index) => ({
      date : selected_day,
      timeInterval: interval,
      startKwh: startKwhData[index],
      endKwh: endKwhData[index],
      unitRate: unitRateData[index],
      totalKwh: endKwhData[index] - startKwhData[index],
      totalCost: (endKwhData[index] - startKwhData[index]) * unitRateData[index],
    }));

    console.log("Processed Hourly Data:", hourlyData);

    setHourlyData(hourlyData);
    setHourlyTotalKwh(total_kwh_all_hours);
    setHourlyTotalCost(total_cost_all_hours);
    setCurrenthourlyPage(1);

  } catch (error) {
    console.error("Error fetching hourly report:", error);
    alert("Failed to fetch hourly report. Please try again.");
  }
};

    const totalhourlyPage = Math.ceil(hourlyData.length / rowsPerhourlyPage);
    const indexOfhourlyLastRow = currenthourlyPage * rowsPerhourlyPage;
    const indexOfhourlyFirstRow = indexOfhourlyLastRow - rowsPerhourlyPage;
    const currenthourlyRows = hourlyData.slice(indexOfhourlyFirstRow, indexOfhourlyLastRow);

    const handlehourlyPageChange = (page) => {
        setCurrenthourlyPage(page);
    };

    const handleGetDailyReport = async () => {
        try {
        const response = await axios.post(`${BaseURL}/data/daily-report/`, {
            meter_id: "GT1_EMS1",
            start_date: fromDate,
            end_date: toDate,
        });

        const data = response.data;
            
            const formattedData = data.dates.map((date, index) => ({
                date,
                fromDate: date,
                toDate: date,
                startKwh: data.columns[0].data[index],
                endKwh: data.columns[1].data[index],
                totalKwh: data.columns[2].data[index],
                unitRate: data.columns[3].data[index],
                totalCost: data.columns[4].data[index]
            }));

            setDailyreportData(formattedData);
            setDailyTotalKwh(data.total_kwh_all_days);
            setDailyTotalCost(data.total_cost_all_days);
        } catch (error) {
            console.error("Error fetching daily report:", error);
        }
    };

    const handleGetLogReport = async () => {
        try {
            const response = await axios.post(`${BaseURL}/data/logdata-report/`, {
                meter_id: "GT1_EMS1",
                date: selectedreportDate,
            });

            const data = response.data;
            const formattedData = data.time.map((time, index) => ({
                datetime: `${selectedreportDate} ${time}`,
                VR: data.vr_data[index],
                VY: data.vy_data[index],
                VB: data.vb_data[index],
                VRY: data.vr_data[index],
                VYB: data.vyb_data[index],
                VBR: data.vbr_data[index],
                IR: data.pf_avg_data[index],
                IY: data.pf_r_data[index],
                IB: data.pf_y_data[index], 
                KVA: data.kwh_r_data[index] / 1000,
                KW: data.kwh_y_data[index] / 1000,
                KVAR: data.kwh_b_data[index] / 1000,
                PF: data.pf_b_data[index],
                KWH: data.kwh_r_data[index] / 1000,
            }));

            setLogData(formattedData);
            setLogTotalKwh(data.total_kwh / 1000000000);
        } catch (error) {
            console.error("Error fetching log report:", error);
        }
    };

    const totalreportPage = Math.ceil(logData.length / rowsPerPage);
    const indexOfLastRow = currentreportPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = logData.slice(indexOfFirstRow, indexOfLastRow);

    const handlePageChange = (page) => {
        setCurrentreportPage(page);
    };

    const handleExportToExcel = (data, fileName) => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Report');
    
        worksheet.getColumn(1).width = 30;
        worksheet.getColumn(2).width = 20; 
        worksheet.getColumn(3).width = 15; 
        worksheet.getColumn(4).width = 15; 
        worksheet.getColumn(5).width = 15;
        worksheet.getColumn(6).width = 15; 
        worksheet.getColumn(7).width = 15; 
    
        let dateRow;
    
    if (activeTab === "Log Report") {
        dateRow = worksheet.addRow([`Date and Time: ${selectedDate}`]);
        worksheet.mergeCells(dateRow.number, 1, dateRow.number, 15); 
    
        for (let i = 1; i <= 15; i++) { 
            dateRow.getCell(i).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFD9EAD3' }, 
            };
        }
    } else if (activeTab === "Hourly Report") {
        
        dateRow = worksheet.addRow([`Date: ${selectedreportDate}`]);
        worksheet.mergeCells(dateRow.number, 1, dateRow.number, 7); 
    
        for (let i = 1; i <= 7; i++) { 
            dateRow.getCell(i).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFD9EAD3' }, 
            };
        }
    } else if (activeTab === "Daily Report") {
       
        dateRow = worksheet.addRow([`From Date: ${fromDate}, To Date: ${toDate}`]);
        worksheet.mergeCells(dateRow.number, 1, dateRow.number, 7); 
    
        for (let i = 1; i <= 7; i++) { 
            dateRow.getCell(i).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFD9EAD3' }, 
            };
        }
    }
    
    dateRow.font = { bold: true, size: 14 };
    dateRow.alignment = { horizontal: 'center', vertical: 'middle' }; 
    
    const branchRow = worksheet.addRow(['Branch: Coimbatore', ' ', ' ',' ','Place of Installation: Group Control']);
    branchRow.font = { bold: true, size: 12 };
    branchRow.getCell(1).alignment = { horizontal: 'left' }; 
    branchRow.getCell(3).alignment = { horizontal: 'center' }; 
    
    const branchCellRange = activeTab === "Log Report" ? 15 : 7; 
    for (let i = 1; i <= branchCellRange; i++) {
        branchRow.getCell(i).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFDDDDDD' }, 
        };
    }
    
    
    const titleRow = worksheet.addRow([`${activeTab}`]);
    titleRow.font = { bold: true, size: 16 };
    titleRow.alignment = { horizontal: 'center' };
    
    worksheet.mergeCells(titleRow.number, 1, titleRow.number, branchCellRange);
    
    for (let i = 1; i <= branchCellRange; i++) {
        titleRow.getCell(i).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FF1E90FF' }, 
        };
    }
    
        if (data.length > 0) {
            let headers;
            if (activeTab === "Hourly Report") {
                headers = ['Date', 'Hour', 'Start WH', 'End WH', 'Total WH', 'Unit Rate', 'Total Cost'];
            } else if (activeTab === "Daily Report") {
                headers = ['From Date', 'To Date', 'Start WH', 'End WH', 'Total WH', 'Total Cost'];
            } else if (activeTab === "Log Report") {
                headers = ['Date Time', 'VR', 'VY', 'VB', 'VRY', 'VYB', 'VBR','IR', 'IY', 'IB', 'KVA', 'KW', 'KVAR', 'PF', 'KWH'];
            }
    
           
            const headerRow = worksheet.addRow(headers);
            headerRow.font = { bold: true };
            
            
            headerRow.eachCell((cell, colIndex) => {
                if (activeTab === "Log Report" && colIndex >= 1 && colIndex <= 15) {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFA500' }, 
                    };
                } else if (colIndex >= 1 && colIndex <= 7) { 
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFA500' }, 
                    };
                }
            });
            
    
           
            data.forEach((row, rowIndex) => {
              const rowData = activeTab === "Hourly Report"
                  ? [row.date, row.timeInterval, row.startKwh, row.endKwh, (row.endKwh - row.startKwh).toFixed(2), row.unitRate.toFixed(2), ((row.endKwh - row.startKwh) * row.unitRate).toFixed(2)]
                  : activeTab === "Daily Report"
                  ? [row.date, row.toDate, row.startKwh, row.endKwh, (row.endKwh - row.startKwh).toFixed(2), row.unitRate.toFixed(2), ((row.endKwh - row.startKwh) * row.unitRate).toFixed(2)]
                  : [row.datetime, row.VR, row.VY, row.VB, row.VRY, row.VYB, row.VBR, row.IR, row.IY, row.IB, row.KVA, row.KW, row.KVAR, row.PF, row.KWH];
          
              const dataRow = worksheet.addRow(rowData);
          
              dataRow.eachCell((cell, colIndex) => {
                  let fillColor;
                  if (activeTab === "Log Report" && colIndex >= 1 && colIndex <= 15) {
                      fillColor = rowIndex % 2 === 0 ? 'FFE8EAF6' : 'FFFFFFFF'; 
                  } else if (colIndex >= 1 && colIndex <= 7) {
                      fillColor = rowIndex % 2 === 0 ? 'FFE8EAF6' : 'FFFFFFFF'; 
                  }
                  
                  if (fillColor) {
                      cell.fill = {
                          type: 'pattern',
                          pattern: 'solid',
                          fgColor: { argb: fillColor }, 
                      };
                  }
              });
          });
          
    
                const totals = activeTab === "Log Report" ? calculateLogTotals(data) : calculateTotals(data);
                const totalsRow = worksheet.addRow(
                    activeTab === "Log Report"
                        ? ['TOTAL', '', '', '', '', '', '', '', '', '', '', '', '', ' ', totals.totalKwh.toFixed(2)] 
                        : ['', '', 'TOTAL', '', totals.totalKwh.toFixed(2), '', totals.totalCost ? totals.totalCost.toFixed(2) : ''] 
                );
                
                totalsRow.font = { bold: true };
                totalsRow.eachCell((cell, colIndex) => {
                    if (activeTab === "Log Report") {
                      if (colIndex >= 1 && colIndex <= 15) { 
                            cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'FFFFEB3B' },
                            };
                            cell.alignment = { horizontal: 'right' };
                            cell.border = {
                                top: { style: 'thin' },
                                left: { style: 'thin' },
                                bottom: { style: 'thin' },
                                right: { style: 'thin' },
                            };
                        }
                    } else {
                        if (colIndex >= 1 && colIndex <= 7) { 
                            cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'FFFFEB3B' }, 
                            };
                            cell.alignment = { horizontal: 'right' };
                            cell.border = {
                                top: { style: 'thin' },
                                left: { style: 'thin' },
                                bottom: { style: 'thin' },
                                right: { style: 'thin' },
                            };
                        }
                    }
                });
                
              } else {
            worksheet.addRow(['No data available']).font = { italic: true };
        }
    
        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], { type: 'application/octet-stream' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(url);
        });
    };

    const calculateTotals = (data) => {
        const totalKwh = data.reduce((sum, row) => sum + (row.endKwh - row.startKwh), 0);
        const totalCost = data.reduce(
        (sum, row) => sum + (row.endKwh - row.startKwh) * row.unitRate,
        0
        );
        return { totalKwh, totalCost };
    };
    
    const calculateLogTotals = (data) => {
        const totalKwh = data.reduce((sum, row) => sum + row.KWH, 0);
        return { totalKwh };
    };

    let colorIndex = 0;

    const getRandomColor = () => {
        const colors = ["red", "blue", "green", "yellow", "orange"];
        const color = colors[colorIndex];
        colorIndex = (colorIndex + 1) % colors.length;
        return color;
    };

    const options = {
        title: {
            text: `${historicalTrend}`,
        },
        rangeSelector: {
            enabled: false
        },
        xAxis: {
            type: "datetime",
            categories: chartssData.map((item) => item.time),
        },
        yAxis: {
            title: {
                text: historicalTrend,
            },
        },
        series: Object.keys(chartssData[0] || {})
            .filter((key) => key !== "time")
            .map((key) => ({
                name: `${key}_${historicalTrend}`,
                data: chartssData.map((item) => item[key]),
                tooltip: {
                    valueSuffix: ` ${historicalTrend}`,
                },
                color: getRandomColor(),
            })),
    };

    const gaugeOptions = (label, value, maxValue, unit) => ({
        chart: {
            type: 'radialBar',
        },
        plotOptions: {
            radialBar: {
                startAngle: -135,
                endAngle: 135,
                hollow: {
                    size: '70%',
                },
                track: {
                    background: '#e7e7e7',
                    strokeWidth: '100%',
                },
                dataLabels: {
                    name: {
                        show: true,
                        fontSize: '16px',
                    },
                    value: {
                        show: true,
                        fontSize: '14px',
                        formatter: () => `${value || 0} ${unit}`,
                    },
                },
            },
        },
        fill: {
            color: '#007acc',
        },
        stroke: {
            lineCap: 'round',
        },
        labels: [label],
        yaxis: {
            min: 0,
            max: maxValue || 100,
        },
    });

    const title = pf.Total >= 1 ? "Lead" : "Lag";

        const handleMeterClick = (group_name) => {
            navigate("/EMS/EmsSubGroup", { state: { group_name } });
            window.scrollTo(0, 0);
          };
    
          const indexOfLastMachine = currentPage * itemsPerPage;
          const indexOfFirstMachine = indexOfLastMachine - itemsPerPage;
          const currentMachines = submachines.slice(indexOfFirstMachine, indexOfLastMachine);

          const totalPages = Math.ceil(submachines.length / itemsPerPage);
          const paginate = (pageNumber) => setCurrentPage(pageNumber);


        const handleNewDateChange = (date) => {
            setdateSelectedDate(date);
        };

        const handleTimeDateChange = (date) => {
            settimeSelectedDate(date);
        };

          

    return (
        <div className="dashboard-container">
           <Header title="DASHBOARD" />

            <div className="card-container" style={{
                border: '1px solid #ccc', 
                borderRadius: '10px', 
                padding: '20px', 
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', 
                backgroundColor: '#fff',
                marginTop: '70px',
                maxWidth: '1000px',
                marginLeft: 'auto',
                marginRight: 'auto'
            }}>
                <h3 className="dashboard-subtitle">MAIN</h3>
                <div className="widgets-container" style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '20px', marginBottom: '20px' }}>
                    <div className="widget-container" style={{ width: '100%' }}>
                        <div className="widget" style={{ padding: '10px', border: '1px solid #ccc', borderRadius: '8px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)' }}>
                            <h3>Today's Data</h3>
                            <div style={{ display: 'table', width: '100%' }}>
                                <div style={{ display: 'table-row' }}>
                                    <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '15px' }}>Units</p>
                                    <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p>
                                    <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}>{energyData.today.kWh} kWh</p>
                                </div>
                                <div style={{ display: 'table-row' }}>
                                    <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '15px' }}>Cost</p>
                                    <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p>
                                    <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}>Rs {energyData.today.cost}</p>
                                </div>
                                <div style={{ display: 'table-row' }}>
                                    <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '15px' }}>Co2</p>
                                    <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p>
                                    <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}>{energyData.today.co2} Tons</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="widget-container" style={{ width: '100%' }}>
                        <div className="widget" style={{ padding: '10px', border: '1px solid #ccc', background: 'linear-gradient(145deg, #4caf50, #2b9e36)', borderRadius: '8px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)' }}>
                            <h3>Yesterday</h3>
                            <div style={{ display: 'table', width: '100%' }}>
                                <div style={{ display: 'table-row' }}>
                                    <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '15px'  }}>Units</p>
                                    <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p>
                                    <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}>{energyData.yesterday.kWh} kWh</p>
                                </div>
                                <div style={{ display: 'table-row' }}>
                                    <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '15px'  }}>Cost</p>
                                    <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p>
                                    <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}>Rs {energyData.yesterday.cost}</p>
                                </div>
                                <div style={{ display: 'table-row' }}>
                                    <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '15px'  }}>Co2</p>
                                    <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p>
                                    <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}>{energyData.yesterday.co2} Tons</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="widget-container" style={{ width: '100%' }}>
                        <div className="widget" style={{ padding: '10px', border: '1px solid #ccc', background: 'linear-gradient(145deg, #2196f3, #0b7dda)', borderRadius: '8px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)' }}>
                            <h3>Current Month</h3>
                            <div style={{ display: 'table', width: '100%' }}>
                                <div style={{ display: 'table-row' }}>
                                    <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '15px'  }}>Units</p>
                                    <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p>
                                    <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}>{energyData.current_month.kWh} kWh</p>
                                </div>
                                <div style={{ display: 'table-row' }}>
                                    <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '15px'  }}>Cost</p>
                                    <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p>
                                    <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}>Rs {energyData.current_month.cost}</p>
                                </div>
                                <div style={{ display: 'table-row' }}>
                                    <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '15px'  }}>Co2</p>
                                    <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p>
                                    <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}>{energyData.current_month.co2} Tons</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="widget-container" style={{ width: '100%' }}>
                        <div className="widget" style={{ padding: '10px', border: '1px solid #ccc', background: 'linear-gradient(145deg, #f44336, #d32f2f)', borderRadius: '8px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)' }}>
                            <h3>Last Month</h3>
                            <div style={{ display: 'table', width: '100%' }}>
                                <div style={{ display: 'table-row' }}>
                                    <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '15px'  }}>Units</p>
                                    <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p>
                                    <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}>{energyData.last_month.kWh} kWh</p>
                                </div>
                                <div style={{ display: 'table-row' }}>
                                    <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '15px'  }}>Cost</p>
                                    <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p>
                                    <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}>Rs {energyData.last_month.cost}</p>
                                </div>
                                <div style={{ display: 'table-row' }}>
                                    <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '15px'  }}>Co2</p>
                                    <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p>
                                    <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}>{energyData.last_month.co2} Tons</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className="extra-details-row"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: '20px',
                    }}
                >
                   <div
                        className="container-1"
                        style={{
                            flex: 1,
                            border: '1px solid #ccc',
                            borderRadius: '10px',
                            padding: '20px',
                            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                            backgroundColor: '#f9f9f9',
                        }}
                    >
                        <h4><strong>Details</strong></h4>
                        <table
                            style={{
                                width: '100%',
                                borderCollapse: 'collapse',
                                marginTop: '20px',
                                fontFamily: "'Roboto', sans-serif",
                                backgroundColor: '#fff',
                                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                                borderRadius: '10px',
                                overflow: 'hidden',
                            }}
                        >
                            <tbody>
                                {[
                                    [
                                        { label: 'Meter Name', value: details.meter_name || 'N/A' },
                                        { label: 'Meter ID', value: details.meter_id || 'N/A' },
                                    ],
                                    [
                                        {
                                            label: 'Status',
                                            value: (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            width: '80px',
                                                            height: '20px',
                                                            backgroundColor: getStatusColor(details.status),
                                                            borderRadius: '4px',
                                                            marginBottom: '5px',
                                                        }}
                                                    />
                                                </div>
                                            ),
                                        },
                                        { label: 'Group Name', value: details.group_name || 'N/A' },
                                    ],
                                    [
                                        { label: 'Model', value: details.model || 'N/A' },
                                        { label: 'Last Updated', value: details.last_updated || 'N/A' },
                                    ],
                                ].map((row, rowIndex) => (
                                    <tr key={rowIndex} style={{ borderBottom: '1px solid #ddd' }}>
                                        {row.map((item, cellIndex) => (
                                            <td
                                                key={`${rowIndex}-${cellIndex}`}
                                                style={{
                                                    padding: '15px',
                                                    width: '50%',
                                                    backgroundColor: rowIndex % 2 === 0 ? '#f9f9f9' : '#fff',
                                                    textAlign: 'center',
                                                    verticalAlign: 'top',
                                                    transition: 'background-color 0.3s ease',
                                                }}
                                                onMouseEnter={(e) =>
                                                    (e.currentTarget.style.backgroundColor = '#f0f8ff')
                                                }
                                                onMouseLeave={(e) =>
                                                    (e.currentTarget.style.backgroundColor =
                                                        rowIndex % 2 === 0 ? '#f9f9f9' : '#fff')
                                                }
                                            >
                                                <div
                                                    style={{
                                                        fontWeight: 'bold',
                                                        marginBottom: '5px',
                                                        fontSize: '14px',
                                                        color: '#333',
                                                    }}
                                                >
                                                    {item.label}
                                                </div>
                                                <div
                                                    style={{
                                                        color: '#555',
                                                        fontSize: '13px',
                                                        wordWrap: 'break-word',
                                                    }}
                                                >
                                                    {item.value}
                                                </div>
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                                <tr>
                                    <td
                                        colSpan="2"
                                        style={{
                                            padding: '15px',
                                            backgroundColor: '#f0f8ff',
                                            textAlign: 'center',
                                            fontWeight: 'bold',
                                            color: '#333',
                                            fontSize: '14px',
                                            borderTop: '1px solid #ddd',
                                        }}
                                    >
                                        <div>
                                            Live kWh : 
                                            <span
                                                style={{
                                                    fontWeight: 'normal',
                                                    marginLeft: '5px',
                                                }}
                                            >
                                                {details.wh_unit || 'N/A'}
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div
                        className="container-2"
                        style={{
                            flex: 1,
                            border: '1px solid #ccc',
                            borderRadius: '10px',
                            padding: '20px',
                            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                            backgroundColor: '#f9f9f9',
                        }}
                    >
                        <h4>Co2</h4>
                        <p><MdFactory size={150} color="#007acc" /></p>
                        <p style={{ fontWeight: 'bold' }}>{(co2 || 0).toLocaleString()} Tons</p>
                    </div>

                    <div
                        className="container-3"
                        style={{
                            flex: 1,
                            border: '1px solid #ccc',
                            borderRadius: '10px',
                            padding: '20px',
                            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                            backgroundColor: '#f9f9f9',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'relative',
                        }}
                    >
                       <h4>PF Avg</h4>
                       <div
                            style={{
                                position: 'relative',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '-20px',
                                    width: '60px',
                                    height: '20px',
                                    border: '2px solid #4caf50',
                                    borderRadius: '10px',
                                    backgroundColor: '#e8f5e9',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    color: '#4caf50',
                                    fontWeight: 'bold',
                                    fontSize: '14px',
                                    textAlign: 'center',
                                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
                                    marginTop:'5px',
                                }}
                            >
                                {pfAvg.toFixed(2)}
                            </div>
                        <div
                            style={{
                                height: '150px',
                                width: '80px',
                                border: '1px solid #ccc',
                                position: 'relative',
                                backgroundColor: '#f0f0f0',
                                overflow: 'hidden',
                                marginTop: '10px',
                                borderRadius: '15px',
                                marginTop: '20px'
                            }}
                        >
                            <span
                                style={{
                                    position: 'absolute',
                                    top: '-10px',
                                    left: '50%', 
                                    transform: 'translateX(-50%)',
                                    fontSize: '12px',
                                    color: '#333',
                                    zIndex: 2,
                                }}
                            >
                                <p>1.0</p>
                            </span>
                            <div
                                style={{
                                    width: '100%',
                                    height: Math.abs(pfAvg) > 1 ? '100%' : `${(Math.abs(pfAvg) / 1) * 100}%`,
                                    backgroundColor: '#4caf50',
                                    position: 'absolute',
                                    bottom: 0,
                                    transition: 'height 0.3s ease',
                                }}
                            />
                            <span
                                style={{
                                    position: 'absolute',
                                    bottom: '-10px', 
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                    fontSize: '12px',
                                    color: '#333',
                                }}
                            >
                                <p>0.0</p>
                            </span>
                        </div>
                    </div>
                        {/* <div
                            style={{
                                position: 'absolute',
                                left: '80px',
                                top: '50%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <span
                                style={{
                                    fontSize: '12px',
                                    color: '#333',
                                    marginBottom: '5px',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                {pfAvg}
                            </span>
                            <div
                                style={{
                                    width: 0,
                                    height: 0,
                                    borderTop: '5px solid transparent',
                                    borderBottom: '5px solid transparent',
                                    borderRight: '10px solid #333',
                                }}
                            />
                        </div> */}
                        <p><strong>{pfAvg >= 1 ? 'Lead' : 'Lag'}</strong></p>
                    </div>
                </div>

            <div className="gauge-chart-container" >
                <div className="gauge-card">
                    <h4>Voltage Phase to Neutral (V<sub>PN</sub>)</h4>
                    {vpn?.VLN_Avg !== undefined && (
                        <Chart
                            options={gaugeOptions('VPN', vpn.VLN_Avg.toLocaleString(), details.vpn_gauge?.[1] || 0, 'V')}
                            series={[((vpn.VLN_Avg || 0) / (details.vpn_gauge?.[1] || 100)) * 100]}
                            type="radialBar"
                            height={200}
                        />
                    )}
                    <div className="table-container">
                        <table className="table">
                            <tbody>
                                <tr className="table-row">
                                    <td className="table-cell">V<sub>LN</sub></td>
                                    <td className="table-cell">{(vpn.VLN_Avg || 0).toLocaleString()} V</td>
                                </tr>
                                <tr className="table-row">
                                    <td className="table-cell">V<sub>R</sub></td>
                                    <td className="table-cell">{(vpn.V_R || 0).toLocaleString()} V</td>
                                </tr>
                                <tr className="table-row">
                                    <td className="table-cell">V<sub>Y</sub></td>
                                    <td className="table-cell">{(vpn.V_Y || 0).toLocaleString()} V</td>
                                </tr>
                                <tr className="table-row">
                                    <td className="table-cell">V<sub>B</sub></td>
                                    <td className="table-cell">{(vpn.V_B || 0).toLocaleString()} V</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="gauge-card">
                    <h4>Voltage Phase to Phase (V<sub>PP</sub>)</h4>
                    {vpp?.VLL_Avg !== undefined && (
                    <Chart
                        options={gaugeOptions('VPP', vpp.VLL_Avg.toLocaleString(), details.vpp_gauge?.[1] || 0, 'V')}
                        series={[((vpp.VLL_Avg || 0) / (details.vpp_gauge?.[1] || 100)) * 100]}
                        type="radialBar"
                        height={200}
                    />
                    )}
                    <div className="table-container">
                        <table className="table">
                            <tbody>
                                <tr className="table-row">
                                    <td className="table-cell">V<sub>LL</sub></td>
                                    <td className="table-cell">{(vpp.VLL_Avg || 0).toLocaleString()} V</td>
                                </tr>
                                <tr className="table-row">
                                    <td className="table-cell">V<sub>RY</sub></td>
                                    <td className="table-cell">{(vpp.V_RY || 0).toLocaleString()} V</td>
                                </tr>
                                <tr className="table-row">
                                    <td className="table-cell">V<sub>YB</sub></td>
                                    <td className="table-cell">{(vpp.V_YB || 0).toLocaleString()} V</td>
                                </tr>
                                <tr className="table-row">
                                    <td className="table-cell">V<sub>BR</sub></td>
                                    <td className="table-cell">{(vpp.V_BR || 0).toLocaleString()} V</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="gauge-card">
                    <h4>Average Current (I<sub>Avg</sub>)</h4>
                    {iavg?.Cu_Avg !== undefined && (
                    <Chart
                        options={gaugeOptions('IAvg', iavg.Cu_Avg.toLocaleString(), details.i_gauge?.[1] || 0, 'A')}
                        series={[((iavg.Cu_Avg || 0) / (details.i_gauge?.[1] || 100)) * 100]}
                        type="radialBar"
                        height={200}
                    />
                    )}
                    <div className="table-container">
                    <table className="table">
                        <tbody>
                            <tr className="table-row">
                                <td className="table-cell">I</td>
                                <td className="table-cell">{(iavg.Cu_Avg || 0).toLocaleString()} A</td>
                            </tr>
                            <tr className="table-row">
                                <td className="table-cell">I<sub>R</sub></td>
                                <td className="table-cell">{(iavg.Cu_R || 0).toLocaleString()} A</td>
                            </tr>
                            <tr className="table-row">
                                <td className="table-cell">I<sub>Y</sub></td>
                                <td className="table-cell">{(iavg.Cu_Y || 0).toLocaleString()} A</td>
                            </tr>
                            <tr className="table-row">
                                <td className="table-cell">I<sub>B</sub></td>
                                <td className="table-cell">{(iavg.Cu_B || 0).toLocaleString()} A</td>
                            </tr>
                        </tbody>
                    </table>
                    </div>
                </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div className="gauge-container" style={{ width: '44%', position: 'relative', padding: '20px', border: '1px solid #ccc', borderRadius: '10px', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', backgroundColor: '#f9f9f9',marginLeft:'3px' }}>
                        <h4>KW</h4>
                        <div className="speedometer">
                        <ReactSpeedometer
                            minValue={0}
                            maxValue={100}
                            value={Math.min(
                                (Math.abs(KW.Total || 0) / (kwGauge[1] - kwGauge[0])) * 100,
                                100
                              )}
                            ringWidth={20}
                            customSegmentStops={[
                                0,
                                10,
                                20,
                                30,
                                40,
                                50,
                                60,
                                70,
                                80,
                                90,
                                100,
                              ]}
                            segmentColors={[
                                "#00ff00",
                                "#32cd32",
                                "#adff2f",
                                "#ffff00",
                                "#ffd700",
                                "#ffa500",
                                "#ff8c00",
                                "#ff4500",
                                "#ff0000",
                                "#8b0000",
                            ]}
                            needleColor="#000000"
                            needleTransitionDuration={5000}
                            needleTransition="easeLinear"
                            currentValueText={`${(KW.Total || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} kW`}
                            textColor="#000000"
                            labelFontSize="0px"
                        />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                            <div style={{ width: '30%' }}>
                                <p>KW<sub>R</sub></p>
                                <div className="speedometer1">
                                <ReactSpeedometer
                                    minValue={0}
                                    maxValue={100}
                                    value={Math.min(
                                        (Math.abs(KW.R || 0) / (kwGauge[1] - kwGauge[0])) * 100,
                                        100
                                      )}
                                    ringWidth={10}
                                    width={130}
                                    needleHeightRatio={0.5}
                                    customSegmentStops={[
                                        0,
                                        10,
                                        20,
                                        30,
                                        40,
                                        50,
                                        60,
                                        70,
                                        80,
                                        90,
                                        100,
                                      ]}
                                    segmentColors={[
                                        "#ff3d3d",
                                        "#ff3d3d",
                                        "#ff3d3d",
                                        "#ff3d3d",
                                        "#ff3d3d",
                                        "#ff3d3d",
                                        "#ff3d3d",
                                        "#ff3d3d",
                                        "#ff3d3d",
                                        "#ff3d3d",
                                    ]}
                                    needleColor="#000000"
                                    needleTransitionDuration={5000}
                                    needleTransition="easeLinear"
                                    valueTextFontSize="12px"
                                    currentValueText={`${(KW.R || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} kW`}
                                    textColor="#000000"
                                    labelFontSize="0px"
                                />
                                </div>
                            </div>
                            <div style={{ width: '30%' }}>
                                <p>KW<sub>Y</sub></p>
                                <div className="speedometer1">
                                <ReactSpeedometer
                                    minValue={0}
                                    maxValue={100}
                                    value={Math.min(
                                        (Math.abs(KW.Y || 0) / (kwGauge[1] - kwGauge[0])) * 100,
                                        100
                                      )}
                                    ringWidth={10}
                                    width={130}
                                    needleHeightRatio={0.5}
                                    customSegmentStops={[
                                        0,
                                        10,
                                        20,
                                        30,
                                        40,
                                        50,
                                        60,
                                        70,
                                        80,
                                        90,
                                        100,
                                      ]}
                                    segmentColors={[
                                        "#fff03d",
                                        "#fff03d",
                                        "#fff03d",
                                        "#fff03d",
                                        "#fff03d",
                                        "#fff03d",
                                        "#fff03d",
                                        "#fff03d",
                                        "#fff03d",
                                        "#fff03d",
                                    ]}
                                    needleColor="#000000"
                                    needleTransitionDuration={5000}
                                    needleTransition="easeLinear"      
                                    valueTextFontSize="12px"
                                    currentValueText={`${(KW.Y || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} kW`}
                                    textColor="#000000"
                                    labelFontSize="0px"
                                />
                                </div>
                            </div>
                            <div style={{ width: '30%' }}>
                                <p>KW<sub>B</sub></p>
                                <div className="speedometer1">
                                <ReactSpeedometer
                                    minValue={0}
                                    maxValue={100}
                                    value={Math.min(
                                        (Math.abs(KW.B || 0) / (kwGauge[1] - kwGauge[0])) * 100,
                                        100
                                      )}
                                    ringWidth={10}
                                    width={130}
                                    needleHeightRatio={0.5}
                                    customSegmentStops={[
                                        0,
                                        10,
                                        20,
                                        30,
                                        40,
                                        50,
                                        60,
                                        70,
                                        80,
                                        90,
                                        100,
                                      ]}
                                    segmentColors={[
                                        "#3db3ff",
                                        "#3db3ff",
                                        "#3db3ff",
                                        "#3db3ff",
                                        "#3db3ff",
                                        "#3db3ff",
                                        "#3db3ff",
                                        "#3db3ff",
                                        "#3db3ff",
                                        "#3db3ff",
                                    ]}
                                    needleColor="#000000"
                                    needleTransitionDuration={5000}
                                    needleTransition="easeLinear"
                                    valueTextFontSize="12px"
                                    currentValueText={`${(KW.B || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} kW`}
                                    textColor="#000000"
                                   labelFontSize="0px"
                                />
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div className="gauge-container" style={{ width: '45%', position: 'relative', padding: '20px', border: '1px solid #ccc', borderRadius: '10px', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', backgroundColor: '#f9f9f9' }}>
                        <h4>KVA</h4>
                        <div className="speedometer">
                        <ReactSpeedometer
                            minValue={0}
                            maxValue={100}
                            value={Math.min(
                                (Math.abs(KVA.Total || 0) / (kvaGauge[1] - kvaGauge[0])) * 100,
                                100
                              )}
                            ringWidth={20}
                            customSegmentStops={[
                                0,
                                10,
                                20,
                                30,
                                40,
                                50,
                                60,
                                70,
                                80,
                                90,
                                100,
                              ]}
                            segmentColors={[
                                "#00ff00",
                                "#32cd32",
                                "#adff2f",
                                "#ffff00",
                                "#ffd700",
                                "#ffa500",
                                "#ff8c00",
                                "#ff4500",
                                "#ff0000",
                                "#8b0000",
                            ]}
                            needleColor="#000000"
                            needleTransitionDuration={5000}
                            needleTransition="easeLinear"
                            currentValueText={`${(KVA.Total || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} kVA`}
                            textColor="#000000"
                            labelFontSize="0px"
                        />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                            <div style={{ width: '30%' }}>
                                <p>KVA<sub>R</sub></p>
                                <div className="speedometer1">
                                <ReactSpeedometer
                                    minValue={0}
                                    maxValue={100}
                                    value={Math.min(
                                        (Math.abs(KVA.R|| 0) / (kvaGauge[1] - kvaGauge[0])) * 100,
                                        100
                                      )}
                                    ringWidth={10}
                                    width={130}
                                    needleHeightRatio={0.5}
                                    customSegmentStops={[
                                        0,
                                        10,
                                        20,
                                        30,
                                        40,
                                        50,
                                        60,
                                        70,
                                        80,
                                        90,
                                        100,
                                      ]}
                                    segmentColors={[
                                        "#ff3d3d",
                                        "#ff3d3d",
                                        "#ff3d3d",
                                        "#ff3d3d",
                                        "#ff3d3d",
                                        "#ff3d3d",
                                        "#ff3d3d",
                                        "#ff3d3d",
                                        "#ff3d3d",
                                        "#ff3d3d",
                                    ]}
                                    needleColor="#000000"
                                    needleTransitionDuration={5000}
                                    needleTransition="easeLinear"
                                    valueTextFontSize="12px"
                                    currentValueText={`${(KVA.R || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} kVA`}
                                    textColor="#000000"
                                    labelFontSize="0px"
                                />
                                </div>
                            </div>
                            <div style={{ width: '30%' }}>
                                <p>KVA<sub>Y</sub></p>
                                <div className="speedometer1">
                                <ReactSpeedometer
                                    minValue={0}
                                    maxValue={100}
                                    value={Math.min(
                                        (Math.abs(KVA.Y || 0) / (kvaGauge[1] - kvaGauge[0])) * 100,
                                        100
                                      )}
                                    ringWidth={10}
                                    width={130}
                                    needleHeightRatio={0.5}
                                    customSegmentStops={[
                                        0,
                                        10,
                                        20,
                                        30,
                                        40,
                                        50,
                                        60,
                                        70,
                                        80,
                                        90,
                                        100,
                                      ]}
                                    segmentColors={[
                                        "#fff03d",
                                        "#fff03d",
                                        "#fff03d",
                                        "#fff03d",
                                        "#fff03d",
                                        "#fff03d",
                                        "#fff03d",
                                        "#fff03d",
                                        "#fff03d",
                                        "#fff03d",
                                    ]}
                                    needleColor="#000000"
                                    needleTransitionDuration={5000}
                                    needleTransition="easeLinear"
                                    valueTextFontSize="12px"
                                    currentValueText={`${(KVA.Y || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} kVA`}
                                    textColor="#000000"
                                    labelFontSize="0px"
                                />
                                </div>
                            </div>
                            <div style={{ width: '30%' }}>
                                <p>KVA<sub>B</sub></p>
                                <div className="speedometer1">
                                <ReactSpeedometer
                                    minValue={0}
                                    maxValue={100}
                                    value={Math.min(
                                        (Math.abs(KVA.B || 0) / (kvaGauge[1] - kvaGauge[0])) * 100,
                                        100
                                      )}
                                    ringWidth={10}
                                    width={130}
                                    needleHeightRatio={0.5}
                                    customSegmentStops={[
                                        0,
                                        10,
                                        20,
                                        30,
                                        40,
                                        50,
                                        60,
                                        70,
                                        80,
                                        90,
                                        100,
                                      ]}
                                    segmentColors={[
                                        "#3db3ff",
                                        "#3db3ff",
                                        "#3db3ff",
                                        "#3db3ff",
                                        "#3db3ff",
                                        "#3db3ff",
                                        "#3db3ff",
                                        "#3db3ff",
                                        "#3db3ff",
                                        "#3db3ff",
                                    ]}
                                    needleColor="#000000"
                                    needleTransitionDuration={5000}
                                    needleTransition="easeLinear"
                                    valueTextFontSize="12px"
                                    currentValueText={`${(KVA.B || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} kVA`}
                                    textColor="#000000"
                                    labelFontSize="0px"
                                />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <div className="charts-container" style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <div className="gauge-container" style={{ width: '31%', position: 'relative', padding: '20px', border: '1px solid #ccc', borderRadius: '10px', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', backgroundColor: '#f9f9f9', marginLeft: '5px' }}>
                <h4 style={{ paddingBottom: '0px', textAlign: 'center', marginTop: '5px', marginBottom: '50px' }}>PF</h4>
                <span style={{ position: 'absolute',top : '80px', transform: 'translateX(-50%)' }}><strong>1</strong></span>
                <div className="speedometer">
                <ReactSpeedometer
                    id={`speedometer-kwh`}
                    minValue={-50}  
                    maxValue={50}   
                    value={pf.Total || 0}  
                    needleColor="#000000"
                    segmentColors={[
                        "#ff0000",
                        "#ff8c00",
                        "#ffd700",
                        "#00ff00",
                        "#00ff00",
                        "#ffd700",
                        "#ff8c00",
                        "#ff0000",
                    ]}
                    segments={8}
                    ringWidth={20}
                    width={260}
                    needleHeightRatio={0.95}
                    needleTransitionDuration={5000}
                    needleTransition="easeLinear"
                    textColor="#000000"
                    currentValueText={`${pf.Total || 0}`}  
                    valueTextFontSize="14px"
                    labelFontSize="0px"
                     />
            </div>
            <div style={{ position: 'absolute', top: '55%', left: '50%', width: '100%', fontSize: '14px', fontWeight: 'bold', transform: 'translateX(-50%)' }}>
                <span style={{
                    position: 'absolute',
                    left: '15%',
                    transform: 'translateX(-50%) rotate(-80deg)',
                    transformOrigin: 'left center',
                }}>-0.5</span>
                <span style={{
                    position: 'absolute',
                    right: '15%',
                    transform: 'translateX(50%) rotate(80deg)',
                    transformOrigin: 'right center',
                }}>0.5</span>
            </div>
    <div style={{ display: 'flex', gap: '20px', justifyContent: 'center', marginTop: '10px' }}>
      <p><strong>{title}</strong></p>
    </div>
  </div>
  <div 
    className="line-chart-card" 
    style={{
      border: '1px solid #ccc', 
      borderRadius: '8px', 
      width: '61.5%',  
      maxWidth: '600%',  
      boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', 
      backgroundColor: 'white',
      // marginLeft:'-40px'
    }}
  >
    <h4>PF</h4>
    <div
    style={{
      alignItems: "center",
      marginBottom: "20px",
    }}
  >
    <label
      htmlFor="date-picker"
      style={{
        fontWeight: "bold",
        fontSize: "14px",
        color: "#333",
        marginRight: "20px",
      }}
    >
      Date:
    </label>
    <DatePicker
      id="date-picker"
      selected={selectedDate}
      onChange={(date) => setSelectedDate(date)}
      dateFormat="yyyy/MM/dd"
      customInput={
        <input
          style={{
            fontSize: "12px",
            width: '80px',
            padding: "5px",
            border: "1px solid #ccc",
            borderRadius: "4px",
            marginRight: "20px",
          }}
        />
      }
    />
  </div>
    <Chart
      options={lineChartOptions}
      series={lineChartOptions.series}
      type="line"
      height={250}
      width="100%"  
    />
  </div>
</div>

            <div className="charts-container" style={{ marginTop: '20px', display: 'flex'}}>
            <div
                    style={{
                        width: '100%',
                        // border: '1px solid #ccc',
                        // borderRadius: '10px',
                        // padding: '15px',
                        // boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                        // backgroundColor: '#f9f9f9',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'relative',
                    }}
                    >
                <div
                    className="chart"
                    style={{
                    width: '88%',
                    height: '210px',
                    border: '1px solid #ccc',
                    borderRadius: '10px',
                    padding: '15px',
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                    backgroundColor: '#f9f9f9',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent:'center',
                    position: 'relative',
                    marginLeft: '15px',
                    marginRight: '15px'
                    }}
                >
                     <div
                        style={{
                            position: 'absolute',
                            top: '10px',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            fontSize: '18px',
                            fontWeight: 'bold',
                            color: '#333',
                            backgroundColor: '#f9f9f9',
                            padding: '5px',
                            borderRadius: '5px',
                           
                        }}
                    >
                        V<sub>THD</sub>
                    </div>
                    <div
                    style={{
                        height: '150px',
                        width: '80px',
                        border: '1px solid #ccc',
                        position: 'relative',
                        backgroundColor: '#f0f0f0',
                        overflow: 'hidden',
                        marginRight: '20px',
                        borderRadius: '15px',
                    }}
                    >
                    <span
                        style={{
                        position: 'absolute',
                        top: '-10px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        fontSize: '12px',
                        color: '#333',
                        }}
                    >
                        <p>{vthd.VTHD_Avg}%</p>
                    </span>
                    <div
                        style={{
                        width: '100%',
                        height: `${vthd.VTHD_Avg}%`,
                        backgroundColor: '#4caf50',
                        position: 'absolute',
                        bottom: 0,
                        transition: 'height 0.3s ease',
                        }}
                    />
                    <span
                        style={{
                        position: 'absolute',
                        bottom: '-10px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        fontSize: '12px',
                        color: '#333',
                        }}
                    >
                        <p>0%</p>
                    </span>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '20px' }}>
                        <div style={{ fontSize: '14px', fontWeight: 'bold', color: '#333', marginTop: '35px', marginRight: '5px' }}>V<sub>R</sub></div>
                        <div style={{ fontSize: '14px', fontWeight: 'bold', color: '#333', marginTop: '25px' }}>V<sub>Y</sub></div>
                        <div style={{ fontSize: '14px', fontWeight: 'bold', color: '#333', marginTop: '20px' }}>V<sub>B</sub></div>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '170px', marginBottom: '20px' }}>
                    <p><strong>{vthd.VTHD_Avg}%</strong></p>
                    <div
                        style={{
                            height: '50px',
                            width: '150px',
                            border: '1px solid #ccc',
                            backgroundColor: '#f0f0f0',
                            position: 'relative',
                            overflow: 'hidden',
                            marginBottom: '5px',
                            borderRadius: '10px',
                        }}
                    >
                        <div
                            style={{
                                width: `${vthd.VHar_R || 0}%`,
                                height: '100%',
                                backgroundColor: '#4caf50',
                                transition: 'width 0.3s ease',
                            }}
                        />
                        <span style={{ 
                            position: 'absolute', 
                            right: '5px', 
                            top: '50%', 
                            transform: 'translateY(-50%)', 
                            color: '#000' 
                        }}>
                            {vthd.VHar_R || 0}%
                        </span>
                    </div>
                    <div
                        style={{
                            height: '50px',
                            width: '150px',
                            border: '1px solid #ccc',
                            backgroundColor: '#f0f0f0',
                            position: 'relative',
                            overflow: 'hidden',
                            marginBottom: '5px',
                            borderRadius: '10px',
                            marginTop: '10px'
                        }}
                    >
                        <div
                            style={{
                                width: `${vthd.VHar_Y || 0}%`,
                                height: '100%',
                                backgroundColor: '#4caf50',
                                transition: 'width 0.3s ease',
                            }}
                        />
                        <span style={{ 
                            position: 'absolute', 
                            right: '5px', 
                            top: '50%', 
                            transform: 'translateY(-50%)', 
                            color: '#000' 
                        }}>
                            {vthd.VHar_Y || 0}%
                        </span>
                    </div>

                    <div
                        style={{
                            height: '50px',
                            width: '150px',
                            border: '1px solid #ccc',
                            backgroundColor: '#f0f0f0',
                            position: 'relative',
                            overflow: 'hidden',
                            borderRadius: '10px',
                            marginTop: '10px'
                        }}
                    >
                        <div
                            style={{
                                width: `${vthd.VHar_B || 0}%`,
                                height: '100%',
                                backgroundColor: '#4caf50',
                                transition: 'width 0.3s ease',
                            }}
                        />
                        <span style={{ 
                            position: 'absolute', 
                            right: '5px', 
                            top: '50%', 
                            transform: 'translateY(-50%)', 
                            color: '#000' 
                        }}>
                            {vthd.VHar_B || 0}%
                        </span>
                    </div>
                </div>
                </div>
                </div>
                <div className="charts-container" style={{ marginTop: '15px', display: 'flex', justifyContent: 'space-between', marginRight: '10px'}}>
                    <div className="gauge-container" style={{ width: '80%',height: '200px', position: 'relative', padding: '20px', border: '1px solid #ccc', borderRadius: '10px', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', backgroundColor: '#f9f9f9', marginLeft: '5px'}}>
                    <h4
                        style={{
                            paddingBottom: '0px', textAlign: 'center', marginTop: '-5px' 
                        }}
                        >
                        Frequency
                        </h4>
                        <div className="speedometer">
                        <ReactSpeedometer
                            id={`speedometer-kwh`}
                            minValue={0}  
                            maxValue={80}   
                            value={freq || 0}  
                            needleColor="#000000"
                            segmentColors={[
                                "#3dadff",
                                "#3dadff",
                                "#3dadff", 
                                "#3dadff", 
                                "#3dadff", 
                            ]}
                            ringWidth={20}
                            width={260}
                            needleHeightRatio={0.95}
                            needleTransitionDuration={5000}
                            needleTransition="easeLinear"
                            textColor="#000000"
                            currentValueText={`${freq || 0}`}  
                            valueTextFontSize="14px"
                            labelFontSize="0px"
                            />
                    </div>
                </div>
            </div>
                <div
                    style={{
                        width: '90%',
                        // border: '1px solid #ccc',
                        // borderRadius: '10px',
                        padding: '15px',
                        // boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                        // backgroundColor: '#f9f9f9',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center', 
                        justifyContent: 'center',
                        position: 'relative',
                    }}
                    >
                <div
                    className="chart"
                    style={{
                    width: '100%',
                    height: '210px',
                    border: '1px solid #ccc',
                    borderRadius: '10px',
                    padding: '15px',
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                    backgroundColor: '#f9f9f9',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent:'center',
                    position: 'relative',
                    marginLeft: '-20px'
                    }}
                >
                <div
                        style={{
                            position: 'absolute',
                            top: '10px',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            fontSize: '18px',
                            fontWeight: 'bold',
                            color: '#333',
                            backgroundColor: '#f9f9f9',
                            padding: '5px',
                            borderRadius: '5px',
                           
                        }}
                    >
                        I<sub>THD</sub>
                    </div>
                    <div
                    style={{
                        height: '150px',
                        width: '80px',
                        border: '1px solid #ccc',
                        position: 'relative',
                        backgroundColor: '#f0f0f0',
                        overflow: 'hidden',
                        marginRight: '20px',
                        borderRadius: '15px',
                    }}
                    >
                    <span
                        style={{
                        position: 'absolute',
                        top: '-10px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        fontSize: '12px',
                        color: '#333',
                        }}
                    >
                        <p>{ithd.ITHD_Avg}%</p>
                    </span>
                    <div
                        style={{
                        width: '100%',
                        height: `${ithd.ITHD_Avg}%`,
                        backgroundColor: '#4caf50',
                        position: 'absolute',
                        bottom: 0,
                        transition: 'height 0.3s ease',
                        }}
                    />
                    <span
                        style={{
                        position: 'absolute',
                        bottom: '-10px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        fontSize: '12px',
                        color: '#333',
                        }}
                    >
                        <p>0%</p>
                    </span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '20px' }}>
                        <div style={{ fontSize: '14px', fontWeight: 'bold', color: '#333', marginTop: '35px', marginRight: '5px' }}>I<sub>R</sub></div>
                        <div style={{ fontSize: '14px', fontWeight: 'bold', color: '#333', marginTop: '25px' }}>I<sub>Y</sub></div>
                        <div style={{ fontSize: '14px', fontWeight: 'bold', color: '#333', marginTop: '20px' }}>I<sub>B</sub></div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '170px', marginBottom: '20px' }}>
                        <p><strong>{ithd.ITHD_Avg}%</strong></p>
                    <div
                        style={{
                            height: '50px',
                            width: '150px',
                            border: '1px solid #ccc',
                            backgroundColor: '#f0f0f0',
                            position: 'relative',
                            overflow: 'hidden',
                            marginBottom: '5px',
                            borderRadius: '10px',
                        }}
                    >
                        <div
                            style={{
                                width: `${ithd.CuHar_R || 0}%`,
                                height: '100%',
                                backgroundColor: '#4caf50',
                                transition: 'width 0.3s ease',
                            }}
                        />
                        <span style={{ 
                            position: 'absolute', 
                            right: '5px', 
                            top: '50%', 
                            transform: 'translateY(-50%)', 
                            color: '#000' 
                        }}>
                            {ithd.CuHar_R || 0}%
                        </span>
                    </div>
                    <div
                        style={{
                            height: '50px',
                            width: '150px',
                            border: '1px solid #ccc',
                            backgroundColor: '#f0f0f0',
                            position: 'relative',
                            overflow: 'hidden',
                            marginBottom: '5px',
                            borderRadius: '10px',
                            marginTop: '10px'
                        }}
                    >
                        <div
                            style={{
                                width: `${ithd.CuHar_Y || 0}%`,
                                height: '100%',
                                backgroundColor: '#4caf50',
                                transition: 'width 0.3s ease',
                            }}
                        />
                        <span style={{ 
                            position: 'absolute', 
                            right: '5px', 
                            top: '50%', 
                            transform: 'translateY(-50%)', 
                            color: '#000' 
                        }}>
                            {ithd.CuHar_Y || 0}%
                        </span>
                    </div>

                    <div
                        style={{
                            height: '50px',
                            width: '150px',
                            border: '1px solid #ccc',
                            backgroundColor: '#f0f0f0',
                            position: 'relative',
                            overflow: 'hidden',
                            borderRadius: '10px',
                            marginTop: '10px'
                        }}
                    >
                        <div
                            style={{
                                width: `${ithd.CuHar_B || 0}%`,
                                height: '100%',
                                backgroundColor: '#4caf50',
                                transition: 'width 0.3s ease',
                            }}
                        />
                        <span style={{ 
                            position: 'absolute', 
                            right: '5px', 
                            top: '50%', 
                            transform: 'translateY(-50%)', 
                            color: '#000' 
                        }}>
                            {ithd.CuHar_B || 0}%
                        </span>
                    </div>
                </div>
                </div>
                </div>
            </div>
            <div className="charts-container" style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <div
                className="container-4"
                style={{
                    border: '1px solid #ccc',
                    borderRadius: '10px',
                    padding: '20px',
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                    backgroundColor: '#f9f9f9',
                    marginTop: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                }}
            >
                <h4>{chartData.graph_title}</h4>
                <div style={{ marginBottom: "20px" }}>
                    <label
                    htmlFor="date-picker"
                    style={{
                        alignItems: "center",
                        marginBottom: "20px",
                      }}
                    >
                    Date:
                    </label>
                    <DatePicker
                    id="date-picker"
                    selected={dateselectedDate}
                    onChange={(date) => handleNewDateChange(date)}
                    dateFormat="yyyy/MM/dd"
                    customInput={
                        <input
                        style={{
                            fontSize: "12px",
                            width:'55%',
                        }}
                        />
                    }
                    />
                </div>
                <Chart
                options={{
                    chart: {
                        type: 'bar',
                        height: 350,
                        toolbar: {
                            tools: { download: false },
                        },
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '50%',
                            endingShape: 'rounded',
                        },
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    xaxis: {
                        categories: chartData.time_intervals,
                        title: {
                            text: chartData.x_axis_label,
                        },
                    },
                    yaxis: {
                        title: {
                            text: chartData.y_axis_label,
                        },
                        labels: {
                            formatter: (value) => {
                                return new Intl.NumberFormat('en-US').format(value);
                            },
                        },
                    },
                    tooltip: {
                        y: {
                            formatter: (value, { seriesIndex }) => {
                                if (chartData.bars[seriesIndex]?.name === 'cost') {
                                    return `Rs ${new Intl.NumberFormat('en-US').format(value)}`;
                                }
                                return new Intl.NumberFormat('en-US').format(value);
                            },
                        },
                    },
                    legend: {
                        position: 'top',
                        horizontalAlign: 'center',
                        offsetX: 0,
                        offsetY: 0,
                    },
                }}
                series={chartData.bars.map(bar => ({
                    name: bar.name,
                    data: bar.data,
                }))}
                type="bar"
                height={350}
                width={950}
            />
            </div>
        </div>

         <div
            className="charts-container"
            style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between', width: '100%' }}
        >
            <div
                className="container-4"
                style={{
                    border: '1px solid #ccc',
                    borderRadius: '10px',
                    padding: '20px',
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                    backgroundColor: '#f9f9f9',
                    marginTop: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                }}
            >
                <h4>Day's Consumption</h4>
                <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: "30px",
                }}
            >
                <label
                    style={{
                        fontSize: "14px",
                        color: "#333",
                        fontWeight: "bold",
                        marginRight: "10px",
                    }}
                >
                    Month:
                </label>
                <select
                    value={selectedMonth}
                    onChange={handleMonthChange}
                    style={{
                        padding: "5px",
                        marginRight: "20px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                    }}
                >
                    {monthNames.map((month, index) => (
                        <option key={index + 1} value={index + 1}>
                            {month}
                        </option>
                    ))}
                </select>

                <label
                    style={{
                        fontSize: "14px",
                        color: "#333",
                        fontWeight: "bold",
                        marginRight: "10px",
                    }}
                >
                    Year:
                </label>
                <select
                    value={selectedYear}
                    onChange={handleYearChange}
                    style={{
                        padding: "5px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                    }}
                >
                    {Array.from({ length: 5 }, (_, i) => new Date().getFullYear() - i).map(year => (
                        <option key={year} value={year}>
                            {year}
                        </option>
                    ))}
                </select>
            </div>
            <Chart
                    options={{
                        chart: {
                            type: 'bar',
                            height: 350,
                            toolbar: {
                                tools: {
                                    zoom: false,
                                    zoomin: false,
                                    zoomout: false,
                                    pan: false,
                                    reset: false,
                                    download: false,
                                },
                            },
                            zoom: {
                                enabled: true,
                            },
                        },
                        plotOptions: {
                            bar: {
                                horizontal: false,
                                columnWidth: '50%',
                                endingShape: 'rounded',
                            },
                        },
                        dataLabels: {
                            enabled: false,
                        },
                        xaxis: {
                            categories: dailyData.categories,
                            title: {
                                text: 'Dates',
                            },
                        },
                        yaxis: {
                            title: {
                                text: 'Value',
                            },
                            labels: {
                                formatter: (value) => {
                                    return new Intl.NumberFormat('en-US').format(value);
                                },
                            },
                        },
                        legend: {
                            position: 'top',
                            horizontalAlign: 'center',
                            offsetX: 0,
                            offsetY: 0,
                        },
                        tooltip: {
                            y: {
                                formatter: function (value, { seriesIndex }) {
                                    if (seriesIndex === 1) {
                                        return `Rs ${value.toLocaleString()}`;
                                    }
                                    return value.toLocaleString();
                                },
                            },
                        },
                    }}
                    series={[
                        {
                            name: 'kWh',
                            data: dailyData.kw,
                        },
                        {
                            name: 'Cost',
                            data: dailyData.cost,
                        },
                        {
                            name: 'CO2',
                            data: dailyData.co2,
                        },
                    ]}
                    type="bar"
                    height={350}
                    width={950}
                />
            </div>
        </div>

        <div className="charts-container" style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <div
                className="container-4"
                style={{
                    border: '1px solid #ccc',
                    borderRadius: '10px',
                    padding: '20px',
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                    backgroundColor: '#f9f9f9',
                    marginTop: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                }}
            >
                <h4>Monthly Consumption</h4>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginBottom: "30px",
                    }}
                >
                    <label
                        style={{
                            fontSize: "14px",
                            color: "#333",
                            fontWeight: "bold",
                            marginRight: "10px",
                        }}
                    >
                        Year:
                    </label>
                    <select
                        value={selectedNewYear}
                        onChange={handleNewYearChange}
                        style={{
                            padding: "5px",
                            borderRadius: "5px",
                            border: "1px solid #ccc",
                        }}
                    >
                        {Array.from({ length: 5 }, (_, i) => new Date().getFullYear() - i).map(year => (
                            <option key={year} value={year}>
                                {year}
                            </option>
                        ))}
                    </select>
                </div>
                <Chart
                    options={{
                        chart: {
                            type: 'bar',
                            height: 350,
                            toolbar: {
                                tools: {
                                    zoom: false,
                                    zoomin: false,
                                    zoomout: false,
                                    pan: false,
                                    reset: false,
                                    download: false,
                                },
                            },
                            zoom: {
                                enabled: true,
                            },
                        },
                        plotOptions: {
                            bar: {
                                horizontal: false,
                                columnWidth: '50%',
                                endingShape: 'rounded',
                            },
                        },
                        dataLabels: {
                            enabled: false,
                        },
                        xaxis: {
                            categories: monthlyData.categories,
                            title: {
                                text: 'Months',
                            },
                        },
                        yaxis: {
                            title: {
                                text: 'Value',
                            },
                            labels: {
                                formatter: (value) => {
                                    return new Intl.NumberFormat('en-US').format(value);
                                },
                            },
                        },
                        legend: {
                            position: 'top',
                            horizontalAlign: 'center',
                            offsetX: 0,
                            offsetY: 0,
                        },
                        tooltip: {
                            y: {
                                formatter: function (value, { seriesIndex }) {
                                    if (seriesIndex === 1) {
                                        return `Rs ${value.toLocaleString()}`;
                                    }
                                    return value.toLocaleString();
                                },
                            },
                        },
                    }}
                    series={[
                        {
                            name: 'kWh',
                            data: monthlyData.kWh,
                        },
                        {
                            name: 'Cost',
                            data: monthlyData.cost,
                        },
                        {
                            name: 'CO2',
                            data: monthlyData.co2,
                        },
                    ]}
                    type="bar"
                    height={350}
                    width={950}
                />
            </div>
        </div>

        <div
            style={{
                width: "96%",
                margin: "20px auto",
                padding: "20px",
                border: "1px solid #ccc",
                borderRadius: "10px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#fff",
            }}
            >
            <h4>
                Time Slots
            </h4>

            <div
        style={{
            flexDirection: "row",
            alignItems: "center",
            marginBottom: "30px",
            marginLeft: "150px"
        }}
      >
       
        <label
          style={{
            fontSize: "14px",
            color: "#333",
            marginBottom: "5px", 
            fontWeight: "bold",
          }}
        >
         Date:
        </label>
        <DatePicker
          selected={timeselectedDate}
          onChange={handleTimeDateChange}
          dateFormat="yyyy/MM/dd"
          customInput={
            <input
              style={{
                fontSize: "12px",
                width:'35%',
                marginRight:'140px'
              }}
            />
          }
        />
      </div>

            <div
                style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "30px",
                }}
                
            >
                <Chart
                    options={chartsData.options}
                    series={chartsData.series}
                    type="bar"
                    height={350}
                    width={800}
                />
            </div>

            <table
                style={{
                width: "100%",
                borderCollapse: "collapse",
                textAlign: "center",
                }}
            >
                <thead>
                <tr>
                    <th
                    style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        backgroundColor: "#f4f4f4",
                    }}
                    >
                    Timings
                    </th>
                    <th
                    style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        backgroundColor: "#f4f4f4",
                    }}
                    >
                    Rate
                    </th>
                    <th
                    style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        backgroundColor: "#f4f4f4",
                    }}
                    >
                    Today's Wh
                    </th>
                    <th
                    style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        backgroundColor: "#f4f4f4",
                    }}
                    >
                    Total Cost
                    </th>
                    <th
                    style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        backgroundColor: "#f4f4f4",
                    }}
                    >
                    Total Co2
                    </th>
                </tr>
                </thead>
                <tbody>
                {tableData.map((row, index) => (
                    <tr key={index}>
                    <td
                        style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        }}
                    >
                        {row.Timings}
                    </td>
                    <td
                        style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        }}
                    >
                        Rs {row.Rate.toLocaleString()}
                    </td>
                    <td
                        style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        textAlign: "right",
                        }}
                    >
                        {row.TodaysKWhr.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </td>
                    <td
                        style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        textAlign: "right",
                        }}
                    >
                        Rs {row.TotalCost.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </td>
                    <td
                        style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        textAlign: "right",
                        }}
                    >
                        {row.TotalCo2.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} Tons 
                    </td>
                    </tr>
                ))}
                <tr>
                    <td
                    style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        fontWeight: "bold",
                        backgroundColor: "#f9f9f9",
                    }}
                    colSpan={2}
                    >
                    Total
                    </td>
                    <td
                    style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        fontWeight: "bold",
                        backgroundColor: "#f9f9f9",
                        textAlign: "right",
                    }}
                    >
                    {totalKWhr.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </td>
                    <td
                    style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        fontWeight: "bold",
                        backgroundColor: "#f9f9f9",
                        textAlign: "right",
                    }}
                    >
                    Rs {totalCost.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </td>
                    <td
                    style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        fontWeight: "bold",
                        backgroundColor: "#f9f9f9",
                        textAlign: "right",
                    }}
                    >
                    {totalCo2.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} Tons
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div
        className="card-container"
        style={{
            border: "1px solid #ccc",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
            backgroundColor: "#fff",
            marginTop: "30px",
            maxWidth: "1000px",
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: '20px',
            padding: "10px",
            borderRadius: "10px",
        }}
        >
            <table
            className="sub-meters-table"
            style={{
                width: "100%",
                borderCollapse: "collapse",
                textAlign: "center",
            }}
            >
            <thead>
                <tr>
                <th
                    style={{
                    border: "1px solid #ddd",
                    padding: "8px",
                    backgroundColor: "#f4f4f4",
                    }}
                >
                    S.NO
                </th>
                <th
                    style={{
                    border: "1px solid #ddd",
                    padding: "8px",
                    backgroundColor: "#f4f4f4",
                    }}
                >
                    Group Name
                </th>
                <th
                    style={{
                    border: "1px solid #ddd",
                    padding: "8px",
                    backgroundColor: "#f4f4f4",
                    }}
                >
                    Total Meters
                </th>
                <th
                    style={{
                    border: "1px solid #ddd",
                    padding: "8px",
                    backgroundColor: "#f4f4f4",
                    }}
                >
                    Total kWh
                </th>
                <th
                    style={{
                    border: "1px solid #ddd",
                    padding: "8px",
                    backgroundColor: "#f4f4f4",
                    }}
                >
                    Total Cost
                </th>
                </tr>
            </thead>
            <tbody>
  {currentMachines.map((group, index) => (
    <tr
      key={index}
      onClick={() => handleMeterClick(group.group_name)}
      style={{ cursor: 'pointer' }}
    >
      <td
        style={{
          border: "1px solid #ddd",
          padding: "8px",
        }}
      >
        {indexOfFirstMachine + index + 1}
      </td>
      <td
        style={{
          border: "1px solid #ddd",
          padding: "8px",
        }}
      >
        {group.group_name}
      </td>
      <td
        style={{
          border: "1px solid #ddd",
          padding: "8px",
        }}
      >
        {group.meter_count}
      </td>
      <td
        style={{
          border: "1px solid #ddd",
          padding: "8px",
          textAlign: "right",
        }}
      >
        {group.total_kWh.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
      </td>
      <td
        style={{
          border: "1px solid #ddd",
          padding: "8px",
          textAlign: "right",
        }}
      >
        Rs {group.total_cost.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
      </td>
    </tr>
  ))}
</tbody>

            <tfoot>
                <tr>
                <td
                    colSpan="3"
                    style={{
                    border: "1px solid #ddd",
                    padding: "8px",
                    fontWeight: "bold",
                    backgroundColor: "#f9f9f9",
                    }}
                >
                    Total
                </td>
                <td
                    style={{
                    border: "1px solid #ddd",
                    padding: "8px",
                    fontWeight: "bold",
                    backgroundColor: "#f9f9f9",
                    textAlign: "right",
                    }}
                >
                    {submachines.reduce((acc, group) => acc + group.total_kWh, 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </td>
                <td
                    style={{
                    border: "1px solid #ddd",
                    padding: "8px",
                    fontWeight: "bold",
                    backgroundColor: "#f9f9f9",
                    textAlign: "right",
                    }}
                >
                    Rs {submachines.reduce((acc, group) => acc + group.total_cost, 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </td>
                </tr>
            </tfoot>
            </table>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <button
                    onClick={() => paginate(currentPage - 1)}
                    disabled={currentPage === 1}
                    style={{
                    marginRight: '10px',
                    padding: '5px 10px',
                    background: currentPage === 1 ? '#007acc' : '#007acc',
                    border: 'none',
                    borderRadius: '25px',
                    color: 'white',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s ease',
                    boxShadow: currentPage === 1 ? 'none' : '0px 4px 8px rgba(0, 0, 0, 0.2)',
                    fontSize: '12px',
                    }}
                >
                    Previous
                </button>

                {Array.from({ length: totalPages }, (_, i) => (
                    <button
                    key={i}
                    onClick={() => paginate(i + 1)}
                    style={{
                        margin: '0 5px',
                        padding: '5px 10px',
                        background: currentPage === i + 1 ? '#007acc' : 'linear-gradient(to right, #f7f7f7, #e0e0e0)',
                        color: currentPage === i + 1 ? '#ffffff' : '#333333',
                        border: '1px solid #ddd',
                        borderRadius: '25px',
                        cursor: 'pointer',
                        fontSize: '12px',
                        transition: 'background-color 0.3s ease',
                    }}
                    >
                    {i + 1}
                    </button>
                ))}

                <button
                    onClick={() => paginate(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    style={{
                    marginLeft: '10px',
                    padding: '5px 10px',
                    background: currentPage === totalPages ? '#007acc' : '#007acc',
                    border: 'none',
                    borderRadius: '25px',
                    color: 'white',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s ease',
                    boxShadow: currentPage === totalPages ? 'none' : '0px 4px 8px rgba(0, 0, 0, 0.2)',
                    fontSize: '12px',
                    }}
                >
                    Next
                </button>
                </div>
        </div>
        <div className="dashboard">
            <div className="main-container">
                <div className="nav-bar">
                {tabs.map((tab) => (
                    <button
                    key={tab}
                    className={activeTab === tab ? "active" : ""}
                    onClick={() => setActiveTab(tab)}
                    >
                    {tab}
                    </button>
                ))}
                </div>
                {activeTab === "Hourly Report" && (
                <div className="hourly-report">
                    <div className="card controls-card">
                    <input
                        type="date"
                        value={selectedreportDate}
                        onChange={(e) => setSelectedreportDate(e.target.value)}
                        placeholder="Select Date"
                    />
                    <button onClick={handleGetHourlyReport}>Get Report</button>
                    <button onClick={() => handleExportToExcel(hourlyData, 'Hourly_Report.xlsx')}>
                        <i className="excel-icon">📊</i> Excel
                    </button>
                    </div>

                    <div className="table-container">
                    <h4 className="table-title">
                        Hourly Log View on {selectedreportDate || "Selected Date"}
                    </h4>
                    <table>
                        <thead>
                        <tr>
                            <th>Date</th>
                            <th>Hour</th>
                            <th>Start WH</th>
                            <th>End WH</th>
                            <th>Total WH</th>
                            <th>Unit Rate</th>
                            <th>Total Cost</th>
                        </tr>
                        </thead>
                        <tbody>
                        {currenthourlyRows.length > 0 ? (
                            currenthourlyRows.map((row, index) => (
                                <tr key={index}>
                                    <td>{row.date}</td>
                                    <td>{row.timeInterval}</td>
                                    <td>{row.startKwh.toFixed(2)}</td>
                                    <td>{row.endKwh.toFixed(2)}</td>
                                    <td>{(row.endKwh - row.startKwh).toFixed(2)}</td>
                                    <td>{row.unitRate.toFixed(2)}</td>
                                    <td>{((row.endKwh - row.startKwh) * row.unitRate).toFixed(2)}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="7" style={{ textAlign: "center" }}>
                                    No Data Available
                                </td>
                            </tr>
                        )}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="4" style={{ textAlign: "right" }}>TOTAL</td>
                            <td>{hourlyTotalKwh.toFixed(2)}</td>
                            <td></td>
                            <td>{hourlyTotalCost.toFixed(2)}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>

            <div className="pagination-controls">
                <button
                    disabled={currenthourlyPage === 1}
                    onClick={() => handlehourlyPageChange(currenthourlyPage - 1)}
                >
                    Previous
                </button>
                <span>  {currenthourlyPage}   of   {totalhourlyPage}  </span>
                <button
                    disabled={currenthourlyPage === totalhourlyPage}
                    onClick={() => handlehourlyPageChange(currenthourlyPage + 1)}
                >
                    Next
                </button>
            </div>
                </div>
                )}
                {activeTab === "Daily Report" && (
                <div className="daily-report">
                    <div className="card controls-card">
                    <input
                        type="date"
                        value={fromDate}
                        onChange={(e) => setFromDate(e.target.value)}
                        placeholder="From Date"
                    />
                    <input
                        type="date"
                        value={toDate}
                        onChange={(e) => setToDate(e.target.value)}
                        placeholder="To Date"
                    />
                    <button onClick={handleGetDailyReport}>Get Report</button>
                    <button onClick={() => handleExportToExcel(dailyreportData, 'Daily_Report.xlsx')}>
                        <i className="excel-icon">📊</i> Excel
                    </button>
                    </div>

                    <div className="table-container">
                    <h4 className="table-title">
                        Daily Log View from {fromDate} to {toDate}
                    </h4>
                    <table>
                        <thead>
                        <tr>
                            <th>Date</th>
                            <th>From Date</th>
                            <th>To Date</th>
                            <th>Start WH</th>
                            <th>End WH</th>
                            <th>Total WH</th>
                            {/* <th>Unit Rate</th> */}
                            <th>Total Cost</th>
                        </tr>
                        </thead>
                        <tbody>
                            {dailyreportData.length > 0 ? (
                                dailyreportData.map((row, index) => (
                                    <tr key={index}>
                                        <td>{row.date}</td>
                                        <td>{row.fromDate}</td>
                                        <td>{row.toDate}</td>
                                        <td>{row.startKwh.toFixed(2)}</td>
                                        <td>{row.endKwh.toFixed(2)}</td>
                                        <td>{(row.endKwh - row.startKwh).toFixed(2)}</td>
                                        {/* <td>{row.unitRate.toFixed(2)}</td> */}
                                        <td>
                                            {((row.endKwh - row.startKwh) * row.unitRate).toFixed(2)}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="7" style={{ textAlign: "center" }}>
                                        No Data Available
                                    </td>
                                </tr>
                            )}
                        </tbody>
                        <tfoot>
                        <tr>
                            <td colSpan="4" style={{ textAlign: "right" }}>TOTAL</td>
                            <td>{dailyTotalKwh.toFixed(2)}</td>
                            <td></td>
                            <td>{dailyTotalCost.toFixed(2)}</td>
                        </tr>
                        </tfoot>
                    </table>
                    </div>
                </div>
                )}
                {activeTab === "Log Report" && (
                <div className="log-report">
                    <div className="card controls-card">
                    <input
                        type="date"
                        value={selectedreportDate}
                        onChange={(e) => setSelectedreportDate(e.target.value)}
                        placeholder="Select Date"
                    />
                    <button onClick={handleGetLogReport}>Get Report</button>
                    <button onClick={() => handleExportToExcel(logData, 'Meter_Log_Report.xlsx')}>
                        <i className="excel-icon">📊</i> Excel
                    </button>
                    </div>

                    <div className="table-container">
                    <h4 className="table-title">
                        Meter Log View on {selectedreportDate || "Selected Date"}
                    </h4>
                    <div className="table-wrapper">
                        <table>
                            <thead>
                                <tr>
                                    <th>DateTime</th>
                                    <th>VR</th>
                                    <th>VY</th>
                                    <th>VB</th>
                                    <th>VRY</th>
                                    <th>VYB</th>
                                    <th>VBR</th>
                                    <th>IR</th>
                                    <th>IY</th>
                                    <th>IB</th>
                                    <th>KVA</th>
                                    <th>KW</th>
                                    <th>KVAR</th>
                                    <th>PF</th>
                                    <th>KWH</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentRows.length > 0 ? (
                                    currentRows.map((row, index) => (
                                        <tr key={index}>
                                            <td>{row.datetime}</td>
                                            <td>{row.VR}</td>
                                            <td>{row.VY}</td>
                                            <td>{row.VB}</td>
                                            <td>{row.VRY}</td>
                                            <td>{row.VYB}</td>
                                            <td>{row.VBR}</td>
                                            <td>{row.IR}</td>
                                            <td>{row.IY}</td>
                                            <td>{row.IB}</td>
                                            <td>{row.KVA}</td>
                                            <td>{row.KW}</td>
                                            <td>{row.KVAR}</td>
                                            <td>{row.PF}</td>
                                            <td>{row.KWH}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="15" style={{ textAlign: "center" }}>
                                            No Data Available
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan="14" style={{ textAlign: "right" }}>TOTAL</td>
                                    <td>{logTotalKwh.toFixed(2)}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>

                    <div className="pagination-controls">
                        <button
                            disabled={currentreportPage === 1}
                            onClick={() => handlePageChange(currentreportPage - 1)}
                        >
                            Previous
                        </button>
                        <span>  {currentreportPage}   of   {totalreportPage}  </span>
                        <button
                            disabled={currentreportPage === totalreportPage}
                            onClick={() => handlePageChange(currentreportPage + 1)}
                        >
                            Next
                        </button>
                    </div>
                </div>
                )}

        {activeTab === "Historical Trend" && (
        <div className="historical-trend">
            <div className="card controls-card">
            <select
                value={historicalTrend}
                onChange={handleHistoricalTrendChange}
            >
                <option>Select Parameters</option>
                <option value="voltage_phase_to_phase">Voltage Phase to Phase</option>
                <option value="voltage_phase_to_neutral">Voltage Phase to Neutral</option>
                <option value="frequency">Frequency</option>
                <option value="current">Current</option>
                <option value="current_harmonics">Current Harmonics</option>
                <option value="voltage_harmonics">Voltage Harmonics</option>
                <option value="power_factor">PF</option>
                <option value="KWH">KWH</option>
                <option value="kvah">KVAH</option>
                <option value="kw">KW</option>
                <option value="va">VA</option>
                <option value="power">Power</option>
            </select>
            <input
                type="date"
                value={selectedreportDate}
                onChange={(e) => setSelectedreportDate(e.target.value)}
                placeholder="Select Date"
            />
            <button onClick={handleSubmit}>Submit</button>
            </div>

            <div className="trend-container">
            <h3 className="trend-title">{historicalTrend} Trend Report</h3>
            <HighchartsReact
                highcharts={Highcharts}
                constructorType={"stockChart"}
                options={options}
            />
            </div>
            </div>
        )}
        </div>
        </div>
        </div>
        </div>
        
    );
};

export default Emsmain;
