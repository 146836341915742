import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BaseURL from '../assets/contants/BaseURL';
import Header from '../components/header';

const PeakTimings = () => {
  const [timings, setTimings] = useState([]);
  const [editing, setEditing] = useState(null);
  const [updatedRate, setUpdatedRate] = useState('');
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchPeakTimings = async () => {
      try {
        const response = await axios.get(`${BaseURL}meters/peaktimings/`);
        const sortedTimings = response.data.sort((a, b) => {
          const slotOrder = {
            'T1': 1, 'T2': 2, 'T3': 3, 'T4': 4, 'T5': 5
          };
          return slotOrder[a.slots] - slotOrder[b.slots];
        });
        setTimings(sortedTimings);
      } catch (error) {
        console.error('Error fetching peak timings:', error);
      }
    };
    fetchPeakTimings();
  }, []);

  // const handleDelete = async (id) => {
  //   const confirmDelete = window.confirm('Are you sure you want to delete this peak timing?');
  //   if (!confirmDelete) return;

  //   try {
  //     await axios.delete(`${BaseURL}meters/peaktimings/${id}/`);
  //     setTimings(timings.filter(timing => timing.id !== id));
  //   } catch (error) {
  //     console.error('Error deleting peak timing:', error);
  //   }
  // };

  const handleUpdate = async (id) => {
    try {
      const updatedData = {
        slots: timings.find(timing => timing.id === id)?.slots,
        timing: timings.find(timing => timing.id === id)?.timing,
        rate: updatedRate,
      };
      await axios.put(`${BaseURL}meters/peaktimings/${id}/`, updatedData);
      setTimings(timings.map(timing =>
        timing.id === id ? { ...timing, rate: updatedRate } : timing
      ));
      setShowModal(false);
    } catch (error) {
      console.error('Error updating peak timing:', error);
    }
  };

  const openModal = (timing) => {
    setEditing(timing.id);
    setUpdatedRate(timing.rate);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setEditing(null);
  };

  return (
    <div style={{ textAlign: 'center', width: '100%' }}>
    <div style={{
    backgroundColor: '#007acc',
    color: 'white',
    alignContent: 'center',
    fontSize: '14px',
    height:'40px'
    }}>
    <Header title="PEAK TIMINGS" />
    </div>

      <div style={{
        border: '1px solid #ccc',
        padding: '10px',
        marginTop: '20px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
        backgroundColor: 'white',
        margin: '20px auto',
        maxWidth: '1000px',
      }}>
        <table style={{ width: '100%', textAlign: 'left', borderCollapse: 'collapse' }}>
          <thead style={{ backgroundColor: '#007acc', color: 'white' }}>
            <tr>
              <th style={{ padding: '10px' }}>S.No</th>
              <th style={{ padding: '10px' }}>Slot</th>
              <th style={{ padding: '10px' }}>Timing</th>
              <th style={{ padding: '10px' }}>Rate</th>
              <th style={{ padding: '10px' }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {timings.length > 0 ? (
              timings.map((timing, index) => (
                <tr
                  key={timing.id}
                  style={{
                    backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#ffffff'
                  }}
                >
                  <td style={{ padding: '10px' }}>{index + 1}</td>
                  <td style={{ padding: '10px' }}>{timing.slots}</td>
                  <td style={{ padding: '10px' }}>{timing.timing}</td>
                  <td style={{ padding: '10px' }}>{timing.rate}</td>
                  <td
                style={{ padding: "10px", color: "blue", cursor: "pointer" }}
                onClick={() => openModal(timing)}
              >
                <FontAwesomeIcon icon={faEdit} />
        
                    {/* <button
                      style={{
                        backgroundColor: '#ff4d4d',
                        color: 'white',
                        border: 'none',
                        borderRadius: '5px',
                        padding: '5px 10px',
                      }}
                      onClick={() => handleDelete(timing.id)}
                    >
                      <FaTrash />
                    </button> */}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" style={{ textAlign: 'center', padding: '10px' }}>No peak timings found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {showModal && (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
        }}>
            <div style={{
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '12px',
            width: '400px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            textAlign: 'center',
            transition: 'transform 0.3s ease-in-out',
            transform: 'scale(1)',
            }}>
            <h3 style={{
                marginBottom: '20px',
                fontSize: '20px',
                fontWeight: '600',
            }}>Edit Peak Timing</h3>

            <div style={{
                marginBottom: '15px',
                textAlign: 'left',
            }}>
                <label style={{
                fontSize: '14px',
                fontWeight: '500',
                marginBottom: '5px',
                display: 'block',
                color: '#555',
                }}>Slot</label>
                <div style={{
                    padding: '10px',
                    fontSize: '16px',
                    borderRadius: '8px',
                    backgroundColor: '#f2f2f2',
                    color: '#555',
                }}>
                    {timings.find(timing => timing.id === editing)?.slots}
                </div>
            </div>

            <div style={{
                marginBottom: '15px',
                textAlign: 'left',
            }}>
                <label style={{
                fontSize: '14px',
                fontWeight: '500',
                marginBottom: '5px',
                display: 'block',
                color: '#555',
                }}>Timing</label>
                <div style={{
                    padding: '10px',
                    fontSize: '16px',
                    borderRadius: '8px',
                    backgroundColor: '#f2f2f2',
                    color: '#555',
                }}>
                    {timings.find(timing => timing.id === editing)?.timing}
                </div>
            </div>

            <div style={{
                marginBottom: '15px',
                textAlign: 'left',
            }}>
                <label style={{
                fontSize: '14px',
                fontWeight: '500',
                marginBottom: '5px',
                display: 'block',
                color: '#555',
                }}>Rate</label>
                <input
                type="text"
                value={updatedRate}
                onChange={(e) => setUpdatedRate(e.target.value)}
                style={{
                    width: '95%',
                    padding: '10px',
                    fontSize: '16px',
                    borderRadius: '8px',
                    border: '1px solid #ddd',
                    outline: 'none',
                    transition: 'border-color 0.3s ease-in-out',
                }}
                />
            </div>

            <div style={{
                marginTop: '20px',
                display: 'flex',
                justifyContent: 'space-between', 
            }}>
                <button
                onClick={() => handleUpdate(editing)}
                style={{
                  backgroundColor: '#4CAF50',
                    color: 'white',
                    border: 'none',
                    padding: '12px 25px',
                    borderRadius: '8px',
                    cursor: 'pointer',
                    fontSize: '16px',
                    transition: 'background-color 0.3s',
                    width: '48%',
                }}
                >
                Save
                </button>
                <button
                onClick={closeModal}
                style={{
                    backgroundColor: '#ff4d4d',
                    color: 'white',
                    border: 'none',
                    padding: '12px 25px',
                    borderRadius: '8px',
                    cursor: 'pointer',
                    fontSize: '16px',
                    transition: 'background-color 0.3s',
                    width: '48%',
                }}
                onMouseOver={(e) => e.target.style.backgroundColor = '#e04040'}
                onMouseOut={(e) => e.target.style.backgroundColor = '#ff4d4d'}
                >
                Cancel
                </button>
            </div>
            </div>
        </div>
        )}
    </div>
  );
};

export default PeakTimings;
