import React, { useState } from 'react';
import BaseURL from '../assets/contants/BaseURL';
import { useNavigate } from 'react-router-dom';

const RegisterPage = () => {
const navigate = useNavigate();
  const [formData, setFormData] = useState({
    company_name: '',      
    address_line_1: '',    
    address_line_2: '',   
    password: '',
    confirm_password: '',  
    name: '',     
    mobile_no: '',          
    email: '',              
    website: '',
    gst_no: '',            
  });

  const [setErrors] = useState({});

  const containerStyle = {
    maxWidth: '600px',
    margin: '50px auto',
    padding: '20px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    boxShadow: '0 2px 15px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    backgroundColor: '#fff',
  };

  const headerStyle = {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#0073e6',
    marginBottom: '20px',
  };

  const inputStyle = {
    width: '90%',
    padding: '12px',
    margin: '10px auto',
    border: '1px solid #ccc',
    borderRadius: '4px',
    fontSize: '16px',
    outline: 'none',
  };

  const submitBtnStyle = {
    width: '100%',
    padding: '12px',
    backgroundColor: '#f0c14b',
    color: '#111',
    border: '1px solid #a88734',
    borderRadius: '4px',
    fontSize: '18px',
    fontWeight: 'bold',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    marginTop: '10px',
  };

  const formContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: '20px',
  };

  const backgroundStyle = {
    backgroundImage: 'url("https://images.unsplash.com/photo-1517336714731-489689fd1ca8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8MTY0MjY1Mzg4Ng&ixlib=rb-1.2.1&q=80&w=1080")',
    backgroundSize: 'cover',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    
    const response = await fetch(`${BaseURL}Companyauth/company/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    const data = await response.json();

    if (response.ok) {
      console.log('Registration successful:', data);
      navigate('/verification'); 

    } else {
      setErrors(data);
      console.log('Registration failed:', data);
    }
  };

  return (
    <div style={backgroundStyle}>
      <div style={containerStyle}>
        <h2 style={headerStyle}>Register</h2>
        <form onSubmit={handleRegister} style={{ display: 'block' }}>
          <div style={formContainerStyle}>
            <div style={{ flex: '1 1 48%' }}>
              <input 
                type="text" 
                name="company_name"  
                placeholder="Company Name" 
                style={inputStyle} 
                value={formData.company_name} 
                onChange={handleChange} 
                required 
              />
              <input 
                type="text" 
                name="address_line_1" 
                placeholder="Address Line 1" 
                style={inputStyle} 
                value={formData.address_line_1} 
                onChange={handleChange} 
                required 
              />
              <input 
                type="text" 
                name="address_line_2"  
                placeholder="Address Line 2" 
                style={inputStyle} 
                value={formData.address_line_2} 
                onChange={handleChange} 
              />
              <input 
                type="password" 
                name="password" 
                placeholder="Password" 
                style={inputStyle} 
                value={formData.password} 
                onChange={handleChange} 
                required 
              />
              <input 
                type="password" 
                name="confirm_password" 
                placeholder="Confirm Password" 
                style={inputStyle} 
                value={formData.confirm_password} 
                onChange={handleChange} 
                required 
              />
            </div>
            <div style={{ flex: '1 1 48%' }}>
              <input 
                type="text" 
                name="name"  
                placeholder="Contact Person" 
                style={inputStyle} 
                value={formData.name} 
                onChange={handleChange} 
                required 
              />
              <input 
                type="tel" 
                name="mobile_no"  
                placeholder="Contact Phone" 
                style={inputStyle} 
                value={formData.mobile_no} 
                onChange={handleChange} 
                required 
              />
              <input 
                type="email" 
                name="email"  
                placeholder="Contact Email" 
                style={inputStyle} 
                value={formData.email} 
                onChange={handleChange} 
                required 
              />
              <input 
                type="text" 
                name="website" 
                placeholder="Website (optional)" 
                style={inputStyle} 
                value={formData.website} 
                onChange={handleChange} 
              />
              <input 
                type="text" 
                name="gst_no"  
                placeholder="GST No (optional)" 
                style={inputStyle} 
                value={formData.gst_no} 
                onChange={handleChange} 
              />
            </div>
          </div>
          <button type="submit" style={submitBtnStyle}>Register</button>
          {/* {Object.keys(errors).length > 0 && (
            <div style={{ color: 'red', marginTop: '10px' }}>
              {Object.entries(errors).map(([key, messages]) => (
                <div key={key}>{messages.join(', ')}</div>
              ))}
            </div>
          )} */}
        </form>
      </div>
    </div>
  );
};

export default RegisterPage;
