import React, { useState, useEffect } from 'react';
import '../scss/Emsmain.scss';
import Chart from 'react-apexcharts';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from '../components/header'
import BaseURL from '../assets/contants/BaseURL';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; 
import axios from 'axios';
import { Pie } from 'react-chartjs-2';

const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];

const EmsSubGroup = () => {
    const location = useLocation();
    const { group_name } = location.state || {};
    const [selectedDate, setSelectedDate] = useState(new Date());
    const navigate = useNavigate();
    const [widgetData, setWidgetData] = useState({
        today: [0, 0, 0],
        yesterday: [0, 0, 0],
        currentMonth: [0, 0, 0],
        lastMonth: [0, 0, 0],
    });
    const [submachines, setSubmachines] = useState([]);
    const [costs, setCosts] = useState([]);
    const [totalCost, setTotalCost] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [chartDatas, setChartDatas] = useState({
        kWh: [],
        cost: [],
        co2: []
    });
    const [categorie, setCategorie] = useState([]);
    const [selectedNewYear, setSelectedNewYear] = useState(new Date().getFullYear());
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [timeselectedDate, settimeSelectedDate] = useState(new Date());
    const [monthlyData, setMonthlyData] = useState({
        categories: monthNames,
        kWh: Array(12).fill(0),
        cost: Array(12).fill(0),
        co2: Array(12).fill(0),
    });

    const [dayData, setDayData] = useState({
      categories: [],
      kWh: [],
      cost: [],
      co2: [],
    });

    const [chartsData, setChartsData] = useState({
        options: {
            chart: {
                id: "timeslot-chart",
                toolbar: {
                    tools: {
                        download: false,
                    },
                },
            },
            xaxis: {
                categories: [],
            },
            dataLabels: {
                enabled: false,
            },
        },
        series: [
            {
                name: "Today's kWhr",
                data: [],
            },
            {
                name: "Total Cost",
                data: [],
            },
            {
                name: "CO₂",
                data: [],
            },
        ],
    });

    const handleTimeDateChange = (date) => {
        settimeSelectedDate(date);
    };




    useEffect(() => {
        const fetchData = async () => {
        try {
            const today = new Date().toISOString().split('T')[0];
            const response = await axios.post(`${BaseURL}/data/meter-table/`, {
            group_name: group_name,
            date: today
            });
            
            const fetchedData = response.data;
            const formattedSubmachines = fetchedData.meter_name.map((meter, index) => ({
            meter_name: meter,
            meter_id: fetchedData.meter_id[index],
            group_name: fetchedData.group_name,
            total_kWh: fetchedData.todays_kwh[index],
            total_cost: fetchedData.cost[index],
            }));
            const totalCost = fetchedData.cost.reduce((total, cost) => total + cost, 0);
            setSubmachines(formattedSubmachines);
            setCosts(fetchedData.cost);
            setTotalCost(totalCost);
        } catch (error) {
            console.error('Error fetching meter data:', error);
        }
        };

        fetchData();
    }, [group_name]);

    useEffect(() => {
        const today = new Date().toISOString().split('T')[0];
        const fetchData = async () => {
            try {
                const response = await axios.post(`${BaseURL}/data/meter-summary/`, {
                    date: today,
                    group_name: group_name,
                });
                setWidgetData({
                    today: response.data.today,
                    yesterday: response.data.yesterday,
                    currentMonth: response.data.current_month,
                    lastMonth: response.data.last_month,
                });
            } catch (error) {
                console.error('Error fetching meter summary data:', error);
            }
        };
        fetchData();
    }, [group_name]);

    useEffect(() => {
        const fetchData = async () => {
            const formattedDate = selectedDate.toISOString().split('T')[0];
            try {
                const response = await axios.post(`${BaseURL}data/hourly-meter-consumption/`, {
                    group_name: group_name,
                    date: formattedDate,
                });
    
                const hourlyData = response.data.hourly_data || {};
                const categories = Object.keys(hourlyData);
    
                const kWhData = [];
                const costData = [];
                const co2Data = [];
                categories.forEach((time) => {
                    const [kWh, cost, co2] = hourlyData[time];
                    kWhData.push(kWh || 0);
                    costData.push(cost || 0);
                    co2Data.push(co2 || 0);
                });
    
                setCategorie(categories);
                setChartDatas({
                    kWh: kWhData,
                    cost: costData,
                    co2: co2Data,
                });
            } catch (error) {
                console.error("Error fetching hourly data:", error);
            }
        };
    
        fetchData();
    }, [selectedDate, group_name]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post(`${BaseURL}data/monthly-meter-consumption/`, {
                    group_name: group_name,
                    year: selectedNewYear
                });
                const data = response.data.monthly_data;
                const kWhData = [];
                const costData = [];
                const co2Data = [];
    
                Object.keys(data).forEach(month => {
                    const [kWh, cost, co2] = data[month];
                    kWhData.push(kWh);
                    costData.push(cost);
                    co2Data.push(co2);
                });
    
                setMonthlyData({
                    categories: monthNames,
                    kWh: kWhData,
                    cost: costData,
                    co2: co2Data
                });
    
            } catch (error) {
                console.error("Error fetching hourly data:", error);
            }
        };
        fetchData();
        const intervalId = setInterval(() => {
            fetchData();
        },  30000);
        return () => clearInterval(intervalId);
    }, [selectedNewYear, group_name]);

    const handleMonthChange = (e) => setSelectedMonth(parseInt(e.target.value));
    const handleYearChange = (e) => setSelectedYear(parseInt(e.target.value));

        const handleMeterClick = (meterId, groupName) => {
            navigate("/EMS/emssub", { state: { meterId, groupName } });
            window.scrollTo(0, 0);
            console.log(meterId, groupName)
          };
    
          const costPercentagesForPie = costs.map((cost) => {
            const percentage = (cost / totalCost) * 100;
            return percentage > 0 ? percentage : 0.001;
        });
    
        const getColorForMachine = (index) => {
            const colors = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'];
            return colors[index % colors.length];
        };
    
        const pieChartData = {
            labels: submachines.map((machine) => machine.meter_name),
            datasets: [
                {
                    data: costPercentagesForPie,
                    backgroundColor: submachines.map((_, index) => getColorForMachine(index)),
                },
            ],
        };
    
        const indexOfLastMachine = currentPage * itemsPerPage;
        const indexOfFirstMachine = indexOfLastMachine - itemsPerPage;
        const currentMachines = submachines.slice(indexOfFirstMachine, indexOfLastMachine);

        const totalPages = Math.ceil(submachines.length / itemsPerPage);
        const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className="dashboard-container">
           <Header title="DASHBOARD" />

            <div className="card-container" style={{
                border: '1px solid #ccc', 
                borderRadius: '10px', 
                padding: '20px', 
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', 
                backgroundColor: '#fff',
                marginTop: '70px',
                maxWidth: '1000px',
                marginLeft: 'auto',
                marginRight: 'auto'
            }}>
                <h3 className="dashboard-subtitle"><span onClick={() => navigate('/EMS/dashboard')}style={{color: '#007bff',cursor: 'pointer'}}title="Navigate to Dashboard">MAIN</span> {' / '} {group_name}</h3>
                <div className="widgets-container" style={{ display: 'grid',  gap: '20px', marginBottom: '20px' }}>
                <div className="widget-container" style={{ width: '100%' }}>
                        <div className="widget" style={{ padding: '10px', border: '1px solid #ccc', borderRadius: '8px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)' }}>
                            <h3>Total No of Meters</h3>
                            <div style={{ display: 'table', width: '100%', height: '30px' }}>
                                <div style={{ display: 'table-row' }}>
                                    <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'center', marginBottom: '20px' }}>{submachines.length}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="widget-container" style={{ width: '100%' }}>
                        <div className="widget" style={{ padding: '10px', border: '1px solid #ccc', background: 'linear-gradient(145deg, #4caf50, #2b9e36)', borderRadius: '8px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)' }}>
                            <h3>Yesterday</h3>
                            <div style={{ display: 'table', width: '100%' }}>
                                <div style={{ display: 'table-row' }}>
                                    <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '15px'  }}>Units</p>
                                    <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p>
                                    <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}>{widgetData.yesterday[0]} kWh</p>
                                </div>
                                <div style={{ display: 'table-row' }}>
                                    <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '15px'  }}>Cost</p>
                                    <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p>
                                    <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}>Rs {widgetData.yesterday[1]}</p>
                                </div>
                                <div style={{ display: 'table-row' }}>
                                    <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '15px'  }}>Co2</p>
                                    <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p>
                                    <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}>{widgetData.yesterday[2]} Tons</p>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="widget-container" style={{ width: '100%' }}>
                        <div className="widget" style={{ padding: '10px', border: '1px solid #ccc', background: 'linear-gradient(145deg, #2196f3, #0b7dda)', borderRadius: '8px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)' }}>
                            <h3>Current Month</h3>
                            <div style={{ display: 'table', width: '100%' }}>
                                <div style={{ display: 'table-row' }}>
                                    <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '15px'  }}>Units</p>
                                    <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p>
                                    <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}>{widgetData.currentMonth[0]} kWh</p>
                                </div>
                                <div style={{ display: 'table-row' }}>
                                    <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '15px'  }}>Cost</p>
                                    <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p>
                                    <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}>Rs {widgetData.currentMonth[1]}</p>
                                </div>
                                <div style={{ display: 'table-row' }}>
                                    <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '15px'  }}>Co2</p>
                                    <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p>
                                    <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}>{widgetData.currentMonth[2]} Tons</p>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="widget-container" style={{ width: '100%' }}>
                        <div className="widget" style={{ padding: '10px', border: '1px solid #ccc', background: 'linear-gradient(145deg, #f44336, #d32f2f)', borderRadius: '8px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)' }}>
                            <h3>Last Month</h3>
                            <div style={{ display: 'table', width: '100%' }}>
                                <div style={{ display: 'table-row' }}>
                                    <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '15px'  }}>Units</p>
                                    <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p>
                                    <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}>{widgetData.lastMonth[0]} kWh</p>
                                </div>
                                <div style={{ display: 'table-row' }}>
                                    <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '15px'  }}>Cost</p>
                                    <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p>
                                    <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}>Rs {widgetData.lastMonth[1]}</p>
                                </div>
                                <div style={{ display: 'table-row' }}>
                                    <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '15px'  }}>Co2</p>
                                    <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p>
                                    <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}>{widgetData.lastMonth[2]} Tons</p>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
        {/* <div
            className="charts-container"
            style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between', width: '100%' }}
        >
            <div
                className="container-4"
                style={{
                    border: '1px solid #ccc',
                    borderRadius: '10px',
                    padding: '20px',
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                    backgroundColor: '#f9f9f9',
                    marginTop: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                }}
            >
                <h4>Day's Consumption</h4>
                <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: "30px",
                }}
            >
                <label
                    style={{
                        fontSize: "14px",
                        color: "#333",
                        fontWeight: "bold",
                        marginRight: "10px",
                    }}
                >
                    Month:
                </label>
                <select
                    value={selectedMonth}
                    onChange={handleMonthChange}
                    style={{
                        padding: "5px",
                        marginRight: "20px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                    }}
                >
                    {monthNames.map((month, index) => (
                        <option key={index + 1} value={index + 1}>
                            {month}
                        </option>
                    ))}
                </select>

                <label
                    style={{
                        fontSize: "14px",
                        color: "#333",
                        fontWeight: "bold",
                        marginRight: "10px",
                    }}
                >
                    Year:
                </label>
                <select
                    value={selectedYear}
                    onChange={handleYearChange}
                    style={{
                        padding: "5px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                    }}
                >
                    {Array.from({ length: 5 }, (_, i) => new Date().getFullYear() - i).map(year => (
                        <option key={year} value={year}>
                            {year}
                        </option>
                    ))}
                </select>
            </div>
            <Chart
                    options={{
                        chart: {
                            type: 'bar',
                            height: 350,
                            toolbar: {
                                tools: {
                                    zoom: false,
                                    zoomin: false,
                                    zoomout: false,
                                    pan: false,
                                    reset: false,
                                    download: false,
                                },
                            },
                            zoom: {
                                enabled: true,
                            },
                        },
                        plotOptions: {
                            bar: {
                                horizontal: false,
                                columnWidth: '50%',
                                endingShape: 'rounded',
                            },
                        },
                        dataLabels: {
                            enabled: false,
                        },
                        xaxis: {
                            categories: dailyData.categories,
                            title: {
                                text: 'Dates',
                            },
                        },
                        yaxis: {
                            title: {
                                text: 'Value',
                            },
                            labels: {
                                formatter: (value) => {
                                    return new Intl.NumberFormat('en-US').format(value);
                                },
                            },
                        },
                        legend: {
                            position: 'top',
                            horizontalAlign: 'center',
                            offsetX: 0,
                            offsetY: 0,
                        },
                        tooltip: {
                            y: {
                                formatter: function (value, { seriesIndex }) {
                                    if (seriesIndex === 1) {
                                        return `Rs ${value.toLocaleString()}`;
                                    }
                                    return value.toLocaleString();
                                },
                            },
                        },
                    }}
                    series={[
                        {
                            name: 'Wh',
                            data: dailyData.kw,
                        },
                        {
                            name: 'Cost',
                            data: dailyData.cost,
                        },
                        {
                            name: 'CO2',
                            data: dailyData.co2,
                        },
                    ]}
                    type="bar"
                    height={350}
                    width={950}
                />
            </div>
        </div> */}
        {/* <div className="charts-container" style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <div
                className="container-4"
                style={{
                    border: '1px solid #ccc',
                    borderRadius: '10px',
                    padding: '20px',
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                    backgroundColor: '#f9f9f9',
                    marginTop: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                }}
            >
                <h4>Monthly Consumption</h4>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginBottom: "30px",
                    }}
                >
                    <label
                        style={{
                            fontSize: "14px",
                            color: "#333",
                            fontWeight: "bold",
                            marginRight: "10px",
                        }}
                    >
                        Year:
                    </label>
                    <select
                        value={selectedNewYear}
                        onChange={handleNewYearChange}
                        style={{
                            padding: "5px",
                            borderRadius: "5px",
                            border: "1px solid #ccc",
                        }}
                    >
                        {Array.from({ length: 5 }, (_, i) => new Date().getFullYear() - i).map(year => (
                            <option key={year} value={year}>
                                {year}
                            </option>
                        ))}
                    </select>
                </div>
                <Chart
                    options={{
                        chart: {
                            type: 'bar',
                            height: 350,
                            toolbar: {
                                tools: {
                                    zoom: false,
                                    zoomin: false,
                                    zoomout: false,
                                    pan: false,
                                    reset: false,
                                    download: false,
                                },
                            },
                            zoom: {
                                enabled: true,
                            },
                        },
                        plotOptions: {
                            bar: {
                                horizontal: false,
                                columnWidth: '50%',
                                endingShape: 'rounded',
                            },
                        },
                        dataLabels: {
                            enabled: false,
                        },
                        xaxis: {
                            categories: monthlyData.categories,
                            title: {
                                text: 'Months',
                            },
                        },
                        yaxis: {
                            title: {
                                text: 'Value',
                            },
                            labels: {
                                formatter: (value) => {
                                    return new Intl.NumberFormat('en-US').format(value);
                                },
                            },
                        },
                        legend: {
                            position: 'top',
                            horizontalAlign: 'center',
                            offsetX: 0,
                            offsetY: 0,
                        },
                        tooltip: {
                            y: {
                                formatter: function (value, { seriesIndex }) {
                                    if (seriesIndex === 1) {
                                        return `Rs ${value.toLocaleString()}`;
                                    }
                                    return value.toLocaleString();
                                },
                            },
                        },
                    }}
                    series={[
                        {
                            name: 'Wh',
                            data: monthlyData.kWh,
                        },
                        {
                            name: 'Cost',
                            data: monthlyData.cost,
                        },
                        {
                            name: 'CO2',
                            data: monthlyData.co2,
                        },
                    ]}
                    type="bar"
                    height={350}
                    width={950}
                />
            </div>
        </div> */}
        {/* <div
            style={{
                width: "96%",
                margin: "20px auto",
                padding: "20px",
                border: "1px solid #ccc",
                borderRadius: "10px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#fff",
            }}
            >
            <h4>
                Time Slots
            </h4>

            <div
        style={{
            flexDirection: "row",
            alignItems: "center",
            marginBottom: "30px",
            marginLeft: "150px"
        }}
      >
       
        <label
          style={{
            fontSize: "14px",
            color: "#333",
            marginBottom: "5px", 
            fontWeight: "bold",
          }}
        >
         Date:
        </label>
        <DatePicker
          selected={timeselectedDate}
          onChange={handleTimeDateChange}
          dateFormat="yyyy/MM/dd"
          customInput={
            <input
              style={{
                fontSize: "12px",
                width:'35%',
                marginRight:'140px'
              }}
            />
          }
        />
      </div>

            <div
                style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "30px",
                }}
                
            >
                <Chart
                    options={chartsData.options}
                    series={chartsData.series}
                    type="bar"
                    height={350}
                    width={800}
                />
            </div>

            <table
                style={{
                width: "100%",
                borderCollapse: "collapse",
                textAlign: "center",
                }}
            >
                <thead>
                <tr>
                    <th
                    style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        backgroundColor: "#f4f4f4",
                    }}
                    >
                    Timings
                    </th>
                    <th
                    style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        backgroundColor: "#f4f4f4",
                    }}
                    >
                    Rate
                    </th>
                    <th
                    style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        backgroundColor: "#f4f4f4",
                    }}
                    >
                    Today's Wh
                    </th>
                    <th
                    style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        backgroundColor: "#f4f4f4",
                    }}
                    >
                    Total Cost
                    </th>
                </tr>
                </thead>
                <tbody>
                {tableData.map((row, index) => (
                    <tr key={index}>
                    <td
                        style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        }}
                    >
                        {row.Timings}
                    </td>
                    <td
                        style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        }}
                    >
                        Rs {row.Rate.toLocaleString()}
                    </td>
                    <td
                        style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        }}
                    >
                        {row.TodaysKWhr.toLocaleString()}
                    </td>
                    <td
                        style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        }}
                    >
                        Rs {row.TotalCost.toLocaleString()}
                    </td>
                    </tr>
                ))}
                <tr>
                    <td
                    style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        fontWeight: "bold",
                        backgroundColor: "#f9f9f9",
                    }}
                    colSpan={2}
                    >
                    Total
                    </td>
                    <td
                    style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        fontWeight: "bold",
                        backgroundColor: "#f9f9f9",
                    }}
                    >
                    {totalKWhr.toLocaleString()}
                    </td>
                    <td
                    style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        fontWeight: "bold",
                        backgroundColor: "#f9f9f9",
                    }}
                    >
                    Rs {totalCost.toLocaleString()}
                    </td>
                </tr>
                </tbody>
            </table>
        </div> */}
        <div
  className="card-container"
  style={{
    border: "1px solid #ccc",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fff",
    marginTop: "30px",
    maxWidth: "1000px",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: '20px',
    padding: "10px",
    borderRadius: "10px",
  }}
>
<div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginBottom: '20px',
            }}>
                <div style={{
                    width: '300px',
                    height: '300px',
                    marginBottom: '20px',
                }}>
                    <Pie
                        data={pieChartData}
                        options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            plugins: {
                                legend: {
                                    display: false,
                                    position: 'bottom',
                                },
                                tooltip: {
                                    callbacks: {
                                        label: function (tooltipItem) {
                                            const value = tooltipItem.raw.toFixed(2);
                                            return `${tooltipItem.label}: ${value}%`;
                                        },
                                    },
                                },
                            },
                        }}
                    />
                </div>
            </div>
  <table
    className="sub-meters-table"
    style={{
      width: "100%",
      borderCollapse: "collapse",
      textAlign: "center",
    }}
  >
    <thead>
      <tr>
        <th
          style={{
            border: "1px solid #ddd",
            padding: "8px",
            backgroundColor: "#f4f4f4",
          }}
        >
          S.NO
        </th>
        <th
          style={{
            border: "1px solid #ddd",
            padding: "8px",
            backgroundColor: "#f4f4f4",
          }}
        >
          Meter Name
        </th>
        <th
          style={{
            border: "1px solid #ddd",
            padding: "8px",
            backgroundColor: "#f4f4f4",
          }}
        >
          Meter ID
        </th>
        <th
          style={{
            border: "1px solid #ddd",
            padding: "8px",
            backgroundColor: "#f4f4f4",
          }}
        >
          Group
        </th>
        <th
          style={{
            border: "1px solid #ddd",
            padding: "8px",
            backgroundColor: "#f4f4f4",
          }}
        >
          Today's KWhr
        </th>
        <th
          style={{
            border: "1px solid #ddd",
            padding: "8px",
            backgroundColor: "#f4f4f4",
          }}
        >
          Total Cost
        </th>
        <th style={{
            border: "1px solid #ddd",
            padding: "8px",
            backgroundColor: "#f4f4f4",
            }}
        >
           Cost %
        </th>
      </tr>
    </thead>
    <tbody>
      {currentMachines.map((submachine, index) => (
        <tr
          key={submachine.meter_id}
          style={{ cursor: 'pointer' }}
          onClick={() => handleMeterClick(submachine.meter_id, submachine.group_name)}
        >
          <td
            style={{
              border: "1px solid #ddd",
              padding: "8px",
            }}
          >
            {index + 1}
          </td>
          <td
            style={{
              border: "1px solid #ddd",
              padding: "8px",
            }}
          >
            {submachine.meter_name}
          </td>
          <td
            style={{
              border: "1px solid #ddd",
              padding: "8px",
            }}
          >
            {submachine.meter_id}
          </td>
          <td
            style={{
              border: "1px solid #ddd",
              padding: "8px",
            }}
          >
            {submachine.group_name}
          </td>
          <td
            style={{
              border: "1px solid #ddd",
              padding: "8px",
              textAlign: "right",
            }}
          >
            {submachine.total_kWh.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
          </td>
          <td
            style={{
              border: "1px solid #ddd",
              padding: "8px",
              textAlign: "right",
            }}
          >
            Rs {submachine.total_cost.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
          </td>
          <td style={{ border: "1px solid #ddd", padding: "8px", textAlign: "center" }}>
            <div
                style={{
                width: '20px',
                height: '20px',
                backgroundColor: getColorForMachine(index),
                borderRadius: '50%',
                display: 'inline-block',
                marginRight: '8px',
                }}
            ></div>
            <span>{costPercentagesForPie[index + indexOfFirstMachine].toFixed(2)}%</span>
           </td>
        </tr>
      ))}
    </tbody>
    <tfoot>
      <tr>
        <td
          colSpan="4"
          style={{
            border: "1px solid #ddd",
            padding: "8px",
            fontWeight: "bold",
            backgroundColor: "#f9f9f9",
          }}
        >
          Total
        </td>
        <td
          style={{
            border: "1px solid #ddd",
            padding: "8px",
            fontWeight: "bold",
            backgroundColor: "#f9f9f9",
            textAlign: "right",
          }}
        >
          {submachines.reduce((total, submachine) => total + submachine.total_kWh, 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
        </td>
        <td
          style={{
            border: "1px solid #ddd",
            padding: "8px",
            fontWeight: "bold",
            backgroundColor: "#f9f9f9",
            textAlign: "right",
          }}
        >
          Rs {submachines.reduce((total, submachine) => total + submachine.total_cost, 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
        </td>
        <td
          style={{
            border: "1px solid #ddd",
            padding: "8px",
            fontWeight: "bold",
            backgroundColor: "#f9f9f9",
            textAlign: "right",
          }}
        >
         
        </td>
      </tr>
    </tfoot>
  </table>
  <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
    <button
      onClick={() => paginate(currentPage - 1)}
      disabled={currentPage === 1}
      style={{
        marginRight: '10px',
        padding: '5px 10px',
        background: currentPage === 1 ? '#007acc' : '#007acc',
        border: 'none',
        borderRadius: '25px',
        color: 'white',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
        boxShadow: currentPage === 1 ? 'none' : '0px 4px 8px rgba(0, 0, 0, 0.2)',
        fontSize: '12px',
      }}
    >
      Previous
    </button>

    {Array.from({ length: totalPages }, (_, i) => (
      <button
        key={i}
        onClick={() => paginate(i + 1)}
        style={{
          margin: '0 5px',
          padding: '5px 10px',
          background: currentPage === i + 1 ? '#007acc' : 'linear-gradient(to right, #f7f7f7, #e0e0e0)',
          color: currentPage === i + 1 ? '#ffffff' : '#333333',
          border: '1px solid #ddd',
          borderRadius: '25px',
          cursor: 'pointer',
          fontSize: '12px',
          transition: 'background-color 0.3s ease',
        }}
      >
        {i + 1}
      </button>
    ))}

    <button
      onClick={() => paginate(currentPage + 1)}
      disabled={currentPage === totalPages}
      style={{
        marginLeft: '10px',
        padding: '5px 10px',
        background: currentPage === totalPages ? '#007acc' : '#007acc',
        border: 'none',
        borderRadius: '25px',
        color: 'white',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
        boxShadow: currentPage === totalPages ? 'none' : '0px 4px 8px rgba(0, 0, 0, 0.2)',
        fontSize: '12px',
      }}
    >
      Next
    </button>
  </div>
</div>
<div
                    className="extra-details-row"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: '20px',
                    }}
                >
                </div>
                <div className="charts-container" style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <div
                className="container-4"
                style={{
                    border: '1px solid #ccc',
                    borderRadius: '10px',
                    padding: '20px',
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                    backgroundColor: '#f9f9f9',
                    marginTop: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                }}
            >
                <h4>Hourly Data</h4>
                <div style={{ marginBottom: "20px" }}>
                    <label
                    htmlFor="date-picker"
                    style={{
                        alignItems: "center",
                        marginBottom: "20px",
                      }}
                    >
                    Date:
                    </label>
                    <DatePicker
                    id="date-picker"
                    selected={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    dateFormat="yyyy/MM/dd"
                    customInput={
                        <input
                        style={{
                            fontSize: "12px",
                            width:'55%',
                        }}
                        />
                    }
                    />
                </div>
                <Chart
                    options={{
                        chart: {
                            type: 'bar',
                            height: 350,
                            toolbar:{
                                tools:{ download:false
                            },
                        },
                        },
                        plotOptions: {
                            bar: {
                                horizontal: false,
                                columnWidth: '50%',
                                endingShape: 'rounded',
                            },
                        },
                        dataLabels: {
                            enabled: false,
                        },
                        xaxis: {
                            categories: categorie,
                            title: {
                                text: 'Time of Day',
                            },
                        },
                        yaxis: {
                            title: {
                                text: 'Value',
                            },
                            labels: {
                                formatter: (value) => {
                                    return new Intl.NumberFormat('en-US').format(value);
                                },
                            },
                        },
                        legend: {
                            position: 'top',
                            horizontalAlign: 'center',
                            offsetX: 0,
                            offsetY: 0,
                        },
                    }}
                    series={[
                        {
                            name: 'kWh',
                            data: chartDatas.kWh,
                        },
                        {
                            name: 'Cost',
                            data: chartDatas.cost,
                        },
                        {
                            name: 'CO2',
                            data: chartDatas.co2,
                        },
                    ]}
                    type="bar"
                    height={350}
                    width={950}
                />
            </div>
        </div>
</div>
</div>
    );
};

export default EmsSubGroup;
