import React, { useState } from "react";
import "../scss/ReportCard.scss";
import 'chartjs-adapter-moment';
import 'chart.js/auto';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import ExcelJS from 'exceljs';


const ReportCard = () => {
  const [activeTab, setActiveTab] = useState("Hourly Report");
  const [selectedDate, setSelectedDate] = useState("");
  const  [fromDate, setFromDate] = useState("");
  const  [toDate, setToDate] = useState("");
  const  [hourlyData, setHourlyData] = useState([]);
  const  [dailyData, setDailyData] = useState([]);
  const [logData, setLogData] = useState([]);
  const [historicalTrend, setHistoricalTrend] = useState("Voltage");


  const tabs = ["Hourly Report", "Daily Report", "Log Report", "Historical Trend"];

  const handleGetHourlyReport = () => {
    setHourlyData([
      { date: selectedDate, hour: "01:00", startKwh: 0, endKwh: 10, unitRate: 5 },
      { date: selectedDate, hour: "02:00", startKwh: 10, endKwh: 20, unitRate: 5 },
      ]);
  };

  const handleGetDailyReport = () => {
    setDailyData([
      { date: fromDate, toDate, startKwh: 0, endKwh: 50, unitRate: 5 },
      { date: fromDate, toDate, startKwh: 50, endKwh: 100, unitRate: 5 },
    ]);
  };

  const handleHistoricalTrendChange = (event) => {
    setHistoricalTrend(event.target.value);
  };

  const handleGetLogReport = () => {
    setLogData([
      { date: "2024-12-07 ", VR: 220, VY: 230, VB: 210, VRY: 230, VYB: 210, VBR: 220, IR: 10, IY: 9, IB: 8, KVA: 15, KW: 12, KVAR: 5, PF: 0.8, KWH: 50 },
      { date: "2024-12-07 ", VR: 225, VY: 235, VB: 215, VRY: 235, VYB: 215, VBR: 225, IR: 11, IY: 10, IB: 9, KVA: 16, KW: 13, KVAR: 6, PF: 0.82, KWH: 55 },
    ]);
  };

  const handleExportToExcel = (data, fileName) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Report');

    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(2).width = 20; 
    worksheet.getColumn(3).width = 15; 
    worksheet.getColumn(4).width = 15; 
    worksheet.getColumn(5).width = 15;
    worksheet.getColumn(6).width = 15; 
    worksheet.getColumn(7).width = 15; 

    let dateRow;

if (activeTab === "Log Report") {
    dateRow = worksheet.addRow([`Date : ${selectedDate}`]);
    worksheet.mergeCells(dateRow.number, 1, dateRow.number, 15); 

    for (let i = 1; i <= 15; i++) { 
        dateRow.getCell(i).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFD9EAD3' }, 
        };
    }
} else if (activeTab === "Hourly Report") {
    
    dateRow = worksheet.addRow([`Date: ${selectedDate}`]);
    worksheet.mergeCells(dateRow.number, 1, dateRow.number, 7); 

    for (let i = 1; i <= 7; i++) { 
        dateRow.getCell(i).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFD9EAD3' }, 
        };
    }
} else if (activeTab === "Daily Report") {
   
    dateRow = worksheet.addRow([`From Date: ${fromDate}, To Date: ${toDate}`]);
    worksheet.mergeCells(dateRow.number, 1, dateRow.number, 7); 

    for (let i = 1; i <= 7; i++) { 
        dateRow.getCell(i).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFD9EAD3' }, 
        };
    }
}

dateRow.font = { bold: true, size: 14 };
dateRow.alignment = { horizontal: 'center', vertical: 'middle' }; 

const branchRow = worksheet.addRow(['Branch: Coimbatore', ' ', ' ',' ','Place of Installation: Group Control']);
branchRow.font = { bold: true, size: 12 };
branchRow.getCell(1).alignment = { horizontal: 'left' }; 
branchRow.getCell(3).alignment = { horizontal: 'center' }; 

const branchCellRange = activeTab === "Log Report" ? 15 : 7; 
for (let i = 1; i <= branchCellRange; i++) {
    branchRow.getCell(i).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFDDDDDD' }, 
    };
}


const titleRow = worksheet.addRow([`${activeTab}`]);
titleRow.font = { bold: true, size: 16 };
titleRow.alignment = { horizontal: 'center' };

worksheet.mergeCells(titleRow.number, 1, titleRow.number, branchCellRange);

for (let i = 1; i <= branchCellRange; i++) {
    titleRow.getCell(i).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF1E90FF' }, 
    };
}

    if (data.length > 0) {
        let headers;
        if (activeTab === "Hourly Report") {
            headers = ['Date', 'Hour', 'Start KWH', 'End KWH', 'Total KWH', 'Unit Rate', 'Total Cost'];
        } else if (activeTab === "Daily Report") {
            headers = ['From Date', 'To Date', 'Start KWH', 'End KWH', 'Total KWH', 'Unit Rate', 'Total Cost'];
        } else if (activeTab === "Log Report") {
            headers = ['Date Time', 'VR', 'VY', 'VB', 'VRY', 'VYB', 'VBR','IR', 'IY', 'IB', 'KVA', 'KW', 'KVAR', 'PF', 'KWH'];
        }

       
        const headerRow = worksheet.addRow(headers);
        headerRow.font = { bold: true };
        
        
        headerRow.eachCell((cell, colIndex) => {
            if (activeTab === "Log Report" && colIndex >= 1 && colIndex <= 15) {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFA500' }, 
                };
            } else if (colIndex >= 1 && colIndex <= 7) { 
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFA500' }, 
                };
            }
        });
        

       
        data.forEach((row, rowIndex) => {
          const rowData = activeTab === "Hourly Report"
              ? [row.date, row.hour, row.startKwh, row.endKwh, (row.endKwh - row.startKwh).toFixed(2), row.unitRate.toFixed(2), ((row.endKwh - row.startKwh) * row.unitRate).toFixed(2)]
              : activeTab === "Daily Report"
              ? [row.date, row.toDate, row.startKwh, row.endKwh, (row.endKwh - row.startKwh).toFixed(2), row.unitRate.toFixed(2), ((row.endKwh - row.startKwh) * row.unitRate).toFixed(2)]
              : [row.date, row.VR, row.VY, row.VB, row.VRY, row.VYB, row.VBR, row.IR, row.IY, row.IB, row.KVA, row.KW, row.KVAR, row.PF, row.KWH];
      
          const dataRow = worksheet.addRow(rowData);
      
          dataRow.eachCell((cell, colIndex) => {
              let fillColor;
              if (activeTab === "Log Report" && colIndex >= 1 && colIndex <= 15) {
                  fillColor = rowIndex % 2 === 0 ? 'FFE8EAF6' : 'FFFFFFFF'; 
              } else if (colIndex >= 1 && colIndex <= 7) {
                  fillColor = rowIndex % 2 === 0 ? 'FFE8EAF6' : 'FFFFFFFF'; 
              }
              
              if (fillColor) {
                  cell.fill = {
                      type: 'pattern',
                      pattern: 'solid',
                      fgColor: { argb: fillColor }, 
                  };
              }
          });
      });
      

            const totals = activeTab === "Log Report" ? calculateLogTotals(data) : calculateTotals(data);
            const totalsRow = worksheet.addRow(
                activeTab === "Log Report"
                    ? ['TOTAL', '', '', '', '', '', '', '', '', '', '', '', '', ' ', totals.totalKwh.toFixed(2)] 
                    : ['', '', 'TOTAL', '', totals.totalKwh.toFixed(2), '', totals.totalCost ? totals.totalCost.toFixed(2) : ''] 
            );
            
            totalsRow.font = { bold: true };
            totalsRow.eachCell((cell, colIndex) => {
                if (activeTab === "Log Report") {
                  if (colIndex >= 1 && colIndex <= 15) { 
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'FFFFEB3B' },
                        };
                        cell.alignment = { horizontal: 'right' };
                        cell.border = {
                            top: { style: 'thin' },
                            left: { style: 'thin' },
                            bottom: { style: 'thin' },
                            right: { style: 'thin' },
                        };
                    }
                } else {
                    if (colIndex >= 1 && colIndex <= 7) { 
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'FFFFEB3B' }, 
                        };
                        cell.alignment = { horizontal: 'right' };
                        cell.border = {
                            top: { style: 'thin' },
                            left: { style: 'thin' },
                            bottom: { style: 'thin' },
                            right: { style: 'thin' },
                        };
                    }
                }
            });
            
          } else {
        worksheet.addRow(['No data available']).font = { italic: true };
    }

    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    });
};

  const handlePrint = () => {
    window.print();
  };

  const calculateTotals = (data) => {
    const totalKwh = data.reduce((sum, row) => sum + (row.endKwh - row.startKwh), 0);
    const totalCost = data.reduce(
      (sum, row) => sum + (row.endKwh - row.startKwh) * row.unitRate,
      0
    );
    return { totalKwh, totalCost };
  };
  
  const calculateLogTotals = (data) => {
    const totalKwh = data.reduce((sum, row) => sum + row.KWH, 0);
    return { totalKwh };
  };


  const { totalKwh: hourlyTotalKwh, totalCost: hourlyTotalCost } = calculateTotals(hourlyData);
  const { totalKwh: dailyTotalKwh, totalCost: dailyTotalCost } = calculateTotals(dailyData);
  const { totalKwh: logTotalKwh } = calculateLogTotals(logData);

  const voltageData = [
    {
      datetime: "2023-08-01T00:00:00Z",
      r_voltage: 250,
      b_voltage: 240,
      y_voltage: 230,
      r_current: 50,
      b_current: 45,
      y_current: 40,
    },
    {
      datetime: "2023-08-01T01:00:00Z",
      r_voltage: 245,
      b_voltage: 235,
      y_voltage: 225,
      r_current: 48,
      b_current: 43,
      y_current: 38,
    },
  
  ];

  const formattedData = voltageData.map((item) => ({
    datetime: new Date(item.datetime).getTime(),
    r_value: item[`r_${historicalTrend.toLowerCase()}`],
    b_value: item[`b_${historicalTrend.toLowerCase()}`],
    y_value: item[`y_${historicalTrend.toLowerCase()}`],
  }));

  const options = {
    title: {
      text: `${historicalTrend}`,
    },
    rangeSelector: {
      selected: 1,
    },
    xAxis: {
      type: "datetime",
    },
    yAxis: {
      title: {
        text: historicalTrend,
      },
    },
    series: [
      {
        name: `R_${historicalTrend}`,
        data: formattedData.map((item) => [item.datetime, item.r_value]),
        tooltip: {
          valueSuffix: ` ${historicalTrend}`,
        },
        color: "red",
      },
      {
        name: `B_${historicalTrend}`,
        data: formattedData.map((item) => [item.datetime, item.b_value]),
        tooltip: {
          valueSuffix: ` ${historicalTrend}`,
        },
        color: "blue",
      },
      {
        name: `Y_${historicalTrend}`,
        data: formattedData.map((item) => [item.datetime, item.y_value]),
        tooltip: {
          valueSuffix: ` ${historicalTrend}`,
        },
        color: "yellow",
      },
    ],
  };
  
  
  return (
    <div className="dashboard">
      <div className="main-container">
        {/* Navbar */}
        <div className="nav-bar">
          {tabs.map((tab) => (
            <button
              key={tab}
              className={activeTab === tab ? "active" : ""}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          ))}
        </div>

        {/* Hourly Report Content */}
        {activeTab === "Hourly Report" && (
          <div className="hourly-report">
            <div className="card controls-card">
              <input
                type="date"
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
                placeholder="Select Date"
              />
              <button onClick={handleGetHourlyReport}>Get Report</button>
              <button onClick={() => handleExportToExcel(hourlyData, 'Hourly_Report.xlsx')}>
                <i className="excel-icon">📊</i> Excel
              </button>
              <button onClick={handlePrint}>
                <i className="print-icon">🖨️</i> Print
              </button>
            </div>

            <div className="table-container">
              <h4 className="table-title">
                Hourly Log View on {selectedDate || "Selected Date"}
              </h4>
              <table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Hour</th>
                    <th>Start KWH</th>
                    <th>End KWH</th>
                    <th>Total KWH</th>
                    <th>Unit Rate</th>
                    <th>Total Cost</th>
                  </tr>
                </thead>
                <tbody>
                  {hourlyData.length > 0 ? (
                    hourlyData.map((row, index) => (
                      <tr key={index}>
                        <td>{row.date}</td>
                        <td>{row.hour}</td>
                        <td>{row.startKwh.toFixed(2)}</td>
                        <td>{row.endKwh.toFixed(2)}</td>
                        <td>{(row.endKwh - row.startKwh).toFixed(2)}</td>
                        <td>{row.unitRate.toFixed(2)}</td>
                        <td>
                          {((row.endKwh - row.startKwh) * row.unitRate).toFixed(2)}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7" style={{ textAlign: "center" }}>
                        No Data Available
                      </td>
                    </tr>
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="4" style={{ textAlign: "right" }}>TOTAL</td>
                    <td>{hourlyTotalKwh.toFixed(2)}</td>
                    <td></td>
                    <td>{hourlyTotalCost.toFixed(2)}</td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        )}

        {/* Daily Report Content */}
        {activeTab === "Daily Report" && (
          <div className="daily-report">
            <div className="card controls-card">
              <input
                type="date"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
                placeholder="From Date"
              />
              <input
                type="date"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
                placeholder="To Date"
              />
              <button onClick={handleGetDailyReport}>Get Report</button>
              <button onClick={() => handleExportToExcel(dailyData, 'Daily_Report.xlsx')}>
                <i className="excel-icon">📊</i> Excel
              </button>
              <button onClick={handlePrint}>
                <i className="print-icon">🖨️</i> Print
              </button>
            </div>

            <div className="table-container">
              <h4 className="table-title">
                Daily Log View from {fromDate} to {toDate}
              </h4>
              <table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>From Date</th>
                    <th>To Date</th>
                    <th>Start KWH</th>
                    <th>End KWH</th>
                    <th>Total KWH</th>
                    <th>Unit Rate</th>
                    <th>Total Cost</th>
                  </tr>
                </thead>
                <tbody>
                  {dailyData.length > 0 ? (
                    dailyData.map((row, index) => (
                      <tr key={index}>
                        <td>{row.date}</td>
                        <td>{row.fromDate}</td>
                        <td>{row.toDate}</td>
                        <td>{row.startKwh.toFixed(2)}</td>
                        <td>{row.endKwh.toFixed(2)}</td>
                        <td>{(row.endKwh - row.startKwh).toFixed(2)}</td>
                        <td>{row.unitRate.toFixed(2)}</td>
                        <td>
                          {((row.endKwh - row.startKwh) * row.unitRate).toFixed(2)}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="8" style={{ textAlign: "center" }}>
                        No Data Available
                      </td>
                    </tr>
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="5" style={{ textAlign: "right" }}>TOTAL</td>
                    <td>{dailyTotalKwh.toFixed(2)}</td>
                    <td></td>
                    <td>{dailyTotalCost.toFixed(2)}</td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        )}

        {/* Log Report Content */}
          {/* Log Report Content */}
          {activeTab === "Log Report" && (
          <div className="log-report">
            <div className="card controls-card">
            <input
                type="date"
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
                placeholder="Select Date"
              />
              <button onClick={handleGetLogReport}>Get Report</button>
              <button onClick={() => handleExportToExcel(logData, 'Meter_Log_Report.xlsx')}>
                <i className="excel-icon">📊</i> Excel
            </button>
                    
              <button onClick={handlePrint}>
                <i className="print-icon">🖨️</i> Print
              </button>
            </div>

            <div className="table-container">
              <h4 className="table-title">
                Meter Log View on {selectedDate || "Selected Date"}
              </h4>
              <table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>VR</th>
                    <th>VY</th>
                    <th>VB</th>
                    <th>VRY</th>
                    <th>VYB</th>
                    <th>VBR</th>
                    <th>IR</th>
                    <th>IY</th>
                    <th>IB</th>
                    <th>KVA</th>
                    <th>KW</th>
                    <th>KVAR</th>
                    <th>PF</th>
                    <th>KWH</th>
                  </tr>
                </thead>
                <tbody>
                  {logData.length > 0 ? (
                    logData.map((row, index) => (
                      <tr key={index}>
                        <td>{row.date}</td>
                        <td>{row.VR}</td>
                        <td>{row.VY}</td>
                        <td>{row.VB}</td>
                        <td>{row.VRY}</td>
                        <td>{row.VYB}</td>
                        <td>{row.VBR}</td>
                        <td>{row.IR}</td>
                        <td>{row.IY}</td>
                        <td>{row.IB}</td>
                        <td>{row.KVA}</td>
                        <td>{row.KW}</td>
                        <td>{row.KVAR}</td>
                        <td>{row.PF}</td>
                        <td>{row.KWH}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="15" style={{ textAlign: "center" }}>
                        No Data Available
                      </td>
                    </tr>
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="14" style={{ textAlign: "right" }}>TOTAL</td>
                    <td>{logTotalKwh.toFixed(2)}</td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        )}

{activeTab === "Historical Trend" && (
  <div className="historical-trend">
    <div className="card controls-card">
      <select
        value={historicalTrend}
        onChange={handleHistoricalTrendChange}
      >
        <option value="Voltage">Voltage</option>
        <option value="Current">Current</option>
        <option value="KVA">KVA</option>
        <option value="KW">KW</option>
        <option value="KVAR">KVAR</option>
        <option value="PF">PF</option>
        <option value="KWH">KWH</option>
      </select>
      <input
        type="date"
        value={selectedDate}
        onChange={(e) => setSelectedDate(e.target.value)}
        placeholder="Select Date"
      />
      <button>Submit</button>
    </div>

    <div className="trend-container">
      <h3 className="trend-title">{historicalTrend} Trend Report</h3>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={"stockChart"}
        options={options}
      />
    </div>
    </div>
)}
</div>
</div>
  );
};

export default ReportCard;