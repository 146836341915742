import React, { useState, useEffect } from 'react';
import '../scss/dashboard.scss';
import Header from '../components/header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTachometerAlt, faIdCard, faLayerGroup, faSitemap, faSun, faIndianRupeeSign, faHome } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import BaseURL from '../assets/contants/BaseURL';
import { useNavigate } from 'react-router-dom';
import { Pie } from 'react-chartjs-2';

const Groupportal = () => {
    const [view, setView] = useState('group'); 
    const [groupData, setGroupData] = useState(null);
    const [meterData, setMeterData] = useState({});
    const [currentPageForWidgets, setCurrentPageForWidgets] = useState({});
    const [currentPageForTable, setCurrentPageForTable] = useState({});
    const [mainMeterData, setMainMeterData] = useState(null);
    const itemsPerPage = 10;
    const navigate = useNavigate();

    const paginate = (array, pageNumber) => {
        const startIndex = (pageNumber - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return array.slice(startIndex, endIndex);
    };

    const handlePageChangeForWidgets = (groupName, direction, totalPages) => {
        setCurrentPageForWidgets((prevState) => {
            const currentPage = prevState[groupName] || 1;
            let newPage = currentPage + direction;

            newPage = Math.max(1, Math.min(newPage, totalPages));
    
            return {
                ...prevState,
                [groupName]: newPage,
            };
        });
    };
    

    const handlePageChangeForTable = (groupName, direction, totalPages) => {
        setCurrentPageForTable((prevState) => {
            const currentPage = prevState[groupName] || 1;
            let newPage = currentPage + direction;
            newPage = Math.max(1, Math.min(newPage, totalPages));
            return {
                ...prevState,
                [groupName]: newPage >= 1 ? newPage : 1,
            };
        });
    };

    const handleMeterClick = (meterId, groupName) => {
        navigate("/EMS/emssub", { state: { meterId, groupName } });
        window.scrollTo(0, 0);
        // console.log(meterId, groupName)
    };
    const handleGroupClick = (group_name) => {
        navigate("/EMS/EmsSubGroup", { state: { group_name } });
        window.scrollTo(0, 0);
        // console.log(group_name)
      };

    const handleMainClick = () => {
        navigate("/EMS/dashboard");
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${BaseURL}/data/group-meter-details/`);
                const data = response.data;
                setGroupData(data);
                setMainMeterData({
                    meter_name: data['Meter Name'],
                    meter_id: data['Meter ID'],
                    total_meters: data['Total Meter'],
                    ems_groups: data['EMS Groups'],
                    total_kWh: data['Total kWh'],
                    total_cost: data['Total Cost'],
                    total_co2: data['Total CO2'],
                });
                for (const groupName of data['Group Names']) {
                    await fetchMeterData(groupName);
                }
            } catch (error) {
                console.error("There was an error fetching the data!", error);
            }
        };
    
        fetchData();
    
    }, []);
    
    const fetchMeterData = async (groupName) => {
        const today = new Date();
        const indiaTime = new Date(today.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' }));
        const formattedDate = `${indiaTime.getFullYear()}-${(indiaTime.getMonth() + 1).toString().padStart(2, '0')}-${indiaTime.getDate().toString().padStart(2, '0')}`;
        try {
            const response = await axios.post(`${BaseURL}/data/meter-table/`, {
                date: formattedDate,
                group_name: groupName
            });
            setMeterData((prevData) => ({
                ...prevData,
                [groupName]: response.data
            }));
        } catch (error) {
            console.error(`There was an error fetching meter data for group ${groupName}!`, error);
        }
    };

    const renderWidget = (title, value, index, icon, onClick) => (
        <div
            key={value}
            onClick={handleMainClick}
            style={{
                width: '150px',
                height: '80px',
                margin: '10px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                borderRadius: '15px',
                border: '1px solid #ddd',
                boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                position: 'relative',
                textAlign: 'left',
                padding: '15px',
                transition: 'all 0.3s ease',
            }}
            className="widgetss"
        >
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                {icon ? (
                    <FontAwesomeIcon icon={icon} size="3x" style={{ color: '#fff' }} />
                ) : (
                    <div style={{ width: '3rem', height: '3rem' }} />
                )}
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <h4 style={{ margin: 0, fontSize: '0.9rem', fontWeight: 'bold', color: '#fff' }}>{title}</h4>
                    <p style={{ fontSize: '1rem', color: '#fff', fontWeight: '400' }}>{value}</p>
                </div>
            </div>
        </div>
    );

    const renderMachineWidgets = (index) => {
        if (!mainMeterData) return null;
        return (
            <div
                key={index}
                className="machine-widgets"
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '20px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '20px',
                }}
            >
                {/* {renderWidget('Meter Name:', mainMeterData.meter_name, null, faTachometerAlt, null, () => handleMainClick())}
                {renderWidget('Meter ID:', mainMeterData.meter_id, index + 1, faIdCard, null, () => handleMainClick())} */}
                {renderWidget('Total Meter:', mainMeterData.total_meters, 1, faLayerGroup, null, () => handleMainClick())}
                {renderWidget('EMS Groups:', mainMeterData.ems_groups, 2, faSitemap, null, () => handleMainClick())} 
                {/* {renderWidget('kWh (R):', mainMeterData.total_kWh, index + 2, faSun, null, () => handleMainClick())}
                {renderWidget('Cost:', `₹ ${mainMeterData.total_cost}`, index + 3, faIndianRupeeSign, null, () => handleMainClick())}
                {renderWidget('Tons:', mainMeterData.total_co2, index + 4, faHome, null, () => handleMainClick())} */}
            </div>
        );
    };

    // const renderMachines = (title, meters) => (
    //     <div>
    //         <h2 className="heading-with-border" style={{ textAlign: 'center', marginBottom: '30px' }}>
    //             {title}
    //         </h2>
    //         <div className="machines" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    //             {meters.map((meter, index) => renderMachineWidgets(meter, index))}
    //         </div>
    //     </div>
    // );

    // const renderCard = (title, meters) => (
    //     <div className="group-card">
    //         {renderMachines(title, meters)}
    //     </div>
    // );

    const renderGroup1Widgets = (groupName) => {
        const groupMeterData = meterData[groupName] || {};
        const meters = groupMeterData.meter_name || [];
        const meterIds = groupMeterData.meter_id || [];
        const todaysKwh = groupMeterData.todays_kwh || [];
        const costs = groupMeterData.cost || [];
        const currentPage = currentPageForWidgets[groupName] || 1;
        const currentMeters = paginate(meters, currentPage);
        const currentMeterIds = paginate(meterIds, currentPage);
        const currentTodaysKwh = paginate(todaysKwh, currentPage);
        const currentCosts = paginate(costs, currentPage);
        const totalPages = Math.ceil(meters.length / itemsPerPage);

        const totalCost = costs.reduce((acc, cost) => acc + cost, 0); 
        const costPercentagesForPie = costs.map((cost) => {
            const percentage = (cost / totalCost) * 100;
            return percentage > 0 ? percentage : 0.001;
        });


        const getColorForMachine = (index) => {
            const colors = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'];
            return colors[index % colors.length];
        };

        const pieChartData = {
            labels: meters,
            datasets: [
                {
                    data: costPercentagesForPie,
                    backgroundColor: meters.map((_, index) => getColorForMachine(index)),
                },
            ],
        };

        const getWidgetCostPercentage = (cost) => {
            const percentage = (cost / totalCost) * 100;
            return percentage > 0 ? percentage : 0.001;
        };
    
        return (
            <div style={{ marginBottom: '20px' }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginBottom: '20px',
                    }}
                >
                    <div
                        style={{
                            width: '300px',
                            height: '300px',
                            marginBottom: '20px',
                        }}
                    >
                        <Pie
                            data={pieChartData}
                            options={{
                                responsive: true,
                                maintainAspectRatio: false,
                                plugins: {
                                    legend: {
                                        display: false,
                                        position: 'bottom',
                                    },
                                    tooltip: {
                                        callbacks: {
                                            label: function (tooltipItem) {
                                                const value = tooltipItem.raw.toFixed(2);
                                                return `${tooltipItem.label}: ${value}%`;
                                            },
                                        },
                                    },
                                },
                            }}
                        />
                    </div>
                </div>
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '20px',
                        justifyContent: 'center',
                    }}
                >
                    {currentMeters.map((meter, index) => (
                        <div
                            key={index}
                            style={{
                                width: '400px',
                                height: '90px',
                                background: '#66c2ff',
                                borderRadius: '10px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: '20px',
                                boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                                textAlign: 'center',
                                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                cursor: 'pointer',
                            }}
                            className="widget"
                            onClick={() => handleMeterClick(currentMeterIds[index], groupName)}
                        >
                            <h4
                                style={{
                                    margin: '5px 0',
                                    color: '#fff',
                                    textShadow: '1px 1px 2px rgba(0,0,0,0.5)',
                                    fontSize: '12px'
                                }}
                            >
                                {meter}
                            </h4>
                            <p
                                style={{
                                    margin: '0',
                                    color: '#fff',
                                    fontWeight: '600',
                                    textShadow: '1px 1px 2px rgba(0,0,0,0.5)',
                                    fontSize: '11px'
                                }}
                            >
                                {currentMeterIds[index]}
                            </p>
                            <p
                                style={{
                                    margin: '0',
                                    color: '#fff',
                                    fontWeight: '600',
                                    textShadow: '1px 1px 2px rgba(0,0,0,0.5)',
                                    fontSize: '11px'
                                }}
                            >
                                kWh: {currentTodaysKwh[index]}
                            </p>
                            <p
                                style={{
                                    margin: '0',
                                    color: '#fff',
                                    fontWeight: '600',
                                    textShadow: '1px 1px 2px rgba(0,0,0,0.5)',
                                    fontSize: '11px'
                                }}
                            >
                                Cost: Rs {currentCosts[index]}
                            </p>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '10px',
                                    marginTop: '10px',
                                }}
                            >
                                <div
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        backgroundColor: getColorForMachine(index),
                                        borderRadius: '50%',
                                    }}
                                ></div>
                                <p
                                    style={{
                                        margin: '0',
                                        color: '#fff',
                                        fontWeight: '600',
                                        textShadow: '1px 1px 2px rgba(0,0,0,0.5)',
                                    }}
                                >
                                    {getWidgetCostPercentage(currentCosts[index]).toFixed(2)}%
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        marginTop: '20px',
                    }}
                >
                    <button
                        onClick={() => handlePageChangeForWidgets(groupName, -1, totalPages)}
                        disabled={currentPage === 1}
                        style={{
                            marginRight: '10px',
                            padding: '5px 10px',
                            borderRadius: '30px',
                            background: '#007acc',
                            color: 'white',
                            border: 'none',
                            cursor: 'pointer',
                            transition: 'background-color 0.3s ease',
                            boxShadow: currentPage === 1 ? 'none' : '0px 4px 8px rgba(0, 0, 0, 0.2)',
                            fontSize: '12px',
                        }}
                    >
                        Previous
                    </button>
                    {[...Array(totalPages)].map((_, pageIndex) => (
                        <span
                            key={pageIndex}
                            style={{
                                padding: '5px 10px',
                                cursor: 'pointer',
                                borderRadius: '50%',
                                fontWeight: 'bold',
                                color: 'white',
                                background:
                                    currentPage === pageIndex + 1
                                        ? '#007acc'
                                        : 'linear-gradient(to right, #f7f7f7, #e0e0e0)',
                                transition: 'background-color 0.3s ease',
                                transform: currentPage === pageIndex + 1 ? 'scale(1.2)' : 'scale(1)',
                                boxShadow: currentPage === pageIndex + 1 ? '0 4px 6px rgba(0, 0, 0, 0.3)' : 'none',
                                fontSize: '12px',
                            }}
                        >
                            {pageIndex + 1}
                        </span>
                    ))}
                    <button
                        onClick={() => handlePageChangeForWidgets(groupName, 1, totalPages)}
                        disabled={currentMeters.length < itemsPerPage}
                        style={{
                            marginLeft: '10px',
                            padding: '5px 10px',
                            borderRadius: '30px',
                            background: '#007acc',
                            color: 'white',
                            border: 'none',
                            cursor: 'pointer',
                            transition: 'background-color 0.3s ease',
                            boxShadow: currentMeters.length < itemsPerPage ? 'none' : '0px 4px 8px rgba(0, 0, 0, 0.2)',
                            fontSize: '12px',
                        }}
                    >
                        Next
                    </button>
                </div>
            </div>
        );
    };

    const renderGroup1Table = (groupName) => {
        const groupMeterData = meterData[groupName] || {};
        const meters = groupMeterData.meter_name || [];
        const meterIds = groupMeterData.meter_id || [];
        const todaysKwh = groupMeterData.todays_kwh || [];
        const costs = groupMeterData.cost || [];
        const currentPage = currentPageForTable[groupName] || 1;
        const totalPages = Math.ceil(meters.length / itemsPerPage);
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        const currentMeters = meters.slice(startIndex, endIndex);
        const getColorForMachine = (index) => {
            const colors = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'];
            return colors[index % colors.length];
        };
    
        const totalCost = costs.reduce((acc, cost) => acc + cost, 0);
        const costPercentages = costs.map((cost) => {
            const percentage = (cost / totalCost) * 100;
            return percentage > 0 ? percentage : 0.001;
        });
    
        const pieChartData = {
            labels: meters,
            datasets: [
                {
                    data: costPercentages,
                    backgroundColor: meters.map((_, index) => getColorForMachine(index)),
                },
            ],
        };
    
        return (
            <div style={{
                width: '1500px'
            }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginBottom: '20px',
                    }}
                >
                    <div
                        style={{
                            width: '300px',
                            height: '300px',
                            marginBottom: '20px',
                        }}
                    >
                        <Pie
                            data={pieChartData}
                            options={{
                                responsive: true,
                                maintainAspectRatio: false,
                                plugins: {
                                    legend: {
                                        display: false,
                                        position: 'bottom',
                                    },
                                    tooltip: {
                                        callbacks: {
                                            label: function (tooltipItem) {
                                                const value = tooltipItem.raw.toFixed(2);
                                                return `${tooltipItem.label}: ${value}%`;
                                            },
                                        },
                                    },
                                },
                            }}
                        />
                    </div>
                </div>
                <table
                    style={{ width: '1000px', borderCollapse: 'collapse', margin: '20px auto', border: "1px solid #ddd" }}
                >
                    <thead style={{ backgroundColor: '#f4f4f4', color: '#333', border: '1px solid #ddd' }}>
                        <tr>
                            <th style={{ padding: '10px', border: '1px solid #ddd' }}>S.No</th>
                            <th style={{ padding: '10px', border: '1px solid #ddd' }}>Meter Name</th>
                            <th style={{ padding: '10px', border: '1px solid #ddd' }}>Meter ID</th>
                            <th style={{ padding: '10px', border: '1px solid #ddd' }}>Today kWh</th>
                            <th style={{ padding: '10px', border: '1px solid #ddd' }}>Cost %</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentMeters.map((meter, index) => (
                            <tr key={index} onClick={() => handleMeterClick(meterIds[startIndex + index], groupName)}>
                                <td style={{ padding: '10px', border: '1px solid #ddd' }}>
                                    {startIndex + index + 1}
                                </td>
                                <td style={{ padding: '10px', border: '1px solid #ddd' }}>{meter}</td>
                                <td style={{ padding: '10px', border: '1px solid #ddd' }}>
                                    {meterIds[startIndex + index]}
                                </td>
                                <td style={{ padding: '10px', border: '1px solid #ddd',textAlign: "right" }}>
                                    {todaysKwh[startIndex + index]}
                                </td>
                                <td style={{ padding: '10px', border: '1px solid #ddd' }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '5px',
                                    }}
                                >
                                    <div
                                        style={{
                                            width: '20px',
                                            height: '20px',
                                            backgroundColor: getColorForMachine(startIndex + index),
                                            borderRadius: '50%',
                                        }}
                                    ></div>
                                    <span>{costPercentages[startIndex + index].toFixed(2)}%</span>
                                </div>
                            </td>
                            </tr>
                        ))}
                        <tr style={{ backgroundColor: '#f4f4f4', color: '#333', fontWeight: 'bold' }}>
                            <td style={{ padding: '10px', border: '1px solid #ddd' }}></td>
                            <td style={{ padding: '10px', border: '1px solid #ddd' }} colSpan="2">
                                Total
                            </td>
                            <td style={{ padding: '10px', border: '1px solid #ddd',textAlign: "right" }}>{todaysKwh.reduce((acc, curr) => acc + parseFloat(curr || 0), 0).toFixed(2)}</td>
                            <td style={{ padding: '10px', border: '1px solid #ddd' }}></td>
                        </tr>
                    </tbody>
                </table>
    
                <div style={{ width: '100%', textAlign: 'center', marginTop: '10px', marginBottom: '20px' }}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10px',
                            marginBottom: '20px',
                        }}
                    >
                        <button
                            onClick={() => handlePageChangeForTable(groupName, -1, totalPages)}
                            disabled={currentPage === 1}
                            style={{
                                padding: '5px 10px',
                                borderRadius: '30px',
                                background: currentPage === 1 ? '#cccccc' : '#007acc',
                                color: '#fff',
                                border: 'none',
                                cursor: currentPage === 1 ? 'not-allowed' : 'pointer',
                                fontSize: '12px',
                            }}
                        >
                            Previous
                        </button>
                        {[...Array(totalPages)].map((_, pageIndex) => (
                            <span
                                key={pageIndex}
                                onClick={() => handlePageChangeForTable(groupName, pageIndex + 1, totalPages)}
                                style={{
                                    padding: '5px 10px',
                                    cursor: 'pointer',
                                    borderRadius: '50%',
                                    fontWeight: 'bold',
                                    color: '#fff',
                                    background:
                                        currentPage === pageIndex + 1
                                            ? '#007acc'
                                            : 'linear-gradient(to right, #f7f7f7, #e0e0e0)',
                                    fontSize: '12px',
                                }}
                            >
                                {pageIndex + 1}
                            </span>
                        ))}
                        <button
                            onClick={() => handlePageChangeForTable(groupName, 1, totalPages)}
                            disabled={currentPage === totalPages}
                            style={{
                                padding: '5px 10px',
                                borderRadius: '30px',
                                background: currentPage === totalPages ? '#cccccc' : '#007acc',
                                color: '#fff',
                                border: 'none',
                                cursor: currentPage === totalPages ? 'not-allowed' : 'pointer',
                                fontSize: '12px',
                            }}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        );
    };    

    const handleViewChange = (event) => {
        const selectedView = event.target.value;
        setView(selectedView);
    };

    if (!groupData) {
        return <div>Loading...</div>;
    }

    return (
        <div className="dashboard" style={{ fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif" }}>
            <Header title="DASHBOARD" />
            <div className="content-container" style={{ padding: '20px' }}>
                <div className="card" style={{ background: '#fff', borderRadius: '10px' }}>
                    <div
                        className="dropdown-container"
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: '20px',
                            width: '1060px',
                            marginLeft: '1px',
                        }}
                    >
                        <select
                            value={view}
                            onChange={handleViewChange}
                            style={{
                                padding: '10px',
                                borderRadius: '5px',
                                border: '1px solid #ccc',
                                width: '20%',
                                fontSize: '14px',
                                cursor: 'pointer',
                                transition: 'border 0.3s ease',
                            }}
                        >
                            <option value="group">Group View</option>
                            <option value="list">List View</option>
                        </select>
                    </div>
                    <div className="machines-container">
                        {renderMachineWidgets()}
                        {groupData && groupData['Group Names'] && groupData['Group Names'].map((groupName, index) => (
                            <div key={index} className="group-card">
                                <a onClick={() => handleGroupClick(groupName)}>
                                <h2 className="heading-with-border">{groupName}</h2>
                                </a>
                                <div style={{ marginTop: '20px' }}>
                                    {view === 'group'
                                        ? renderGroup1Widgets(groupName)
                                        : renderGroup1Table(groupName)
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Groupportal;
