import React from 'react';
import '../scss/Appfooter.scss'; 

const AppFooter = () => {
  return (
    <footer className="footer">
      <div>
        EMS<span>&copy;2024</span>
      </div>
    </footer>
  );
};

export default React.memo(AppFooter);
