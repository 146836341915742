import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTrash, faPlus,faEdit } from '@fortawesome/free-solid-svg-icons';
import BaseURL from '../assets/contants/BaseURL';
import Header from '../components/header';

const EnergyMeter = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [checkedItems, setCheckedItems] = useState({});
  const [newMeter, setNewMeter] = useState({ id: '', name: '', cost: ''});
  const [editMeter, setEditMeter] = useState({ id: '', meter_id: '', name: '', cost: '', model: '', peak_timing: '' });

  const [isModalOpen, setModalOpen] = useState(false);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [timings, setTimings] = useState([]);
  const [unitSelected, setUnitSelected] = useState("COST");

  const [isEditModalOpen, setEditModalOpen] = useState(false)
  
  
  const totalPages = Math.ceil(dataToDisplay.length / itemsPerPage);
  const paginatedData = dataToDisplay.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${BaseURL}meters/energymeters/`);
      // console.log("API Response:", response.data); 
      setDataToDisplay(response.data); 
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // const fetchGroups = async () => {
  //   try {
  //     const response = await axios.get(`${BaseURL}meters/emsgroups/`);
  //     // console.log("Groups Response:", response.data); 
  //     // setGroups(response.data); 
  //   } catch (error) {
  //     console.error("Error fetching groups:", error);
  //   }
  // };

  const fetchPeakTimings = async () => {
    try {
      const response = await axios.get(`${BaseURL}meters/peaktimings/`);
      setTimings(response.data);

      // console.log(response.data);
    } catch (error) {
      console.error("Error fetching peak timings:", error);
    }
  };


  useEffect(() => {
    fetchData(); 
    // fetchGroups();
    fetchPeakTimings();
  }, []);

  

  const handleSearch = () => {
    const results = dataToDisplay.filter(item =>
      item.meter_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setDataToDisplay(results.length > 0 ? results : []);
    setCheckedItems({});
  };

  const handleCheckboxChange = (id) => {
    setCheckedItems((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    const newCheckedItems = {};
    dataToDisplay.forEach(item => {
      newCheckedItems[item.id] = isChecked;
    });
    setCheckedItems(newCheckedItems);
  };

  const handleAddMeter = async () => {
    const meterData = {
      meter_id: newMeter.id,
      meter_name: newMeter.name,
      cost_per_unit: unitSelected === 'Cost' ? Number(newMeter.cost) : null,
      peak_timing: unitSelected === 'Peak Hours Timings' && newMeter.peak_timing ? newMeter.peak_timing : null,
      // status: newMeter.status,
      unit_type: unitSelected === 'Cost' ? 'Cost' : unitSelected === 'Peak Hours Timings' ? 'Peak Timing' : '',
      model:newMeter.model,
    };
  
    // console.log("Meter Data to be sent:", meterData);
  
    try {
      await axios.post(`${BaseURL}meters/energymeters/`, meterData);
      fetchData(); 
      setNewMeter({ id: '', name: '', cost: ''});
      setModalOpen(false); 
    } catch (error) {
      console.error("Error adding meter:", error.response ? error.response.data : error.message);
      alert("An error occurred while adding the meter.");
    }
  };
  
  
  

  const handleDeleteMeters = async () => {
    const confirmDelete = window.confirm("Are you sure you want to delete the selected meters?");
    if (confirmDelete) {
      try {
        await Promise.all(
          Object.keys(checkedItems).filter(id => checkedItems[id]).map(id => 
            axios.delete(`${BaseURL}meters/energymeters/${id}/`)
          )
        );
        fetchData(); 
        setCheckedItems({});
      } catch (error) {
        console.error("Error deleting meters:", error);
      }
    }
  };

  
  const handleEditMeter = async () => {
    
    const unitTypeValue =
    unitSelected === "Cost" ? "Cost" : 
    unitSelected === "Peak Hours Timings" ? "Peak Timing" : "";

    const meterData = { 
      id: editMeter.id, 
      meter_id: editMeter.meter_id, 
      meter_name: editMeter.name,
      cost_per_unit: unitSelected === 'Cost' ? Number(editMeter.cost) : null,
      peak_timing: unitSelected === 'Peak Hours Timings' && editMeter.peak_timing ? editMeter.peak_timing : null,
      // status: editMeter.status,
      unit_type: unitTypeValue,
      model: editMeter.model,
    };
  
  
    try {
    
      // console.log("Updating meter:", meterData);
  
      await axios.put(`${BaseURL}meters/energymeters/${editMeter.id}/`, meterData);
  
      
      fetchData();
      setEditMeter({ id: '', meter_id: '', name: '', cost: '', model: '', peak_timing: '' });
      setEditModalOpen(false);
      alert("Meter updated successfully!");
    } catch (error) {
      
      console.error("Error editing meter:", error.response ? error.response.data : error.message);
      alert("An error occurred while editing the meter. Please try again.");
    }
  };
  
  

  const handleEditClick = (meter) => {
    setEditMeter({
      id: meter.id, 
      meter_id: meter.meter_id, 
      name: meter.meter_name,
      cost: meter.cost_per_unit,
      // status: meter.status,
      model: meter.model,
      peak_timing: meter.peak_timing
    });
    setEditModalOpen(true);
  };
  
  return (
    <div style={{ textAlign: 'center', width: '100%' }}>
      <div style={{
       backgroundColor: '#007acc',
       color: 'white',
       alignContent: 'center',
       fontSize: '14px',
       height:'40px'
      }}>
       <Header title="METER LIST" />
      </div>

      <div style={{
        border: '1px solid #ccc',
        padding: '10px',  
        marginTop: '20px', 
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
        backgroundColor: 'white',
        margin: '20px auto', 
        maxWidth: '1000px',
      }}>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
          <div style={{ display: 'flex', alignItems: 'center', width: '30%' }}>
            <input
              type="text"
              placeholder="Search"
              style={{
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '8px',
                flex: '1',
                marginRight: '10px',
              }}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <FontAwesomeIcon
              icon={ faSearch}
              style={{ color: '#007acc', cursor: 'pointer' }}
              onClick={handleSearch}
            />
          </div>

          <div style={{ marginLeft: 'auto' }}>
            <button
              style={{
                padding: '10px 15px',
                marginRight: '10px',
                backgroundColor: '#f44336',
                color: 'white',
                border: 'none',
                borderRadius: '8px',
                cursor: 'pointer',
              }}
              onClick={handleDeleteMeters}
            >
              <FontAwesomeIcon icon={faTrash} style={{ marginRight: '5px' }} />
              Delete
            </button>
            <button
              style={{
                padding: '10px 15px',
                backgroundColor: '#4CAF50',
                color: 'white',
                border: 'none',
                borderRadius: '8px',
                cursor: 'pointer',
                marginRight: '10px',
              }}
              onClick={() => setModalOpen(true)}
            >
              <FontAwesomeIcon icon={faPlus} style={{ marginRight: '5px' }} />
              Add
            </button>
          </div>
        </div>
      </div>

      <div style={{
         marginTop: '20px', 
        padding: '10px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
        backgroundColor: 'white',
        maxWidth: '1000px',
        margin: '20px auto',
        marginBottom: '70px', 
      }}>
        <table style={{ width: '100%', textAlign: 'left', borderCollapse: 'collapse' }}>
          <thead style={{ backgroundColor: '#007acc', color: 'white' }}>
            <tr>
              <th style={{ padding: '10px' }}>
              <input
                type="checkbox"
                onChange={handleSelectAll}
                checked={
                  Object.keys(checkedItems).length ===
                    paginatedData.filter(item => item.meter_name !== 'Main').length &&
                  Object.values(checkedItems).every((value, key) => 
                    (paginatedData.find(item => item.id === key)?.meter_name !== 'Main') ? value : true
                  )
                }
              />
              </th>
              <th style={{ padding: '10px' }}>S.No</th>
              <th style={{ padding: '10px' }}>Meter Name</th>
              <th style={{ padding: '10px' }}>Meter ID</th>
              <th style={{ padding: '10px' }}>Cost/Unit</th>
              {/* <th style={{ padding: '10px' }}>Status</th> */}
              <th style={{padding:'10px'}}>Action</th>
             
            </tr>
          </thead>
          <tbody>
  {paginatedData.length > 0 ? (
    paginatedData.map((item, index) => {
      
      return (
        <tr
          key={item.id}
          style={{
            backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#ffffff',
          }}
        >
          <td style={{ padding: '10px' }}>
          {item.meter_name !== 'Main' && (
                  <input
                    type="checkbox"
                    checked={checkedItems[item.id] || false}
                    onChange={() => handleCheckboxChange(item.id)}
                  />
                )}
          </td>
          <td style={{ padding: '10px' }}>
            {(currentPage - 1) * itemsPerPage + index + 1}
          </td>
          <td style={{ padding: '10px' }}>{item.meter_name}</td>
          <td style={{ padding: '10px' }}>{item.meter_id}</td>
          <td style={{ padding: '10px' }}>{item.cost_per_unit}</td>
          {/* <td style={{ padding: '10px' }}>{item.status ? 'Active' : 'Inactive'}</td> */}
           <td
                style={{ padding: "20px", color: "blue", cursor: "pointer" }}
                onClick={() => handleEditClick(item)}
              >
                <FontAwesomeIcon icon={faEdit} />
              </td>
        </tr>
      );
    })
  ) : (
    <tr>
      <td colSpan="7" style={{ padding: '10px', textAlign: 'center' }}>
        No results found.
      </td>
    </tr>
  )}
</tbody>
        </table>

        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            style={{
              margin: '0 5px',
              padding: '5px 10px',
              background: currentPage === 1 ? '#007acc' : '#007acc',
              color: currentPage === 1 ? '#9e9e9e' : '#ffffff',
              border: 'none',
              borderRadius: '25px',
              cursor: currentPage === 1 ? 'not-allowed' : 'pointer',
              boxShadow: currentPage === 1 ? 'none' : '0px 4px 8px rgba(0, 0, 0, 0.2)',
              fontSize: '12px',
            }}
          >
            Previous
          </button>

          {Array.from({ length: totalPages }, (_, i) => (
            <button
              key={i}
              onClick={() => handlePageChange(i + 1)}
              style={{
                margin: '0 5px',
                padding: '5px 10px',
                background: currentPage === i + 1 ? '#007acc' : 'linear-gradient(to right, #f7f7f7, #e0e0e0)',
                color: currentPage === i + 1 ? '#ffffff' : '#333333',
                border: '1px solid #ddd',
                borderRadius: '25px',
                cursor: 'pointer',
                fontSize: '12px',
              }}
            >
              {i + 1}
            </button>
          ))}

          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            style={{
              margin: '0 5px',
              padding: '5px 10px',
              background: currentPage === totalPages ? '#007acc' : '#007acc',
              color: currentPage === totalPages ? '#9e9e9e' : '#ffffff',
              border: 'none',
              borderRadius: '25px',
              cursor: currentPage === totalPages ? 'not-allowed' : 'pointer',
              boxShadow: currentPage === totalPages ? 'none' : '0px 4px 8px rgba(0, 0, 0, 0.2)',
              fontSize: '12px',
            }}
          >
            Next
          </button>
        </div>
      </div>

{isModalOpen && (
  <div style={{
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)', 
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }}>
    <div style={{
      backgroundColor: 'white',
      padding: '30px',
      borderRadius: '8px',
      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)', 
      width: '60%', 
      maxWidth: '900px',
      transition: 'all 0.3s ease',
    }}>
      <h4 style={{ marginBottom: '20px', textAlign: 'center' }}>Add New Meter</h4>
      <div style={{ display: 'flex', gap: '30px' }}>
        {/* Left Column */}
        <div style={{ flex: '1', display: 'flex', flexDirection: 'column', gap: '15px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <label style={{ flex: '1', marginRight: '10px' }}>Meter Name</label>
            <input
              type="text"
              placeholder="Enter Meter Name"
              value={newMeter.name}
              onChange={(e) => setNewMeter({ ...newMeter, name: e.target.value })}
              style={{ flex: '2', padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <label style={{ flex: '1', marginRight: '10px' }}>Meter ID</label>
            <input
              type="text"
              placeholder="Enter Meter ID"
              value={newMeter.id}
                  onChange={(e) => setNewMeter({ ...newMeter, id: e.target.value })}
              style={{ flex: '2', padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}
            />
          </div>
          {unitSelected === 'Cost' && (
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <label style={{ flex: '1', marginRight: '10px' }}>Cost</label>
            <input
              type="number"
              placeholder="Enter Cost"
              value={newMeter.cost}
              onChange={(e) => setNewMeter({ ...newMeter, cost: e.target.value })}
              style={{ flex: '2', padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}
            />
          </div>
          )}
          {unitSelected === 'Peak Hours Timings' && (
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px' }}>
            <label style={{ flex: "1", marginRight: "10px" }}>Peak Hrs Timings</label>
            <select
              style={{
                flex: "2",
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "8px",
              }}
              value={newMeter.peak_timing}
              onChange={(e) => setNewMeter({ ...newMeter, peak_timing: e.target.value })}
            >
              <option value="">Select Timing</option>
              {timings.map((timing, index) => (
                <option key={index} value={timing.id}>
                  {timing.timing}
                </option>
              ))}
            </select>
          </div>
          )}
          </div>

        <div style={{ flex: '1', display: 'flex', flexDirection: 'column', gap: '15px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <label style={{ flex: '1', marginRight: '10px' }}>Meter Model</label>
              <select
                value={newMeter.model}
                onChange={(e) => setNewMeter({ ...newMeter, model: e.target.value })}
                style={{ flex: '2', padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}
              >
                <option value="">Select Meter Model</option>
                <option> L measure</option>
                <option> Selec</option>
              </select>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <label style={{ flex: '1', marginRight: '10px' }}>Unit select</label>
              <select
                style={{ flex: '2', padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}
                onChange={(e) => setUnitSelected(e.target.value)}
                value={unitSelected}
              >
                <option value="">Select Unit</option>
                <option value="Cost">Cost</option>
                <option value="Peak Hours Timings">Peak Hours Timings</option>
              </select>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
            {/* <div style={{ display: 'flex', alignItems: 'center' }}>
              <label style={{ fontWeight: '600', color: '#555', fontSize: '14px', marginRight: '10px' }}>Status:</label>
              <input
                type="checkbox"
                id="status-checkbox"
                style={{ display: 'none' }}
                checked={newMeter.status}
                onChange={(e) => setNewMeter({ ...newMeter, status: e.target.checked })}
              />
              <label
                htmlFor="status-checkbox"
                style={{
                  width: '40px',
                  height: '20px',
                  backgroundColor: newMeter.status ? '#4caf50' : '#ccc',
                  borderRadius: '50px',
                  position: 'relative',
                  cursor: 'pointer',
                  transition: 'background-color 0.3s',
                }}
              >
                <span
                  style={{
                    position: 'absolute',
                    top: '3px',
                    left: newMeter.status ? '20px' : '3px',
                    width: '14px',
                    height: '14px',
                    backgroundColor: 'white',
                    borderRadius: '50%',
                    transition: '0.3s',
                  }}
                />
              </label>
            </div> */}
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', width: '40%', marginLeft: '200px' }}>
        <button
          onClick={handleAddMeter}
          style={{
            backgroundColor: '#4CAF50',
            color: 'white',
            border: 'none',
            padding: '10px 15px',
            borderRadius: '4px',
            cursor: 'pointer',
            flex: '1',
            marginRight: '10px',
          }}
        >
          Add Meter
        </button>
        <button
          onClick={() => setModalOpen(false)}
          style={{
            backgroundColor: '#f44336',
            color: 'white',
            border: 'none',
            padding: '10px 15px',
            borderRadius: '4px',
            cursor: 'pointer',
            flex: '1',
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
)}

      {/* Edit Modal */}
      {isEditModalOpen && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <div style={{
            backgroundColor: 'white',
            padding: '30px',
            borderRadius: '8px',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
            width: '60%',
            maxWidth: '900px',
            transition: 'all 0.3s ease',
          }}>
            <h4 style={{ marginBottom: '20px', textAlign: 'center' }}>Edit Meter</h4>
            <div style={{ display: 'flex', gap: '30px' }}>
              {/* Left Column */}
              <div style={{ flex: '1', display: 'flex', flexDirection: 'column', gap: '15px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <label style={{ flex: '1', marginRight: '10px' }}>Meter Name</label>
                  <input
                    type="text"
                    placeholder="Enter Meter Name"
                    value={editMeter.name}
                    onChange={(e) => setEditMeter({ ...editMeter, name: e.target.value })}
                    style={{ flex: '2', padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}
                    disabled={editMeter.name === 'Main'}
                  />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <label style={{ flex: '1', marginRight: '10px' }}>Meter ID</label>
                  <input
                    type="text"
                    placeholder="Enter Meter ID" 
                    value={editMeter.meter_id}
                    onChange={(e) => setEditMeter({ ...editMeter, meter_id: e.target.value })}
                    style={{ flex: '2', padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}
                  />
                </div>
                {unitSelected === 'Cost' && (
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <label style={{ flex: '1', marginRight: '10px' }}>Cost</label>
                    <input
                      type="number"
                      placeholder="Enter Cost"
                      value={editMeter.cost}
                      onChange={(e) => setEditMeter({ ...editMeter, cost: e.target.value })}
                      style={{ flex: '2', padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}
                    />
                  </div>
                )}
                {unitSelected === 'Peak Hours Timings' && (
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px' }}>
                    <label style={{ flex: "1", marginRight: "10px" }}>Peak Hrs Timings</label>
                    <select
                      style={{
                        flex: "2",
                        padding: "10px",
                        border: "1px solid #ccc",
                        borderRadius: "8px",
                      }}
                      value={editMeter.peak_timing}
                      onChange={(e) => setEditMeter({ ...editMeter, peak_timing: e.target.value })}
                    >
                      <option value="">Select Timing</option>
                      {timings.map((timing, index) => (
                        <option key={index} value={timing.id}>
                          {timing.timing}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              </div>

              <div style={{ flex: '1', display: 'flex', flexDirection: 'column', gap: '15px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <label style={{ flex: '1', marginRight: '10px' }}>Meter Model</label>
                  <select
                    value={editMeter.model}
                    onChange={(e) => setEditMeter({ ...editMeter, model: e.target.value })}
                    style={{ flex: '2', padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}
                  >
                    <option value="">Select Meter Model</option>
                    <option> L measure</option>
                    <option> Selec</option>
                  </select>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <label style={{ flex: '1', marginRight: '10px' }}>Unit select</label>
                  <select
                    style={{ flex: '2', padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}
                  value={unitSelected} onChange={(e) => setUnitSelected(e.target.value)}>
                    <option value="">Select Unit</option>
                    <option value="Cost">Cost</option>
                    <option value="Peak Hours Timings">Peak Hours Timings</option>
                  </select>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                  {/* <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label style={{ fontWeight: '600', color: '#555', fontSize: '14px', marginRight: '10px' }}>Status:</label>
                    <input
                      type="checkbox"
                      id="status-checkbox-edit"
                      style={{ display: 'none' }}
                      checked={editMeter.status}
                      onChange={(e) => setEditMeter({ ...editMeter, status: e.target.checked })}
                    />
                    <label
                      htmlFor="status-checkbox-edit"
                      style={{
                        width: '40px',
                        height: '20px',
                        backgroundColor: editMeter.status ? '#4caf50' : '#ccc',
                        borderRadius: '50px',
                        position: 'relative',
                        cursor: 'pointer',
                        transition: 'background-color 0.3s',
                      }}
                    >
                      <span
                        style={{
                          position: 'absolute',
                          top: '3px',
                          left: editMeter.status ? '20px' : '3px',
                          width: '14px',
                          height: '14px',
                          backgroundColor: 'white',
                          borderRadius: '50%',
                          transition: '0.3s',
                        }}
                      />
                    </label>
                  </div> */}
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', width: '40%', marginLeft: '200px' }}>
              <button
                onClick={handleEditMeter}
                style={{
                  backgroundColor: '#4CAF50',
                  color: 'white',
                  border: 'none',
                  padding: '10px 15px',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  flex: '1',
                  marginRight: '10px',
                }}
              >
                Save Changes
              </button>
              <button
                onClick={() => setEditModalOpen(false)}
                style={{
                  backgroundColor: '#f44336',
                  color: 'white',
                  border: 'none',
                  padding: '10px 15px',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  flex: '1',
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EnergyMeter;
